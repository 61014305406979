import React, { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faSearch,
  faArrowRight,
  faAngleDown,
  faBowlFood,
  faFilter,
  faDollar,
  faAngleUp
} from '@fortawesome/free-solid-svg-icons'
import bgimage from '../../Images/Hotels/bg.jpg'
import noUiSlider from 'nouislider'
import { RangeSlider } from 'rsuite'
import HotelCard from '../../Components/HotelCard/Hotelcard'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import HotelSearch from '../../Components/SearchBar/HotelSearch'
import Layout from '../../Components/Layout/Layout'
import moment from 'moment'

// New Imports
import Axios from 'axios'
import Loading from '../../Components/Loading/Loader'
import { fetchHotelsSearh, fetchHotels } from '../../Redux/Actions/actions'
import {
  Hotelapitoken,
  ApiEndPoint,
  CurrencyConverter
} from '../../Components/GlobalData/GlobalData'
function Hotels () {
  const token = Hotelapitoken()
  const dispatch = useDispatch()
  var endpoint = ApiEndPoint()
  const [loading, setLoading] = useState(false)
  const [searchHotelList, setSearchHotelList] = useState([])
  const [hotelid, setHotelid] = useState('')
  const [mealRating, setMealRating] = useState({
    meal1: '',
    meal2: '',
    meal3: '',
    meal4: '',
    type: 'meal'
  })
  const [hotelsSearchData, sethotelsSearchData] = useState({})
  const [hotelNameFilter, setHotelNameFilter] = useState(null)
  const [minValue, setMinValue] = useState(0)
  const [maxValue, setMaxValue] = useState(0)
  const [ShowMealTYpeFilter, setShowMealTYpeFilter] = useState(false)
  const [rangeValue, setRangeValue] = useState([])
  const [ShowRatingFilter, setShowRatingFilter] = useState(false)
  const [ShowPriceFilter, setShowPriceFilter] = useState(false)
  const [ShowFacilityFilter, setShowFacilityFilter] = useState(false)
  const [selectedFacilities, setSelectedFacilities] = useState({
    type: 'facility'
  })
  const [starRating, setStarRating] = useState({
    rating5: '0',
    rating4: '0',
    rating3: '0',
    rating2: '0',
    rating1: '0',
    type: 'Stars'
  })

  // Search Hotels

  useEffect(() => {
    Searchhotels()
  }, [])
  const Searchhotels = async () => {
    setLoading(true)
    const urlParams = new URLSearchParams(window.location.search)
    const parsedData = {}

    for (const [key, value] of urlParams.entries()) {
      if (key === 'Adults' || key === 'children' || key === 'rooms_counter') {
        parsedData[key] = value.split(',').map(Number)
      } else if (key === 'lat' || key === 'long') {
        parsedData[key] = parseFloat(value)
      } else if (key === 'adult' || key === 'child' || key === 'room') {
        parsedData[key] = parseInt(value, 10)
      } else {
        parsedData[key] = value
      }
    }
    parsedData.token = token
    parsedData.currency_slc = 'AFN'
    parsedData.currency_slc_iso = 'AF'
    sethotelsSearchData(parsedData)
    try {
      dispatch(fetchHotelsSearh(parsedData))
      const response = await Axios.post(
        endpoint + '/api/search/hotels/new_Live',
        parsedData,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      if (
        response.data.status === 'success' &&
        response.data.hotels_list.length !== 0
      ) {
        setSearchHotelList(response.data.hotels_list)
        var minamounts = response.data.hotels_list.map(hotel => {
          let minPrice = Number(hotel.min_price);
          
          // Admin markup
          if (Number(hotel.admin_markup) !== 0) {
            if(hotel.admin_markup_type === 'Percentage'){
              minPrice += (Number(hotel.min_price) * Number(hotel.admin_markup)) / 100;
            }else{
              minPrice += Number(hotel.admin_markup);
            }
          } 
          // Customer markup
          if (Number(hotel.customer_markup) !== 0) {
            if(hotel.customer_markup_type === 'Percentage'){
              minPrice += (Number(hotel.min_price) * Number(hotel.customer_markup)) / 100;
            }else{
              minPrice += Number(hotel.admin_markup);
            }
          } 
          if (Number(minPrice) % 1 !== 0) {
            return Number(minPrice).toFixed(2)
          }
          return minPrice
        });
        var minValue = Math.min(...minamounts)
        var maxValue = Math.max(...minamounts)
        setMinValue(minValue)
        setMaxValue(maxValue)
        setRangeValue([minValue, maxValue])
        const currencies = []
        response.data.hotels_list.forEach(hotel => {
          if (!currencies.includes(hotel.hotel_curreny)) {
            currencies.push(hotel.hotel_curreny)
          }
        })
        const currencyResponse = await AllCurrency(currencies)
        const result = makeArrayNull(currencyResponse)
        if (result === null) {
          localStorage.setItem('AllHotelCurr', null)
        } else {
          localStorage.setItem('AllHotelCurr', JSON.stringify(currencyResponse))
        }
        setLoading(false)
        sessionStorage.removeItem('FlightCheckOut')
      }
    } catch (error) {
      console.error('Error:', error)
      setLoading(false)
    }
  }

  // Currency Conversion

  const AllCurrency = async currencyArray => {
    const currtoken = CurrencyConverter()
    const requests = currencyArray.map(currencynew => {
      const config = {
        method: 'get',
        url: `https://v6.exchangerate-api.com/v6/${currtoken}/latest/${currencynew}`, // Replace with your API URL
        maxBodyLength: Infinity,
        headers: {}
      }

      return Axios.request(config)
        .then(response => response.data)
        .catch(error => {
          console.error(error)

          return [] // Return null for failed requests
        })
    })
    const results = await Promise.all(requests)
    var newdatcurr = results.filter(response => response !== null)
    return newdatcurr
  }
  function makeArrayNull (arr) {
    if (arr.every(element => Array.isArray(element) && element.length === 0)) {
      return null
    }
    return arr
  }

  // Filter Section

  const handleHotelFilter = inputValue => {
    setHotelid(inputValue.value)
    setHotelNameFilter(inputValue)
  }
  const handleChange = newRangeValue => {
    setRangeValue(newRangeValue)
  }
  const PriceFilter = () => {
    setShowPriceFilter(false)
    var data = { type: 'price', min: rangeValue[0], max: rangeValue[1] }
    setHotelid(data)
  }
  const handleCheckboxChange = event => {
    const { name, value } = event.target
    setStarRating(prevDetails => {
      const updatedStarRating = { ...prevDetails }
      if (updatedStarRating[name] !== '0') {
        updatedStarRating[name] = '0'
      } else {
        updatedStarRating[name] = value
      }

      return updatedStarRating
    })
  }
  const StarFilter = () => {
    setShowRatingFilter(false)
    setHotelid(starRating)
  }
  const handleMealTypeChange = event => {
    const { name, value } = event.target

    setMealRating(prevDetails => {
      const updatedStarRating = { ...prevDetails }
      if (updatedStarRating[name] !== '') {
        updatedStarRating[name] = ''
      } else {
        updatedStarRating[name] = value
      }

      return updatedStarRating
    })
  }
  const MealFilter = () => {
    setShowMealTYpeFilter(false)
    setHotelid(mealRating)
  }
  const handleFacilityChange = event => {
    const { name, value } = event.target
    const isSelected = selectedFacilities[name] === value

    if (isSelected) {
      // If selected, remove it from the object
      const updatedFacilities = { ...selectedFacilities }
      delete updatedFacilities[name]
      setSelectedFacilities(updatedFacilities)
    } else {
      // If not selected, add it to the object
      setSelectedFacilities({ ...selectedFacilities, [name]: value })
    }
  }
  const FacilityFilter = () => {
    setShowFacilityFilter(false)
    setHotelid(selectedFacilities)
  }
  return (
    <>
      {loading && <Loading />}
      <Layout>
        <main>
          <section class='hero_in hotels'>
            <div class='wrapper'>
              <div class='container'>
                <h1 class='fadeInUp'>
                  <span></span>Search for hotel
                </h1>
              </div>
            </div>
          </section>
          {/* <div class='filters_listing sticky_horizontal'>
            <div class='container'>
              <ul class='clearfix'>
                <li></li>
                <li>
                  <a
                    class='btn_map'
                    data-bs-toggle='collapse'
                    href='#collapseMap'
                    aria-expanded='false'
                    aria-controls='collapseMap'
                    data-text-swap='Hide map'
                    data-text-original='View on map'
                  >
                    View on map
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
          {/* <div class='collapse' id='collapseMap'>
            <div id='map' class='map'></div>
          </div> */}

          <div class='container margin_60_35'>
            <div class='row'>
              <aside class='col-lg-3' id='sidebar'>
                <div id='filters_col'>
                  <a
                    data-bs-toggle='collapse'
                    href='#collapseFilters'
                    aria-expanded='false'
                    aria-controls='collapseFilters'
                    id='filters_col_bt'
                  >
                    Filters{' '}
                  </a>
                  <div class='collapse show' id='collapseFilters'>
                    <div class='filter_type'>
                      <h6>Name</h6>
                      <Select
                        value={hotelNameFilter}
                        onChange={handleHotelFilter}
                        options={searchHotelList.map(option => ({
                          value: option.hotel_id,
                          label: option.hotel_name
                        }))}
                      />
                    </div>
                    <div class='filter_type'>
                      <h6>Price</h6>
                      <RangeSlider
                        value={rangeValue}
                        onChange={handleChange}
                        min={minValue}
                        tooltip={false}
                        max={maxValue}
                        step={1}
                      />
                      <div className='pt-2'>
                        <div className='fw-bold mb-2'>
                          Min:{' '}
                          <span id='kt_slider_basic_min'>{rangeValue[0]} </span>
                        </div>
                        <div className='fw-bold '>
                          Max:{' '}
                          <span id='kt_slider_basic_max'>{rangeValue[1]}</span>
                        </div>
                      </div>
                      <button
                        onClick={PriceFilter}
                        type='submit'
                        class='btn btn-primary w-100 mt-2'
                      >
                        APPLY
                      </button>
                    </div>
                    <div class='filter_type'>
                      <h6>Star Category</h6>
                      <ul>
                        <li>
                          <label class='container_check'>
                            <span class='cat_star'>
                              {' '}
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />
                            </span>
                            <input
                              type='checkbox'
                              name='rating5'
                              onChange={handleCheckboxChange}
                              value='5'
                            />
                            <span class='checkmark'></span>
                          </label>
                        </li>
                        <li>
                          <label class='container_check'>
                            <span class='cat_star'>
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />
                            </span>
                            <input
                              type='checkbox'
                              name='rating4'
                              onChange={handleCheckboxChange}
                              value='4'
                            />
                            <span class='checkmark'></span>
                          </label>
                        </li>
                        <li>
                          <label class='container_check'>
                            <span class='cat_star'>
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />
                            </span>
                            <input
                              type='checkbox'
                              name='rating3'
                              onChange={handleCheckboxChange}
                              value='3'
                            />
                            <span class='checkmark'></span>
                          </label>
                        </li>
                        <li>
                          <label class='container_check'>
                            <span class='cat_star'>
                              {' '}
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />
                            </span>
                            <input
                              type='checkbox'
                              name='rating2'
                              onChange={handleCheckboxChange}
                              value='2'
                            />
                            <span class='checkmark'></span>
                          </label>
                        </li>
                        <li>
                          <label class='container_check'>
                            <span class='cat_star'>
                              {' '}
                              <FontAwesomeIcon icon={faStar} />
                            </span>
                            <input
                              type='checkbox'
                              name='rating1'
                              onChange={handleCheckboxChange}
                              value='1'
                            />
                            <span class='checkmark'></span>
                          </label>
                        </li>
                      </ul>
                      <button
                        onClick={StarFilter}
                        type='submit'
                        class='btn btn-primary w-100 mt-2'
                      >
                        APPLY
                      </button>
                    </div>
                    <div class='filter_type'>
                      <h6>Meal Type</h6>
                      <ul>
                        <li>
                          <label class='container_check'>
                          Room Only 
                            <input value='ROOM ONLY' name='meal1' onChange={handleMealTypeChange} type='checkbox' />
                            <span class='checkmark'></span>
                          </label>
                        </li>
                        <li>
                          <label class='container_check'>
                          BED AND BREAKFAST
                            <input value='BED AND BREAKFAST' name='meal2' onChange={handleMealTypeChange} type='checkbox' />
                            <span class='checkmark'></span>
                          </label>
                        </li>
                        <li>
                          <label class='container_check'>
                          HALF BOARD
                            <input value='HALF BOARD' name='meal3' onChange={handleMealTypeChange} type='checkbox' />
                            <span class='checkmark'></span>
                          </label>
                        </li>
                        <li>
                          <label class='container_check'>
                          Full BOARD
                            <input value='Full BOARD' name='meal3' onChange={handleMealTypeChange} type='checkbox' />
                            <span class='checkmark'></span>
                          </label>
                        </li>
                      </ul>
                      <button
                        onClick={MealFilter}
                        type='submit'
                        class='btn btn-primary w-100 mt-2'
                      >
                        APPLY
                      </button>
                    </div>
                    <div class='filter_type'>
                      <h6>Facilities</h6>
                      <ul>
                        <li>
                          <label class='container_check'>
                          Wi-fi
                            <input value='Wi-fi' name='facility1' onChange={handleFacilityChange} type='checkbox' />
                            <span class='checkmark'></span>
                          </label>
                        </li>
                        <li>
                          <label class='container_check'>
                          Internet access
                            <input value='Internet access' name='facility2' onChange={handleFacilityChange} type='checkbox' />
                            <span class='checkmark'></span>
                          </label>
                        </li>
                        <li>
                          <label class='container_check'>
                          TV
                            <input value='TV' name='facility3' onChange={handleFacilityChange} type='checkbox' />
                            <span class='checkmark'></span>
                          </label>
                        </li>
                        <li>
                          <label class='container_check'>
                          Wake-up service
                            <input value='Wake-up service' name='facility4' onChange={handleFacilityChange} type='checkbox' />
                            <span class='checkmark'></span>
                          </label>
                        </li>
                        <li>
                          <label class='container_check'>
                          Smoking rooms
                            <input value='Smoking rooms' name='facility5' onChange={handleFacilityChange} type='checkbox' />
                            <span class='checkmark'></span>
                          </label>
                        </li>
                        <li>
                          <label class='container_check'>
                          Wheelchair-accessible
                            <input value='Wheelchair-accessible' name='facility6' onChange={handleFacilityChange} type='checkbox' />
                            <span class='checkmark'></span>
                          </label>
                        </li>
                      </ul>
                      <button
                        onClick={FacilityFilter}
                        type='submit'
                        class='btn btn-primary w-100 mt-2'
                      >
                        APPLY
                      </button>
                    </div>
                  </div>
                </div>
              </aside>
              <div class="col-lg-9" id="list_sidebar">
              {searchHotelList.length === 0 ? (
                    <h5 className='text-center'>
                      Sorry! no hotels found in the given search.
                    </h5>
                  ) : (
                    <div>
                      <HotelCard
                        hotelid={hotelid}
                        hotelDataNew={searchHotelList}
                        hotelsSearchData={hotelsSearchData}
                      />
                    </div>
                  )}
              
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </>
  )
}

export default Hotels
