import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSuitcase,
  faBuilding,
  faCar,
  faGlobe,
  faCalendar,
  faMinus,
  faPlus,
  faPlane,
  faSliders,
  faMapLocation,
  faMapLocationDot,
  faUserClock,
  faClock
} from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import ActivitySearch from './ActivitySearch'
import DatePicker from 'react-datepicker'
import { ToastContainer, toast } from 'react-toastify'
import { NavLink, Link, useNavigate } from 'react-router-dom'
import Loader from '../Loading/Loader'
import { DateRangePicker } from 'rsuite'
import 'rsuite/dist/rsuite.min.css'
import { Hotelapitoken, ApiEndPoint } from '../GlobalData/GlobalData'
import Autocomplete from 'react-google-autocomplete'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import moment from 'moment'
import Axios from 'axios'
import 'react-datepicker/dist/react-datepicker.css'
import HotelSearch from './HotelSearch'
import PackageSearch from './PackageSearch'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import FlightSearch from './FlightSearch'
import TransferNew from './TransferNew'
import { countryListLocal } from '../Data/CountryList'
import {
  fetchHotels,
  fetchHotelsSearh,
  SearchToursDetail
} from '../../Redux/Actions/actions'
import transfer from '../../Images/NewTemplate/car-line-drawing-that-can-be-used-for-svgrepo-com.svg'
import hotel from '../../Images/NewTemplate/hotel_icon_cat.svg'
import flight from '../../Images/NewTemplate/plane-svgrepo-com.svg'
import packages from '../../Images/NewTemplate/ticket-8-svgrepo-com .svg'
import activity from '../../Images/NewTemplate/adventure_icon_cat.svg'
const { beforeToday } = DateRangePicker

function SearchBar ({ onValueChange }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const apitoken = Hotelapitoken()
  var endpoint = ApiEndPoint()
  const [selectedDate, setSelectedDate] = useState(null)
  const [places, setPlace] = useState([])
  const [initialRender, setInitialRender] = useState(true)
  const [countryList, setCountryList] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedNationality, setSelectedNationality] = useState('')
  const [loading, setLoading] = useState(false)
  const [personData, setPersonData] = useState({
    adult: 2,
    children: 0,
    room: 1
  })
  const [placeDetail, setPlaceDetail] = useState({
    city: '',
    country: '',
    countrycode: '',
    lat: 0,
    long: 0,
    checkindate: '',
    Checkoutdate: ''
  })
  const [rooms, setRooms] = useState([
    // Initial room with default values
    {
      adults: 2,
      children: 0,
      childrenAges: []
    }
  ])

  const [showtours, setShowtours] = useState(false)
  const [Showhotels, setShowhotels] = useState(true)
  const [Showflights, setFlights] = useState(false)
  const [Showtransfers, setShowtransfers] = useState(false)
  const [ShowActivities, setShowActivities] = useState(false)
  const [activeItem, setActiveItem] = useState(2)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768) // You can adjust the breakpoint as needed
  const [adultModal, setAdultModal] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState({
    payment: false,
    booking: false,
    trackid: false
  })
  const [dateRange, setDateRange] = useState({
    checkindate: null,
    Checkoutdate: null
  })
  // State for input value

  useEffect(() => {
    const countryOptions = countryListLocal.item.map(country => ({
      value: country.name.common,
      label: country.name.common,
      flag: country.flags.png,
      phoneCode: country.idd.root + country.idd.suffixes[0]
    }))
    setCountryList(countryOptions)
    GetPackages()
  }, [dispatch])

  const Searchhotels = async () => {
    if (places.length === 0) {
      toast.info('Please Select Location.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    }
    if (dateRange.Checkoutdate === null || dateRange.Checkoutdate === '') {
      toast.info('Please Select Hotel Stay.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (moment(placeDetail.checkindate).isSame(moment(), 'day')) {
      toast.info('Please Select a Future Check in Date.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (selectedNationality === '') {
      toast.info('Please Select Nationality.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    }
    setLoading(true)
    // Define your API URL, authToken, and dataToSend as shown in the previous example
    const FormData = {
      //   "token":apitoken,
      //  "currency_slc": 'AFN',
      //  "currency_slc_iso": 'AF',
      destination_name: placeDetail.city,
      country: placeDetail.country,
      lat: placeDetail.lat,
      long: placeDetail.long,
      pin: placeDetail.countrycode,
      cityd: placeDetail.city,
      country_code: placeDetail.countrycode,
      // "daterange_date": placeDetail.checkindate+'-'+placeDetail.Checkoutdate,
      check_in: placeDetail.checkindate,
      check_out: placeDetail.Checkoutdate,
      slc_nationality: String(selectedNationality),
      ...rooms.reduce(
        (acc, item, index) => {
          acc.adult += item.adults
          acc.child += item.children
          acc.room += 1
          acc.Adults.push(item.adults)
          acc.children.push(item.children)
          acc[`child_ages${index + 1}`] = item.childrenAges.map(age =>
            age ? parseInt(age) : 2
          )
          acc.rooms_counter.push(acc.room)
          return acc
        },
        {
          adult: 0,
          child: 0,
          room: 0,
          Adults: [],
          children: [],
          child_ages1: [],
          rooms_counter: [],
          child_ages2: []
        }
      )
    }
    const queryString = new URLSearchParams(FormData).toString()
    navigate(`/hotels?${queryString}`)

    return
    // navigate('/hotels',{state:{FormData}});
    try {
      dispatch(fetchHotelsSearh(FormData))
      // _Live
      const response = await Axios.post(
        endpoint + '/api/search/hotels/new_Live',
        FormData,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
            // Required for CORS support to work
            //  "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
            // "Access-Control-Allow-Headers":
            // "Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale",
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          }
        }
      )
      if (response?.data?.hotels_list.length === 0) {
        setLoading(false)
        toast.info('Sorry! no hotels found in given search.')
        return
      }
      sessionStorage.removeItem('FlightCheckOut')
      navigate('/hotels')
      dispatch(fetchHotels(response.data))
      // Handle the API response here
      setLoading(false)
    } catch (error) {
      // Handle errors here
      setLoading(false)
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768) // Update isMobile based on the screen width
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    // Calculate the total counts from the inputArray
    const adults = rooms.reduce((acc, item) => acc + item.adults, 0)
    const children = rooms.reduce((acc, item) => acc + item.children, 0)
    const roomss = rooms.length
    setPersonData(prevPersonData => ({
      ...prevPersonData,
      adult: adults,
      children: children,
      room: roomss
      // Use square brackets to set the property dynamically based on the 'name' attribute
    }))
    // Update the state with the calculated totals
  }, [rooms])

  // async function fetchData () {
  //   Axios.get('https://restcountries.com/v3.1/all?fields=name,flags,cca2,cca3,idd')
  //         .then(response => {
  //           const countryOptions = response?.data.map((country) => ({
  //               value:country.name.common,
  //               label: country.name.common,
  //               flag: country.flags.png,
  //               phoneCode: country.idd.root+country.idd.suffixes[0],
  //             }));
  //           setCountryList(countryOptions)
  //         })
  //         .catch(error => {
  //           console.error('Error:', error)
  //         })
  // };
  // Callback function to handle date range changes
  const print = () => {
    // console.log(placeDetail)
    // console.log(selectedNationality)
    // console.log(personData)
    // console.log(rooms)
  }

  const GetPackages = async () => {
    var token = Hotelapitoken()
    const fullURL = window.location.href
    var data = {
      token: token,
      currentURL: fullURL,
      limit: 6
    }
  }

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false) // Update the initialRender state to false.
      return // Exit the effect early to avoid running the code below.
    }

    if (places.length !== 0) {
      // debugger
      if (
        places.address_components.filter(
          f =>
            JSON.stringify(f.types) ===
            JSON.stringify(['locality', 'political'])
        )[0]
      ) {
        const city1 = places.address_components.filter(
          f =>
            JSON.stringify(f.types) ===
            JSON.stringify(['locality', 'political'])
        )[0]?.short_name
        // console.log('city1=' + city1)
        setPlaceDetail(prevPlaceDetail => ({
          ...prevPlaceDetail,
          city: city1
        }))
      } else {
        const city1 = places.address_components.filter(
          f =>
            JSON.stringify(f.types) ===
            JSON.stringify(['administrative_area_level_2', 'political'])
        )[0]?.short_name
        // console.log('city1=' + city1)
        setPlaceDetail(prevPlaceDetail => ({
          ...prevPlaceDetail,
          city: city1
        }))
      }

      if (
        places.address_components.filter(
          f =>
            JSON.stringify(f.types) ===
            JSON.stringify(['locality', 'political'])
        )[0]
      ) {
        const displayCity1 = places.address_components.filter(
          f =>
            JSON.stringify(f.types) ===
            JSON.stringify(['locality', 'political'])
        )[0]?.long_name
        // console.log('displayCity1=' + displayCity1)
      } else {
        const displayCity1 = places.address_components.filter(
          f =>
            JSON.stringify(f.types) ===
            JSON.stringify(['administrative_area_level_2', 'political'])
        )[0]?.long_name
        // console.log('displayCity1=' + displayCity1)
      }

      if (
        places.address_components.filter(
          f =>
            JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
        )[0]
      ) {
        const country_code = places.address_components.filter(
          f =>
            JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
        )[0]?.short_name
        // console.log('country_code=' + country_code)
        setPlaceDetail(prevPlaceDetail => ({
          ...prevPlaceDetail,
          countrycode: country_code
        }))
      }
      if (
        places.address_components.filter(
          f =>
            JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
        )[0]
      ) {
        const country = places.address_components.filter(
          f =>
            JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
        )[0]?.long_name
        setPlaceDetail(prevPlaceDetail => ({
          ...prevPlaceDetail,
          country: country
        }))
      }
      var address = places.formatted_address
      var latitude = places.geometry.location.lat()
      setPlaceDetail(prevPlaceDetail => ({
        ...prevPlaceDetail,
        lat: latitude
      }))
      var longitude = places.geometry.location.lng()
      setPlaceDetail(prevPlaceDetail => ({
        ...prevPlaceDetail,
        long: longitude
      }))
      const latlng = new window.google.maps.LatLng(latitude, longitude)
      const geocoder = new window.google.maps.Geocoder()
      geocoder.geocode({ latLng: latlng }, function (results, status) {
        if (status === window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            const addressComponents = results[0].address_components
            const lastAddressComponent =
              addressComponents[addressComponents.length - 1]
            const country = lastAddressComponent.short_name
            const state =
              addressComponents[addressComponents.length - 3].long_name
            const city =
              addressComponents[addressComponents.length - 4].long_name
          }
        }
      })
    }
  }, [places, initialRender])

  const toggleModal = divNumber => {
    if (divNumber === 1) {
      setModalIsOpen({ payment: !modalIsOpen.payment })
    } else if (divNumber === 2) {
      setModalIsOpen({ booking: !modalIsOpen.booking })
    } else if (divNumber === 3) {
      setModalIsOpen({ trackid: !modalIsOpen.trackid })
    }
  }
  const handleDateChange = date => {
    setSelectedDate(date)
  }

  const handleCountryChange = selectedOption => {
    // debugger
    setSelectedCountry(selectedOption)
    setSelectedNationality(selectedOption.value) // Update the selected nationality when the user makes a selection
  }

  const toggleDiv = divNumber => {
    onValueChange(divNumber);
    if (divNumber === 1) {
      setActiveItem(divNumber)
      setShowtransfers(false)
      setFlights(false)
      setShowtours(true)
      setShowActivities(false)
      setShowhotels(false) // Hide div2 when showing div1
    } else if (divNumber === 2) {
      setActiveItem(divNumber)
      setShowtransfers(false)
      setShowhotels(true)
      setFlights(false)
      setShowActivities(false)
      setShowtours(false) // Hide div1 when showing div2
    } else if (divNumber === 3) {
      setActiveItem(divNumber)
      setShowtransfers(true)
      setShowhotels(false)
      setShowActivities(false)
      setFlights(false)
      setShowtours(false) // Hide div1 when showing div2
    } else if (divNumber === 4) {
      setActiveItem(divNumber)
      setShowtransfers(false)
      setShowhotels(false)
      setShowActivities(false)
      setFlights(true)
      setShowtours(false) // Hide div1 when showing div2
    } else if (divNumber === 5) {
      setActiveItem(divNumber)
      setShowtransfers(false)
      setShowhotels(false)
      setShowActivities(true)
      setFlights(false)
      setShowtours(false) // Hide div1 when showing div2
    }
  }
  const toggleAdultModal = () => {
    setAdultModal(!adultModal)
  }
  const childAgearray = [
    '1 Year',
    '2 Year',
    '3 Year',
    '4 Year',
    '5 Year',
    '6 Year',
    '7 Year',
    '8 Year',
    '9 Year',
    '10 Year',
    '11 Year',
    '12 Year',
    '13 Year',
    '14 Year',
    '15 Year',
    '16 Year',
    '17 Year'
  ]
  const handleDateRangeChange = value => {
    if (value === null) {
      // Dates have been removed, reset the date range state
      setPlaceDetail(prevPlaceDetail => ({
        ...prevPlaceDetail,
        checkindate: '',
        Checkoutdate: ''
      }))

      // Reset the dateRange state
      setDateRange(null)
    } else {
      // Dates are selected, format and update the state
      setPlaceDetail(prevPlaceDetail => ({
        ...prevPlaceDetail,
        checkindate: moment(value[0]).format('YYYY-MM-DD'),
        Checkoutdate: moment(value[1]).format('YYYY-MM-DD')
      }))

      // Update the dateRange state
      setDateRange(value)
    }
  }

  const handleAdultIncrement = roomIndex => {
    // Increment the number of adults for a specific room
    const updatedRooms = [...rooms]
    if (updatedRooms[roomIndex].adults < 6) {
      updatedRooms[roomIndex].adults += 1
      setRooms(updatedRooms)
    }
  }

  const handleAdultDecrement = roomIndex => {
    // Decrement the number of adults for a specific room
    const updatedRooms = [...rooms]
    if (updatedRooms[roomIndex].adults > 1) {
      updatedRooms[roomIndex].adults -= 1
      setRooms(updatedRooms)
    }
  }

  const handleChildrenIncrement = roomIndex => {
    // Increment the number of children for a specific room, if less than 4
    const updatedRooms = [...rooms]
    if (updatedRooms[roomIndex].children < 4) {
      updatedRooms[roomIndex].children += 1
      setRooms(updatedRooms)
    }
  }

  const handleChildrenDecrement = roomIndex => {
    // Decrement the number of children for a specific room
    const updatedRooms = [...rooms]
    if (updatedRooms[roomIndex].children > 0) {
      updatedRooms[roomIndex].children -= 1
      setRooms(updatedRooms)
    }
  }

  const handleChildAgeChange = (roomIndex, childIndex, value) => {
    // Update the age of a specific child for a specific room
    const updatedRooms = [...rooms]
    updatedRooms[roomIndex].childrenAges[childIndex] = value
    setRooms(updatedRooms)
  }
  const addRoom = () => {
    // Add a new room with default values
    setRooms([...rooms, { adults: 2, children: 0, childrenAges: [] }])
  }

  const removeLastRoom = () => {
    if (rooms.length > 1) {
      // Remove the last room by slicing the array
      setRooms(rooms.slice(0, -1))
    }
  }
  const handleDeleteRoomByIndex = roomIndex => {
    const updatedRooms = rooms.filter((room, index) => index !== roomIndex)
    setRooms(updatedRooms)
  }
  return (
    <>
      <ToastContainer />
      <Modal isOpen={adultModal} centered={true} toggle={toggleAdultModal}>
        <ModalHeader toggle={toggleAdultModal}>Select Detail</ModalHeader>
        <ModalBody>
          <div className='p-3'>
            {rooms.map((room, roomIndex) => (
              <div key={roomIndex}>
                <div
                  className='hotel-detail-border d-flex text-center fw-bold mb-2'
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <p className='fw-bold'>Room {roomIndex + 1}</p>
                  {roomIndex > 0 && ( // Check if roomIndex is greater than 0
                    <button
                      className='delete-room-button m-1'
                      onClick={() => handleDeleteRoomByIndex(roomIndex)}
                    >
                      Delete
                    </button>
                  )}
                </div>
                <div>
                  <div className='select-child'>
                    <div class='f4878764f1'>
                      <label class='a984a491d9 fw-bold' for='group_adults'>
                        Adults
                      </label>
                    </div>
                    <div class='d-flex fff'>
                      <button
                        className='adult-modal-btn'
                        name={`adults-${roomIndex}`}
                        onClick={() => handleAdultDecrement(roomIndex)}
                      >
                        <i class='fas fa-minus'>
                          <FontAwesomeIcon size='sm' icon={faMinus} />
                        </i>
                      </button>
                      <span className='d723d73d5f fw-bold' id='input'>
                        {room.adults}
                      </span>
                      <button
                        className='adult-modal-btn'
                        name={`adults-${roomIndex}`}
                        onClick={() => handleAdultIncrement(roomIndex)}
                        id='increment'
                      >
                        <i class='fas fa-plus'>
                          <FontAwesomeIcon size='sm' icon={faPlus} />
                        </i>
                      </button>
                    </div>
                  </div>
                  <div className='select-child'>
                    <div class='f4878764f1'>
                      <label class='a984a491d9 fw-bold' for='group_adults'>
                        Children
                      </label>
                    </div>
                    <div class='d-flex fff'>
                      <button
                        className='adult-modal-btn'
                        onClick={() => handleChildrenDecrement(roomIndex)}
                        id='decrement'
                      >
                        <i class='fas fa-minus'>
                          <FontAwesomeIcon size='sm' icon={faMinus} />
                        </i>
                      </button>
                      <span className='d723d73d5f fw-bold' id='input'>
                        {room.children}
                      </span>
                      <button
                        className='adult-modal-btn'
                        onClick={() => handleChildrenIncrement(roomIndex)}
                        id='increment'
                      >
                        <i class='fas fa-plus'>
                          <FontAwesomeIcon size='sm' icon={faPlus} />
                        </i>
                      </button>
                    </div>
                  </div>
                  <div
                    className='select-child'
                    style={{ justifyContent: 'left' }}
                  >
                    {room.children > 0 && (
                      <div className='row w-100 '>
                        {Array.from(
                          { length: room.children },
                          (_, childIndex) => (
                            <div
                              key={childIndex}
                              className='m-1 '
                              style={{ width: '45%', alignItems: 'center' }}
                            >
                              <label>Child {childIndex + 1} (Age)</label>
                              <select
                                class='form-select child-age-select'
                                name='child1'
                                onChange={e =>
                                  handleChildAgeChange(
                                    roomIndex,
                                    childIndex,
                                    e.target.value
                                  )
                                }
                                aria-label='Default select example'
                              >
                                <option selected>Age needed</option>
                                {childAgearray.map((item, index) => (
                                  <option key={index} value={index + 1}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className='select-child'>
            <div class='f4878764f1 pe-4'>
              <label class='a984a491d9 fw-bold' for='group_adults'>
                Room
              </label>
            </div>
            <div class='d-flex fff'>
              <button
                className='adult-modal-btn'
                onClick={removeLastRoom}
                id='decrement'
              >
                <i class='fas fa-minus'>
                  <FontAwesomeIcon size='sm' icon={faMinus} />
                </i>
              </button>
              <span className='d723d73d5f fw-bold' id='input'>
                {rooms.length}
              </span>
              <button
                className='adult-modal-btn'
                onClick={addRoom}
                id='increment'
              >
                <i class='fas fa-plus'>
                  <FontAwesomeIcon size='sm' icon={faPlus} />
                </i>
              </button>
            </div>
          </div>
          <Button color='secondary' onClick={toggleAdultModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      {loading ? (
        <Loader />
      ) : (
        <div>
          <div class='switch-field'>
            <input
              type='radio'
              id='Hotels'
              onClick={() => toggleDiv(2)}
              checked={activeItem === 2 ? 'true' : ''}
              name='listing_filter'
              value='hotels'
            />
            <label for='Hotels'>
              <img src={hotel} alt='' />
              Hotels
            </label>
            {/* <input
              type='radio'
              id='Tours'
              name='listing_filter'
              value='tours'
              onClick={() => toggleDiv(4)}
              checked={activeItem === 4 ? 'true' : ''}
            />
            <label for='Tours'>
              <img src={flight} alt='' />
              Flight
            </label> */}
            <input
              type='radio'
              onClick={() => toggleDiv(1)}
              checked={activeItem === 1 ? 'true' : ''}
              id='Restaurants'
              name='listing_filter'
              value='restaurants'
            />
            <label for='Restaurants'>
              <img src={packages} alt='' />
              Packages
            </label>
            <input
              type='radio'
              id='Adventure'
              onClick={() => toggleDiv(5)}
              checked={activeItem === 5 ? 'true' : ''}
              name='listing_filter'
              value='adventure'
            />
            <label for='Adventure'>
              <img src={activity} alt='' />
              Activities
            </label>
            <input
              type='radio'
              id='Transfer'
              onClick={() => toggleDiv(3)}
              checked={activeItem === 3 ? 'true' : ''}
              name='listing_filter'
              value='Transfer'
            />
            <label for='Transfer'>
              <img src={transfer} alt='' />
              Transfer
            </label>
          </div>
          {Showhotels && (
            <div class='row g-0 custom-search-home'>
              <div class='col-lg-3 px-2 mt-2'>
                <div>
                  <Autocomplete
                    className='form-control'
                    placeholder='Where are you going?'
                    apiKey='AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY'
                    onPlaceSelected={place => {
                      setPlace(place)
                    }}
                    options={{
                      types: ['establishment', 'geocode'],
                      componentRestrictions: null
                    }}
                    language='en'
                  />
                </div>
              </div>
              <div class='col-lg-3 px-2 mt-2'>
                <DateRangePicker
                  onChange={handleDateRangeChange}
                  placeholder='Check in ~ Check out'
                  value={dateRange}
                  showOneCalendar={isMobile}
                  disabledDate={beforeToday()}
                  className='w-100 '
                />
              </div>
              <div class='col-lg-3 px-2 mt-2'>
                <div class='hotel-search-person'>
                  <a style={{ cursor: 'pointer' }} onClick={toggleAdultModal}>
                    Adult <span class='qtyTotal'>{personData.adult}</span> Child{' '}
                    <span class='qtyTotal'>{personData.children}</span> Room{' '}
                    <span class='qtyTotal'>{personData.room}</span>
                  </a>
                </div>
              </div>
              <div class='col-lg-3 px-2 mt-2'>
                <Select
                  options={countryList}
                  isSearchable={true}
                  placeholder='Select'
                  onChange={handleCountryChange}
                  value={selectedCountry}
                  getOptionLabel={option => (
                    <div>
                      <img
                        src={option.flag}
                        alt={option.label}
                        style={{
                          width: '20px',
                          marginRight: '8px'
                        }}
                      />
                      {option.label}
                    </div>
                  )}
                  getOptionValue={option => option.value}
                />
              </div>
              <div class='col-lg-3 px-2 mt-2'>
                <input
                  type='submit'
                  onClick={Searchhotels}
                  class='btn_search'
                  value='Search'
                />
              </div>
            </div>
          )}
          {Showflights && <FlightSearch />}
          {ShowActivities && <ActivitySearch />}
          {Showtransfers && <TransferNew />}
          {showtours && <PackageSearch />}
        </div>
        // <div className='g-form-control'>

        //   <div class='tab-content'>
        //   {Showhotels && (
        //     <div role='tabpanel' class='tab-pane  active ' id='bravo_hotel'>
        //       <div class='form bravo_form'>
        //         <div class='g-field-search'>
        //           <div class='row'>
        //             <div class='col-md-3 border-right'>
        //               <div class='form-group'>
        //                 <div class='form-content'>
        //                   <label> Location </label>
        //                   <div class='smart-search'>
        //                     <Autocomplete
        //                       className='smart-search-location parent_text form-control'
        //                       placeholder='Where are you going?'
        //                       apiKey='AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY'
        //                       onPlaceSelected={place => {
        //                         setPlace(place)
        //                       }}
        //                       options={{
        //                         types: ['establishment', 'geocode'],
        //                         componentRestrictions: null
        //                       }}
        //                       language='en'
        //                     />
        //                   </div>
        //                 </div>
        //               </div>
        //             </div>
        //             <div class='col-md-3 border-right'>
        //               <div class='form-group'>
        //                 <div class='form-content'>
        //                   <div class='form-date-search-hotel'>
        //                     <div class=''>
        //                       <div class='check-in-wrapper'>
        //                         <label>Stay</label>
        //                         <div>
        //                           <DateRangePicker
        //                             onChange={handleDateRangeChange}
        //                             placeholder='Check in ~ Check out'
        //                             value={dateRange}
        //                             showOneCalendar={isMobile}
        //                             disabledDate={beforeToday()}
        //                             className='w-100'
        //                           />
        //                         </div>
        //                       </div>
        //                     </div>
        //                   </div>
        //                 </div>
        //               </div>
        //             </div>
        //             <div class='col-md-3 border-right'>
        //               <div class='form-select-guests'>
        //                 <div class='form-group '>
        //                   <div
        //                     className='form-content '
        //                     onClick={toggleAdultModal}
        //                   >
        //                     <div class='wrapper-more'>
        //                       <label> Guests </label>
        //                       <div class='render mt-2'>
        //                         <span class='adults'>
        //                           <span class='one '>
        //                             {personData.adult} Adult
        //                           </span>{' '}
        //                         </span>
        //                         -
        //                         <span class='children'>
        //                           <span class='one ' data-html=':count Child'>
        //                             {personData.children} Child
        //                           </span>
        //                         </span>
        //                         -
        //                         <span class='children'>
        //                           <span class='one ' data-html=':count Child'>
        //                             {personData.room} Room
        //                           </span>
        //                         </span>
        //                       </div>
        //                     </div>
        //                   </div>
        //                 </div>
        //               </div>
        //             </div>
        //             <div class='col-md-3 border-right'>
        //               <div class='form-select-guests'>
        //                 <div class='form-group '>
        //                   <div className='form-content '>
        //                     <div class='wrapper-more search-nationality'>
        //                       <label> Nationality </label>
        //                       <Select
        //                         options={countryList}
        //                         isSearchable={true}
        //                         placeholder='Select'
        //                         onChange={handleCountryChange}
        //                         value={selectedCountry}
        //                         getOptionLabel={option => (
        //                           <div>
        //                             <img
        //                               src={option.flag}
        //                               alt={option.label}
        //                               style={{
        //                                 width: '20px',
        //                                 marginRight: '8px'
        //                               }}
        //                             />
        //                             {option.label}
        //                           </div>
        //                         )}
        //                         getOptionValue={option => option.value}
        //                       />
        //                     </div>
        //                   </div>
        //                 </div>
        //               </div>
        //             </div>
        //           </div>
        //         </div>
        //         <div class='g-button-submit'>
        //           <button
        //             class='btn btn-primary btn-search'
        //             type='submit'
        //             onClick={Searchhotels}
        //           >
        //             Search
        //           </button>
        //         </div>
        //       </div>{' '}
        //     </div>
        //   )}
        //   {ShowActivities && (
        //     <ActivitySearch/>
        //   )}
        //   {showtours && (
        //     <PackageSearch/>
        //   )}
        //     {Showtransfers && (
        //     <TransferNew/>
        //     )}
        //     {Showflights && (
        //       <FlightSearch/>
        //     )}
        //   </div>
        // </div>
      )}
    </>
  )
}

export default SearchBar
