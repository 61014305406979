import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { ViewTourDetail } from '../../Redux/Actions/actions'
import { useDispatch, useSelector } from 'react-redux'
import OwlCarousel from 'react-owl-carousel'
import { fetchHotelDetail } from '../../Redux/Actions/actions'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import Axios from 'axios'
import {
  ApiEndPoint,
  Hotelapitoken,
  CurrencyConverter
} from '../../Components/GlobalData/GlobalData'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import emailjs from 'emailjs-com'
import Loader from '../../Components/Loading/Loader'
import { fetchHotelsSearh, fetchHotels } from '../../Redux/Actions/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faStar } from '@fortawesome/free-solid-svg-icons'
import { Tooltip, Whisper } from 'rsuite'
function IndexComponents () {
  const { t, i18n } = useTranslation()
  const language = i18n.language
  var token = Hotelapitoken()
  var apiendpoint = ApiEndPoint()
  const [isloading, setLoading] = useState(false)
  const [isloadingMakkah, setLoadingMakkah] = useState(true)
  const [isloadingMadinah, setLoadingMadinah] = useState(true)
  const [makkahPromotion, setMakkahPromotion] = useState(true)
  const [madinahPromotion, setMadinahPromotion] = useState(true)
  const [makkahHotels, setMakkahHotels] = useState([])
  const [MadinahHotels, setMadinahHotels] = useState([])
  const [packagescard, setPackagescard] = useState([])
  const [allcateogries, setAllCateogries] = useState([])
  const [makkahHotelsRes, setMakkahHotelsRes] = useState([])
  const [MadinahHotelsRes, setMadinahHotelsRes] = useState([])
  const [allTours, setAllTours] = useState([])
  const [showTours, setShowTours] = useState([])
  const [GetCurrency, setGetCurrency] = useState(true)
  const [makkahDetail, setMakkahDetail] = useState({})
  const [madinahDetail, setMadinahDetail] = useState({})
  const [itemsToShow, setItemsToShow] = useState(4)
  const [baseCName, setBaseCName] = useState('GBP')
  const [showPrice, setShowPrice] = useState(false)
  const [baseCurrency, setBaseCurrency] = useState([])
  const Dispatch = useDispatch()
  const navigation = useNavigate()
  const [activeItem, setActiveItem] = useState(0) // Initialize activeItem with the default value (0).
  const CurrencyRates = useSelector(state => state.hotels.Currency)
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency)
  const handleCategorySelect = index => {
    setActiveItem(index)
    var filter = allTours.filter(
      tour => Number(tour.categories) === allcateogries[index].id
    )

    setShowTours(
      filter.sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
    )
    // Set the selected category as the active one.
  }

  useEffect(() => {
    // Add an event listener to track window width changes
    function handleResize () {
      if (window.innerWidth > 1000) {
        setItemsToShow(4) // For smaller screens, show 1 item
      } else if (window.innerWidth > 768 && window.innerWidth < 1000) {
        setItemsToShow(3) // For smaller screens, show 1 item
      } else if (window.innerWidth > 530 && window.innerWidth < 768) {
        setItemsToShow(2) // For smaller screens, show 1 item
      } else if (window.innerWidth < 530) {
        setItemsToShow(1) // For larger screens, show 4 items (you can adjust this)
      }
    }

    // Initialize the number of items based on the current window width
    handleResize()

    // Attach the event listener
    window.addEventListener('resize', handleResize)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    getUserLocation()
  }, [])
  function getUserLocation () {
    // Fetch user's location data
    fetch(
      'https://api.geoapify.com/v1/ipinfo?&apiKey=0708571ae58c4688824a7d01ca397041'
    )
      .then(response => response.json())
      .then(data => {
        SearchMakkahhotels(data.country.name)
        SearchMadinahhotels(data.country.name)
        localStorage.setItem('usercountry', data.country.name)
        // Handle location data as needed
      })
      .catch(error => {
        console.error('Error fetching user location:', error)
      })
  }
  const fetchHotelDetails = async (hotelId, provider) => {
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: token
    }
    try {
      const response = await Axios.post(
        apiendpoint + '/api/hotels/mata_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      setMakkahDetail(prevDetails => ({
        ...prevDetails,
        [hotelId]: response.data
      }))
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  }

  const fetchHotelDetails1 = async (hotelId, provider) => {
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: token
    }
    try {
      const response = await Axios.post(
        apiendpoint + '/api/hotels/mata_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      setMadinahDetail(prevDetails => ({
        ...prevDetails,
        [hotelId]: response.data
      }))
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  }
  const SearchMakkahhotels = async userCountry => {
    const currentDate = new Date()
    const twoDaysLater = new Date(currentDate)
    twoDaysLater.setDate(currentDate.getDate() + 2)
    const threeDaysLater = new Date(currentDate)
    threeDaysLater.setDate(currentDate.getDate() + 3)
    // Define your API URL, authToken, and dataToSend as shown in the previous example
    const FormData = {
      token: token,
      currency_slc: 'AFN',
      currency_slc_iso: 'AF',
      destination_name: 'Makkah',
      country: 'Saudi Arabia',
      lat: 21.4240968,
      long: 39.81733639999999,
      pin: 'SA',
      cityd: 'Makkah',
      country_code: 'SA',
      check_in: moment(twoDaysLater).format('YYYY-MM-DD'),
      check_out: moment(threeDaysLater).format('YYYY-MM-DD'),
      slc_nationality: userCountry,
      adult: 2,
      child: 0,
      room: 1,
      Adults: [2],
      children: [0],
      child_ages1: [],
      rooms_counter: [1],
      child_ages2: []
    }
    try {
      Dispatch(fetchHotelsSearh(FormData))
      const response = await Axios.post(
        apiendpoint + '/api/search/hotels/new_Live',
        FormData,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      sessionStorage.removeItem('FlightCheckOut')
      var promotion_Hotel_List = response.data.hotels_list.filter(item =>
        item.rooms_options.some(room => room.room_Promotions_Exist === '1')
      )
      setMakkahHotelsRes(response.data)
      if (promotion_Hotel_List.length !== 0) {
        promotion_Hotel_List.forEach(hotel => {
          if (!makkahDetail[hotel.hotel_id]) {
            fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
          }
        })
      } else {
        response.data.hotels_list.slice(0, 4).forEach(hotel => {
          if (!makkahDetail[hotel.hotel_id]) {
            fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
          }
        })
      }
      if (response.data.hotels_list.length !== 0) {
        setGetCurrency(false)
        const currencies = []
        response.data.hotels_list.forEach(hotel => {
          if (!currencies.includes(hotel.hotel_curreny)) {
            currencies.push(hotel.hotel_curreny)
          }
        })
        const currencyResponse = await AllCurrency(currencies)
        const result = makeArrayNull(currencyResponse)
        if (result === null) {
          localStorage.setItem('AllHotelCurr', null)
        } else {
          localStorage.setItem('AllHotelCurr', JSON.stringify(currencyResponse))
        }

        setBaseCurrency(currencyResponse)
        // AllCurrency(response.data.hotels_list[0]?.hotel_curreny);
      }
      setLoadingMakkah(false)
      if (promotion_Hotel_List.length !== 0) {
        setMakkahHotels(promotion_Hotel_List)
      } else {
        setMakkahPromotion(false)
        setMakkahHotels(response.data)
      }
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  }
  const SearchMadinahhotels = async userCountry => {
    const currentDate = new Date()
    const twoDaysLater = new Date(currentDate)
    twoDaysLater.setDate(currentDate.getDate() + 2)
    const threeDaysLater = new Date(currentDate)
    threeDaysLater.setDate(currentDate.getDate() + 3)
    // Define your API URL, authToken, and dataToSend as shown in the previous example
    const FormData = {
      token: token,
      currency_slc: 'AFN',
      currency_slc_iso: 'AF',
      destination_name: 'Madinah',
      country: 'Saudi Arabia',
      lat: 24.4672132,
      long: 39.6024496,
      pin: 'SA',
      cityd: 'Madinah',
      country_code: 'SA',
      check_in: moment(twoDaysLater).format('YYYY-MM-DD'),
      check_out: moment(threeDaysLater).format('YYYY-MM-DD'),
      slc_nationality: userCountry,
      adult: 2,
      child: 0,
      room: 1,
      Adults: [2],
      children: [0],
      child_ages1: [],
      rooms_counter: [1],
      child_ages2: []
    }
    try {
      Dispatch(fetchHotelsSearh(FormData))
      const response = await Axios.post(
        apiendpoint + '/api/search/hotels/new_Live',
        FormData,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      sessionStorage.removeItem('FlightCheckOut')

      var promotion_Hotel_List = response.data.hotels_list.filter(item =>
        item.rooms_options.some(room => room.room_Promotions_Exist === '1')
      )
      setLoadingMadinah(false)
      setMadinahHotelsRes(response.data)
      if (promotion_Hotel_List.length !== 0) {
        promotion_Hotel_List.forEach(hotel => {
          if (!madinahDetail[hotel.hotel_id]) {
            fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
          }
        })
        setMadinahHotels(promotion_Hotel_List)
      } else {
        setMadinahPromotion(false)
        response.data.hotels_list.slice(0, 4).forEach(hotel => {
          if (!madinahDetail[hotel.hotel_id]) {
            fetchHotelDetails1(hotel.hotel_id, hotel.hotel_provider)
          }
        })
        setMadinahHotels(response.data)
      }
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  }
  const RoomDetailPage = async (id, index, city) => {
    localStorage.setItem('HotelCurrency', null)
    var hotelRoomdetail = []
    if (city === 'makkah') {
      hotelRoomdetail = makkahHotels.hotels_list.filter(
        item => item.hotel_id == id
      )
    } else if (city === 'madinah') {
      hotelRoomdetail = MadinahHotels.hotels_list.filter(
        item => item.hotel_id == id
      )
    }
    if(hotelRoomdetail.length !==0){
      Dispatch(fetchHotels(hotelRoomdetail))
    }

    setLoading(true)
    try {
      const data = {
        token: token,
        hotel_search_data: JSON.stringify(hotelRoomdetail[0]),
        hotel_code: String(id)
      }
      const response = await Axios.post(
        apiendpoint + '/api/hotels/details_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            // Required for CORS support to work
            'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
            'Access-Control-Allow-Headers':
              'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          }
        }
      )

      // Handle the API response here
      Dispatch(fetchHotelDetail(response.data.hotel_details))
      navigation(`/hotel_detail/${id}`, { state: { index } })
      setLoading(false)
    } catch (error) {
      // Handle errors here
      setLoading(false)
      console.error('Error:', error)
    }
  }
  // const AllCurrency =async currencyArray => {
  //   const token = CurrencyConverter();
  //   const requests =currencyArray.length!==0 && currencyArray.map(currencynew => {
  //     const config = {
  //       method: 'get',
  //       url: `https://v6.exchangerate-api.com/v6/${token}/latest/${currencynew}`, // Replace with your API URL
  //       maxBodyLength: Infinity,
  //       headers: {}
  //     };

  //     return Axios.request(config)
  //       .then(response => response.data)
  //       .catch(error => {
  //         console.error(error);
  //         setShowPrice(false);
  //         return []; // Return null for failed requests
  //       });
  //   });
  //   const results = await Promise.all(requests);
  //   var newdatcurr=results.filter(response => response !== null);
  //   if (newdatcurr.length > 0) {
  //     setShowPrice(true); // Show price if at least one request succeeded
  //   } else {
  //     setShowPrice(false); // Hide price if all requests failed
  //   }
  //   return newdatcurr;
  // };
  const AllCurrency = async currencyArray => {
    const token = CurrencyConverter() // Assumes this function returns the API token
    const requests =
      currencyArray.length !== 0 &&
      currencyArray.map(currency => {
        const config = {
          method: 'get',
          url: `https://v6.exchangerate-api.com/v6/${token}/latest/${currency}`, // API URL
          maxBodyLength: Infinity,
          headers: {}
        }

        return Axios.request(config)
          .then(response => {
            return response.data // Return the data from the response
          })
          .catch(error => {
            console.error(error)
            return null // Return null for failed requests
          })
      })

    const results = await Promise.all(requests)

    const newdatcurr = results.filter(response => response !== null) // Filter out failed requests

    if (newdatcurr.length > 0) {
      setShowPrice(true) // Show price if at least one request succeeded
    } else {
      setShowPrice(false) // Hide price if all requests failed
    }

    return newdatcurr
  }
  function makeArrayNull (arr) {
    // Check if every element in the array is an empty array
    if (arr.every(element => Array.isArray(element) && element.length === 0)) {
      return null
    }
    return arr
  }
  const renderPrice = (price, currency) => {
    var currencyData = baseCurrency
    var selectedcurr = localStorage.getItem('DefaultCurrency')
    if (
      selectedcurr === currency ||
      currencyData === null ||
      currencyData.length === 0 ||
      currencyData === null
    ) {
      return Number(price).toFixed(0)
    } else {
      var filterroomcurrdata = currencyData.filter(
        item => item.base_code === currency
      )
      if (CurrencyRates === undefined) {
        const gbpprice = filterroomcurrdata[0].conversion_rates[baseCName] // Use square brackets to access the property
        var baseprice = Number(gbpprice) * Number(price)
      } else {
        var select123 = CurrencyRates.selectedcurrency
        const gbpprice = filterroomcurrdata[0].conversion_rates[baseCName]
        var baseprice1 = Number(gbpprice) * Number(price)
        const gbpprice2 = GBPCurrencyRates.conversion_rates[select123] // Use square brackets to access the property
        var baseprice = Number(gbpprice2) * Number(baseprice1)
      }
      return baseprice.toFixed(0)
    }
  }
  const calculateMarkup = (
    price,
    adminmarkup,
    admintype,
    clientmarkup,
    clienttype
  ) => {
    let markupprice = 0
    let adminmarkupprice = 0
    let clientmarkupprice = 0
    let finalpricemarkup = Number(price)
    if (Number(adminmarkup) !== 0) {
      if (admintype === 'Percentage') {
        markupprice = (price * Number(adminmarkup)) / 100
      } else {
        markupprice = Number(adminmarkup)
      }
      adminmarkupprice = markupprice
      finalpricemarkup += markupprice
      // savemarkup.admin=adminmarkupprice;
      // savemarkup.final=finalpricemarkup;
    }
    if (Number(clientmarkup) !== 0) {
      if (clienttype === 'Percentage') {
        markupprice = (finalpricemarkup * Number(clientmarkup)) / 100
      } else {
        markupprice = Number(clientmarkup)
      }
      clientmarkupprice = markupprice
      finalpricemarkup += markupprice
    }
    if (Number(finalpricemarkup) % 1 !== 0) {
      return Number(finalpricemarkup).toFixed(2)
    }
    return finalpricemarkup
  }
  const promotionFromDate = rooms => {
    const roomWithPromotion = rooms.find(
      room => room.room_Promotions_Exist === '1'
    )
    if (roomWithPromotion) {
      const formattedDate = moment(
        roomWithPromotion.room_Promotions.availible_from
      ).format('DD-MM-YYYY')
      return formattedDate // or assign it to a variable/property as needed
    }
  }
  const promotionToDate = rooms => {
    const roomWithPromotion = rooms.find(
      room => room.room_Promotions_Exist === '1'
    )
    if (roomWithPromotion) {
      const formattedDate = moment(
        roomWithPromotion.room_Promotions.availible_to
      ).format('DD-MM-YYYY')
      return formattedDate // or assign it to a variable/property as needed
    }
  }
  return (
    <>
      {isloading && <Loader />}
      <section class='add_bottom_45'>
        {makkahPromotion ? (
          <div class=''>
            <div class='main_title_3'>
              <span>
                <em></em>
              </span>
              <h2>Promotion Hotels in Makkah</h2>
              <p>Hotel highly rated for thoughtful design</p>
            </div>
            {isloadingMakkah ? (
              <div className='row'>
                <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                  <div class='theme_common_box_two img_hover'>
                    <div class='theme_two_box_img'>
                      <div
                        style={{ background: '#c88698', height: '12em' }}
                      ></div>
                    </div>
                    <div class='theme_two_box_content'>
                      <p class='card-text placeholder-glow'>
                        <span class='placeholder col-7'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                  <div class='theme_common_box_two img_hover'>
                    <div class='theme_two_box_img'>
                      <div
                        style={{ background: '#c88698', height: '12em' }}
                      ></div>
                    </div>
                    <div class='theme_two_box_content'>
                      <p class='card-text placeholder-glow'>
                        <span class='placeholder col-7'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                  <div class='theme_common_box_two img_hover'>
                    <div class='theme_two_box_img'>
                      <div
                        style={{ background: '#c88698', height: '12em' }}
                      ></div>
                    </div>
                    <div class='theme_two_box_content'>
                      <p class='card-text placeholder-glow'>
                        <span class='placeholder col-7'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                  <div class='theme_common_box_two img_hover'>
                    <div class='theme_two_box_img'>
                      <div
                        style={{ background: '#c88698', height: '12em' }}
                      ></div>
                    </div>
                    <div class='theme_two_box_content'>
                      <p class='card-text placeholder-glow'>
                        <span class='placeholder col-7'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div class='row' data-cues='slideInUp'>
                {makkahHotels.map((item, index) => (
                  <div class='col-xl-3 col-lg-6 col-md-6'>
                    <a class='grid_item'>
                      <figure>
                        <div class='score'>
                          {showPrice ? (
                            <strong>
                              {CurrencyRates === undefined
                                ? baseCName
                                : CurrencyRates.selectedcurrency}{' '}
                              {renderPrice(
                                calculateMarkup(
                                  item.min_price,
                                  item.admin_markup,
                                  item.admin_markup_type,
                                  item.customer_markup,
                                  item.customer_markup_type
                                ),
                                item?.hotel_curreny
                              )}
                              <sub style={{ color: '#808080ad' }}>
                                <del>
                                  {renderPrice(
                                    calculateMarkup(
                                      item.min_price_Actual,
                                      item.admin_markup,
                                      item.admin_markup_type,
                                      item.customer_markup,
                                      item.customer_markup_type
                                    ),
                                    item?.hotel_curreny
                                  )}
                                </del>
                              </sub>
                            </strong>
                          ) : (
                            <strong>
                              {item?.hotel_curreny}{' '}
                              {calculateMarkup(
                                item.min_price,
                                item.admin_markup,
                                item.admin_markup_type,
                                item.customer_markup,
                                item.customer_markup_type
                              )}
                              <sub style={{ color: '#808080ad' }}>
                                <del>
                                  {calculateMarkup(
                                    item.min_price_Actual,
                                    item.admin_markup,
                                    item.admin_markup_type,
                                    item.customer_markup,
                                    item.customer_markup_type
                                  )}
                                </del>
                              </sub>
                            </strong>
                          )}
                        </div>
                        {makkahDetail[item.hotel_id] &&
                        makkahDetail[item.hotel_id].details_data &&
                        makkahDetail[item.hotel_id].details_data.image ? (
                          <img
                            className='home-hotel-img'
                            src={makkahDetail[item.hotel_id].details_data.image}
                            alt={item.hotel_name}
                          />
                        ) : (
                          <img
                            className='home-hotel-img'
                            src='https://haramaynhotels.com/public/uploads/package_imgs/1698138818.jpg'
                            style={{ height: '172px' }}
                            alt='img'
                          />
                        )}
                        <div class='info'>
                          {item.stars_rating === '' ? (
                            <div class='cat_star'>No Rating</div>
                          ) : (
                            <div class='cat_star'>
                              {Array(item.stars_rating)
                                .fill(0)
                                .map((_, index) => (
                                    <FontAwesomeIcon icon={faStar} />
                                ))}
                            </div>
                          )}

                          <Whisper
                            placement='top'
                            controlId='control-id-hover'
                            trigger='hover'
                            speaker={<Tooltip>{item.hotel_name}</Tooltip>}
                          >
                            <h3
                             onClick={() =>
                              RoomDetailPage(item.hotel_id, index, 'makkah')
                            }
                              style={{ cursor: 'pointer' }}
                              className='home-card-title '
                            >
                              {' '}
                              {item.hotel_name}
                            </h3>
                          </Whisper>
                        </div>
                      </figure>
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : (
          <div>
            <div class='main_title_3'>
              <span>
                <em></em>
              </span>
              <h2>Bestseller Listing in Makkah</h2>
              <p>Hotel highly rated for thoughtful design</p>
            </div>
            {isloadingMakkah ? (
              <div className='row'>
                <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                  <div class='theme_common_box_two img_hover'>
                    <div class='theme_two_box_img'>
                      <div
                        style={{ background: '#c88698', height: '12em' }}
                      ></div>
                    </div>
                    <div class='theme_two_box_content'>
                      <p class='card-text placeholder-glow'>
                        <span class='placeholder col-7'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                  <div class='theme_common_box_two img_hover'>
                    <div class='theme_two_box_img'>
                      <div
                        style={{ background: '#c88698', height: '12em' }}
                      ></div>
                    </div>
                    <div class='theme_two_box_content'>
                      <p class='card-text placeholder-glow'>
                        <span class='placeholder col-7'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                  <div class='theme_common_box_two img_hover'>
                    <div class='theme_two_box_img'>
                      <div
                        style={{ background: '#c88698', height: '12em' }}
                      ></div>
                    </div>
                    <div class='theme_two_box_content'>
                      <p class='card-text placeholder-glow'>
                        <span class='placeholder col-7'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                  <div class='theme_common_box_two img_hover'>
                    <div class='theme_two_box_img'>
                      <div
                        style={{ background: '#c88698', height: '12em' }}
                      ></div>
                    </div>
                    <div class='theme_two_box_content'>
                      <p class='card-text placeholder-glow'>
                        <span class='placeholder col-7'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div class='row' data-cues='slideInUp'>
                {makkahHotels.hotels_list.slice(0, 4).map((item, index) => (
                  <div class='col-xl-3 col-lg-6 col-md-6'>
                    <a class='grid_item'>
                      <figure>
                        <div class='score'>
                          {showPrice ? (
                            <strong>
                              {CurrencyRates === undefined
                                ? baseCName
                                : CurrencyRates.selectedcurrency}{' '}
                              {renderPrice(
                                calculateMarkup(
                                  item.min_price,
                                  item.admin_markup,
                                  item.admin_markup_type,
                                  item.customer_markup,
                                  item.customer_markup_type
                                ),
                                item?.hotel_curreny
                              )}
                            </strong>
                          ) : (
                            <strong>
                              {item?.hotel_curreny}{' '}
                              {calculateMarkup(
                                item.min_price,
                                item.admin_markup,
                                item.admin_markup_type,
                                item.customer_markup,
                                item.customer_markup_type
                              )}
                            </strong>
                          )}
                        </div>
                        {makkahDetail[item.hotel_id] &&
                        makkahDetail[item.hotel_id].details_data &&
                        makkahDetail[item.hotel_id].details_data.image ? (
                          <img
                            className='home-hotel-img'
                            src={makkahDetail[item.hotel_id].details_data.image}
                            alt={item.hotel_name}
                          />
                        ) : (
                          <img
                            className='home-hotel-img'
                            src='https://haramaynhotels.com/public/uploads/package_imgs/1698138818.jpg'
                            style={{ height: '172px' }}
                            alt='img'
                          />
                        )}
                        <div class='info'>
                          {item.stars_rating === '' ? (
                            <div class='cat_star'>No Rating</div>
                          ) : (
                            <div class='cat_star'>
                              {Array(item.stars_rating)
                                .fill(0)
                                .map((_, index) => (
                                    <FontAwesomeIcon icon={faStar} />
                                ))}
                            </div>
                          )}
                          <Whisper
                            placement='top'
                            controlId='control-id-hover'
                            trigger='hover'
                            speaker={<Tooltip>{item.hotel_name}</Tooltip>}
                          >
                            <h3
                             onClick={() =>
                              RoomDetailPage(item.hotel_id, index, 'makkah')
                            }
                              style={{ cursor: 'pointer' }}
                              className='home-card-title '
                            >
                              {' '}
                              {item.hotel_name}
                            </h3>
                          </Whisper>
                        </div>
                      </figure>
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </section>

      <section class='add_bottom_45'>
        {madinahPromotion ? (
          <div>
            <div class='main_title_3'>
              <span>
                <em></em>
              </span>
              <h2>Promotion Hotels in Madinah</h2>
              <p>Hotel highly rated for thoughtful design</p>
            </div>
            {isloadingMadinah ? (
              <div className='row'>
                <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                  <div class='theme_common_box_two img_hover'>
                    <div class='theme_two_box_img'>
                      <div
                        style={{ background: '#c88698', height: '12em' }}
                      ></div>
                    </div>
                    <div class='theme_two_box_content'>
                      <p class='card-text placeholder-glow'>
                        <span class='placeholder col-7'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                  <div class='theme_common_box_two img_hover'>
                    <div class='theme_two_box_img'>
                      <div
                        style={{ background: '#c88698', height: '12em' }}
                      ></div>
                    </div>
                    <div class='theme_two_box_content'>
                      <p class='card-text placeholder-glow'>
                        <span class='placeholder col-7'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                  <div class='theme_common_box_two img_hover'>
                    <div class='theme_two_box_img'>
                      <div
                        style={{ background: '#c88698', height: '12em' }}
                      ></div>
                    </div>
                    <div class='theme_two_box_content'>
                      <p class='card-text placeholder-glow'>
                        <span class='placeholder col-7'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                  <div class='theme_common_box_two img_hover'>
                    <div class='theme_two_box_img'>
                      <div
                        style={{ background: '#c88698', height: '12em' }}
                      ></div>
                    </div>
                    <div class='theme_two_box_content'>
                      <p class='card-text placeholder-glow'>
                        <span class='placeholder col-7'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div class='row' data-cues='slideInUp'>
                {MadinahHotels.map((item, index) => (
                  <div class='col-xl-3 col-lg-6 col-md-6'>
                    <a class='grid_item'>
                      <figure>
                        <div class='score'>
                          {showPrice ? (
                            <strong>
                              {CurrencyRates === undefined
                                ? baseCName
                                : CurrencyRates.selectedcurrency}{' '}
                              {renderPrice(
                                calculateMarkup(
                                  item.min_price,
                                  item.admin_markup,
                                  item.admin_markup_type,
                                  item.customer_markup,
                                  item.customer_markup_type
                                ),
                                item?.hotel_curreny
                              )}
                              <sub style={{ color: '#808080ad' }}>
                                <del>
                                  {renderPrice(
                                    calculateMarkup(
                                      item.min_price_Actual,
                                      item.admin_markup,
                                      item.admin_markup_type,
                                      item.customer_markup,
                                      item.customer_markup_type
                                    ),
                                    item?.hotel_curreny
                                  )}
                                </del>
                              </sub>
                            </strong>
                          ) : (
                            <strong>
                              {item?.hotel_curreny}{' '}
                              {calculateMarkup(
                                item.min_price,
                                item.admin_markup,
                                item.admin_markup_type,
                                item.customer_markup,
                                item.customer_markup_type
                              )}
                              <sub style={{ color: '#808080ad' }}>
                                <del>
                                  {calculateMarkup(
                                    item.min_price_Actual,
                                    item.admin_markup,
                                    item.admin_markup_type,
                                    item.customer_markup,
                                    item.customer_markup_type
                                  )}
                                </del>
                              </sub>
                            </strong>
                          )}
                        </div>
                        {madinahDetail[item.hotel_id] &&
                        madinahDetail[item.hotel_id].details_data &&
                        madinahDetail[item.hotel_id].details_data.image ? (
                          <img
                            className='home-hotel-img'
                            src={
                              madinahDetail[item.hotel_id].details_data.image
                            }
                            alt={item.hotel_name}
                          />
                        ) : (
                          <img
                            className='home-hotel-img'
                            src='https://haramaynhotels.com/public/uploads/package_imgs/1698138818.jpg'
                            style={{ height: '172px' }}
                            alt='img'
                          />
                        )}
                        <div class='info'>
                          {item.stars_rating === '' ? (
                            <div class='cat_star'>No Rating</div>
                          ) : (
                            <div class='cat_star'>
                              {Array(item.stars_rating)
                                .fill(0)
                                .map((_, index) => (
                                    <FontAwesomeIcon icon={faStar} />
                                ))}
                            </div>
                          )}

                          <Whisper
                            placement='top'
                            controlId='control-id-hover'
                            trigger='hover'
                            speaker={<Tooltip>{item.hotel_name}</Tooltip>}
                          >
                            <h3
                             onClick={() =>
                              RoomDetailPage(item.hotel_id, index, 'madinah')
                            }
                              style={{ cursor: 'pointer' }}
                              className='home-card-title '
                            >
                              {' '}
                              {item.hotel_name}
                            </h3>
                          </Whisper>
                        </div>
                      </figure>
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : (
          <div>
            <div class='main_title_3'>
              <span>
                <em></em>
              </span>
              <h2>Bestseller Listing in Madinah</h2>
              <p>Hotel highly rated for thoughtful design</p>
            </div>
            {isloadingMadinah ? (
              <div className='row'>
                <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                  <div class='theme_common_box_two img_hover'>
                    <div class='theme_two_box_img'>
                      <div
                        style={{ background: '#c88698', height: '12em' }}
                      ></div>
                    </div>
                    <div class='theme_two_box_content'>
                      <p class='card-text placeholder-glow'>
                        <span class='placeholder col-7'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                  <div class='theme_common_box_two img_hover'>
                    <div class='theme_two_box_img'>
                      <div
                        style={{ background: '#c88698', height: '12em' }}
                      ></div>
                    </div>
                    <div class='theme_two_box_content'>
                      <p class='card-text placeholder-glow'>
                        <span class='placeholder col-7'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                  <div class='theme_common_box_two img_hover'>
                    <div class='theme_two_box_img'>
                      <div
                        style={{ background: '#c88698', height: '12em' }}
                      ></div>
                    </div>
                    <div class='theme_two_box_content'>
                      <p class='card-text placeholder-glow'>
                        <span class='placeholder col-7'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                  <div class='theme_common_box_two img_hover'>
                    <div class='theme_two_box_img'>
                      <div
                        style={{ background: '#c88698', height: '12em' }}
                      ></div>
                    </div>
                    <div class='theme_two_box_content'>
                      <p class='card-text placeholder-glow'>
                        <span class='placeholder col-7'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-4'></span>
                        <span class='placeholder col-6'></span>
                        <span class='placeholder col-8'></span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div class='row' data-cues='slideInUp'>
                {MadinahHotels.hotels_list.slice(0, 4).map((item, index) => (
                  <div class='col-xl-3 col-lg-6 col-md-6'>
                    <a class='grid_item'>
                      <figure>
                        <div class='score'>
                          {showPrice ? (
                            <strong>
                              {CurrencyRates === undefined
                                ? baseCName
                                : CurrencyRates.selectedcurrency}{' '}
                              {renderPrice(
                                calculateMarkup(
                                  item.min_price,
                                  item.admin_markup,
                                  item.admin_markup_type,
                                  item.customer_markup,
                                  item.customer_markup_type
                                ),
                                item?.hotel_curreny
                              )}
                            </strong>
                          ) : (
                            <strong>
                              {item?.hotel_curreny}{' '}
                              {calculateMarkup(
                                item.min_price,
                                item.admin_markup,
                                item.admin_markup_type,
                                item.customer_markup,
                                item.customer_markup_type
                              )}
                            </strong>
                          )}
                        </div>
                        {madinahDetail[item.hotel_id] &&
                        madinahDetail[item.hotel_id].details_data &&
                        madinahDetail[item.hotel_id].details_data.image ? (
                          <img
                            className='home-hotel-img'
                            src={
                              madinahDetail[item.hotel_id].details_data.image
                            }
                            alt={item.hotel_name}
                          />
                        ) : (
                          <img
                            className='home-hotel-img'
                            src='https://haramaynhotels.com/public/uploads/package_imgs/1698138818.jpg'
                            style={{ height: '172px' }}
                            alt='img'
                          />
                        )}
                        <div class='info'>
                          {item.stars_rating === '' ? (
                            <div class='cat_star'>No Rating</div>
                          ) : (
                            <div class='cat_star'>
                              {Array(item.stars_rating)
                                .fill(0)
                                .map((_, index) => (
                                    <FontAwesomeIcon icon={faStar} />
                                ))}
                            </div>
                          )}

                          <Whisper
                            placement='top'
                            controlId='control-id-hover'
                            trigger='hover'
                            speaker={<Tooltip>{item.hotel_name}</Tooltip>}
                          >
                            <h3
                             onClick={() =>
                              RoomDetailPage(item.hotel_id, index, 'madinah')
                            }
                              style={{ cursor: 'pointer' }}
                              className='home-card-title '
                            >
                              {' '}
                              {item.hotel_name}
                            </h3>
                          </Whisper>
                        </div>
                      </figure>
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </section>
    </>
  )
}

export default IndexComponents
