import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarAlt,
  faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons'
import Layout from '../../Components/Layout/Layout'
import bgimage from '../../Images/New/banner-search.jpg'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import Carousel from 'react-bootstrap/Carousel'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import Axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Loading from '../../Components/Loading/Loader'
import { ToastContainer, toast } from 'react-toastify'
import {
  ApiEndPoint,
  ActivityToken,
  CustomerDomainName
} from '../../Components/GlobalData/GlobalData'
import ImageGallery from 'react-image-gallery'
import ReactOwlCarousel from 'react-owl-carousel'
import ActivitySearch from '../../Components/SearchBar/ActivitySearch'
function ActivityDetail () {
  const navigate = useNavigate()
  const [activityDetail, setActivityDetail] = useState(null)
  const [whatExpect, setWhatExpect] = useState(null)
  const [faqs, setFaqs] = useState(null)
  const [availibalityDays, setAvailibalityDays] = useState(null)
  const [additionalServices, setAdditionalServices] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [activeIndex, setActiveIndex] = useState(null)
  const [activeIndex1, setActiveIndex1] = useState(null)
  const [selectedDate, setSelectedDate] = useState('')
  const [selectAdults, setSelectAdults] = useState('')
  const [selectChilds, setSelectChilds] = useState('')
  const [imageArray, setImageArray] = useState([])
  const [selectedServices, setSelectedServices] = useState([])
  const [isModal, setIsModal] = useState(false)
  const [isAvailableModal, setisAvailableModal] = useState(false)

  const DomainURL = CustomerDomainName()
  useEffect(() => {
    GetDetail()
  }, [])
  const options = {
    margin: 10,
    nav: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 3
      }
    }
  }
  const GetDetail = async () => {
    var currentURL = window.location.href
    // Extract the last part of the URL
    var urlParts = currentURL.split('/')
    var lastPart = urlParts[urlParts.length - 1]

    var endpoint = ApiEndPoint()
    var token = ActivityToken()
    var data = {
      token: token,
      id: lastPart
    }
    setIsLoading(true)
    try {
      const response = await Axios.post(
        endpoint + '/api/activity-details-react',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      setIsLoading(false)
      setActivityDetail(response.data.data)
      setWhatExpect(JSON.parse(response.data.data.what_expect))
      setFaqs(JSON.parse(response.data.data.faqs_arr))
      setAdditionalServices(
        JSON.parse(response.data.data.addtional_service_arr)
      )
      setImageArray(JSON.parse(response.data.data.gallery_images))
      if (response !== undefined) {
        const start = new Date(response.data.data.start_date) // Example start date
        const end = new Date(response.data.data.end_date) // Example end date
        var resultDates = getDatesBetween(
          start,
          end,
          JSON.parse(response.data.data.Availibilty)
        )
        setAvailibalityDays(resultDates)
      }
      //   console.log(resultDates);
      // dispatch(ActivitiesListing(response.data));
      // navigation('/activities');
    } catch (error) {
      setIsLoading(false)
      console.error('Error:', error)
    }
  }
  const toggleModal = () => {
    setIsModal(!isModal)
  }
  const toggleAVailabilityModal = () => {
    setisAvailableModal(!isAvailableModal)
  }
  function getDatesBetween (start, end, daysArray) {
    const enabledDays = []
    const startDateNum = start.getDay()
    let currentDate = new Date(start)

    while (currentDate <= end) {
      const dayNum = currentDate.getDay()

      // Handle Sunday correctly:
      const dayData = dayNum === 0 ? daysArray['7'] : daysArray[dayNum]

      if (dayData && dayData.enable) {
        enabledDays.push({
          date: new Date(currentDate),
          from: dayData.from,
          to: dayData.to
        })
      }

      currentDate.setDate(currentDate.getDate() + 1)
    }
    return enabledDays
  }

  // Custom function to filter dates
  const filterDate = date => {
    // Return true if the date is in the enabledDates array, false otherwise
    return availibalityDays.some(
      enabledDate =>
        date.getDate() === enabledDate.date.getDate() &&
        date.getMonth() === enabledDate.date.getMonth() &&
        date.getFullYear() === enabledDate.date.getFullYear()
    )
  }

  const handleAdult = event => {
    setSelectAdults(event.target.value)
  }
  const handleChild = event => {
    setSelectChilds(event.target.value)
  }

  const SaveData = () => {
    debugger
    if (selectedDate === '') {
      toast.info('Please select Activity date.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    }
    if (selectAdults === '' || selectAdults === '0') {
      toast.info('Please select Adult for Activity.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    }
    var newdata = {
      date: selectedDate,
      adults: selectAdults,
      childs: selectChilds,
      adultPrice: activityDetail?.sale_price,
      childrenPrice: activityDetail?.child_sale_price,
      currency: activityDetail?.currency_symbol
    }
    sessionStorage.setItem(
      'AdditionalServices',
      JSON.stringify(selectedServices)
    )
    sessionStorage.setItem('ActivityData', JSON.stringify(newdata))
    sessionStorage.setItem('ActivityDetail', JSON.stringify(activityDetail))

    navigate('/activity_checkout')
  }

  const handleCheckboxChange = (checkboxName, servicetype, serviceprice) => {
    const updatedCheckboxes = [...selectedServices]

    // Check if the checkbox is already in the array
    const existingCheckboxIndex = updatedCheckboxes.findIndex(
      item => item.name === checkboxName
    )

    if (existingCheckboxIndex !== -1) {
      // If it is, remove it
      updatedCheckboxes.splice(existingCheckboxIndex, 1)
    } else {
      // If it is not, add it
      updatedCheckboxes.push({
        name: checkboxName,
        type: servicetype,
        price: serviceprice
      })
    }

    setSelectedServices(updatedCheckboxes)
  }

  const handleAdditionalServicePerson = (event, serviceName) => {
    const newAdultValue = parseInt(event.target.value, 10) // Assuming you get the adult value from the event
    addAdultToService(serviceName, newAdultValue)
  }

  const addAdultToService = (serviceName, newAdultValue) => {
    setSelectedServices(prevServices => {
      return prevServices?.map(service => ({
        ...service,
        ...(service.name === serviceName ? { adult: newAdultValue } : {})
      }))
    })
  }

  const show = index => {
    const buttons = document.getElementsByClassName('accordion')
    const panel = buttons[index].nextElementSibling
    buttons[index].classList.toggle('active-2')

    if (panel.style.maxHeight) {
      panel.style.maxHeight = null
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px'
    }

    // Remove "active" class from other buttons
    for (let i = 0; i < buttons.length; i++) {
      if (i !== index) {
        buttons[i].classList.remove('active-2')
        buttons[i].nextElementSibling.style.maxHeight = null
      }
    }
  }
  const show1 = index => {
    const buttons = document.getElementsByClassName('accordion2')
    const panel = buttons[index].nextElementSibling
    buttons[index].classList.toggle('active-2')

    if (panel.style.maxHeight) {
      panel.style.maxHeight = null
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px'
    }

    for (let i = 0; i < buttons.length; i++) {
      if (i !== index) {
        buttons[i].classList.remove('active-2')
        buttons[i].nextElementSibling.style.maxHeight = null
      }
    }
  }
  return (
    <>
      <ToastContainer />
      {isLoading && <Loading />}
      <Layout>
        <main>
          <section
            class='hero_in '
            style={{ backgroundImage: `url(${bgimage})` }}
          >
            <div class='wrapper'>
              <div class='container'>
                <h1 class='fadeInUp'>
                  <span></span>
                  {activityDetail?.title}
                </h1>
              </div>
              <div className='tour-video-modal'>
                {activityDetail?.video_link !== null && (
                  <div className='btn-group tour_video_btn'>
                    <a
                      href='#'
                      className='btn btn-transparent has-icon bravo-video-popup'
                      data-bs-toggle='modal'
                      data-bs-target='#myModal'
                      // data-src='https://www.youtube.com/embed/UfEiKK-iX70'
                    >
                      <i className='input-icon field-icon fa'>
                        <svg
                          height='18px'
                          width='18px'
                          version='1.1'
                          id='Layer_1'
                          x='0px'
                          y='0px'
                          viewBox='0 0 24 24'
                          style={{ enableBackground: 'new 0 0 24 24' }}
                        >
                          <g fill='#FFFFFF'>
                            <path d='M2.25,24C1.009,24,0,22.991,0,21.75V2.25C0,1.009,1.009,0,2.25,0h19.5C22.991,0,24,1.009,24,2.25v19.5c0,1.241-1.009,2.25-2.25,2.25H2.25z M2.25,1.5C1.836,1.5,1.5,1.836,1.5,2.25v19.5c0,0.414,0.336,0.75,0.75,0.75h19.5c0.414,0,0.75-0.336,0.75-0.75V2.25c0-0.414-0.336-0.75-0.75-0.75H2.25z' />
                            <path d='M9.857,16.5c-0.173,0-0.345-0.028-0.511-0.084C8.94,16.281,8.61,15.994,8.419,15.61c-0.11-0.221-0.169-0.469-0.169-0.716V9.106C8.25,8.22,8.97,7.5,9.856,7.5c0.247,0,0.495,0.058,0.716,0.169l5.79,2.896c0.792,0.395,1.114,1.361,0.719,2.153c-0.154,0.309-0.41,0.565-0.719,0.719l-5.788,2.895C10.348,16.443,10.107,16.5,9.857,16.5z M9.856,9C9.798,9,9.75,9.047,9.75,9.106v5.788c0,0.016,0.004,0.033,0.011,0.047c0.013,0.027,0.034,0.044,0.061,0.054C9.834,14.998,9.845,15,9.856,15c0.016,0,0.032-0.004,0.047-0.011l5.788-2.895c0.02-0.01,0.038-0.027,0.047-0.047c0.026-0.052,0.005-0.115-0.047-0.141l-5.79-2.895C9.889,9.004,9.872,9,9.856,9z' />
                          </g>
                        </svg>
                      </i>
                      Tour Video
                    </a>
                  </div>
                )}
                <div
                  class='modal fade'
                  id='myModal'
                  tabindex='-1'
                  role='dialog'
                  aria-labelledby='exampleModalLabel'
                  aria-hidden='true'
                >
                  <div class='modal-dialog modal-lg' role='document'>
                    <div class='modal-content'>
                      <div class='modal-body'>
                        <div class='embed-responsive embed-responsive-16by9'>
                          <iframe
                            class='embed-responsive-item bravo_embed_video'
                            src={activityDetail?.video_link}
                            allowscriptaccess='always'
                            allow='autoplay'
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class='bg_color_1'>
            <div class='container margin_60_35'>
              <div class='row'>
                <div class='col-lg-8'>
                  <section id='description'>
                    <h2>Description</h2>
                    <p>{activityDetail?.activity_content}</p>
                    <div class='row'>
                      <div class='col-lg-6'>
                        <h6>Included</h6>
                        <p>{activityDetail?.whats_included}</p>
                      </div>
                      <div class='col-lg-6'>
                        <h6>Excluded</h6>
                        <p> {activityDetail?.whats_excluded}</p>
                      </div>
                    </div>
                    <div class='g-tour-feature'>
                      <div class='row'>
                        <div class='col-xs-6 col-lg-3 col-6 col-md-6'>
                          <div class='item'>
                            <div class='info'>
                              <h6 class='name'>Duration</h6>
                              <p class='value'>
                                {activityDetail?.duration} Hour
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class='col-xs-6 col-lg-3 col-6  col-md-6'>
                          <div class='item'>
                            <div class='info'>
                              <h6 class='name'>Start Date</h6>
                              <p class='value'>
                                {moment(activityDetail?.start_date).format(
                                  'll'
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class='col-xs-6 col-lg-3 col-6  col-md-6'>
                          <div class='item'>
                            <div class='info'>
                              <h6 class='name'>Group Size</h6>
                              <p class='value'>
                                {activityDetail?.max_people} persons
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class='col-xs-6 col-lg-3 col-6  col-md-6'>
                          <div class='item'>
                            <div class='info'>
                              <h6 class='name'>Location</h6>
                              <p class='value'> {activityDetail?.location}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: '100%' }}>
                      <ImageGallery
                        showFullscreenButton={true}
                        items={imageArray.map(image => ({
                          original:
                            DomainURL + '/public/images/activites/' + image, // large image
                          thumbnail:
                            DomainURL + '/public/images/activites/' + image // thumbnail
                        }))}
                      />
                    </div>
                    {whatExpect !== null && whatExpect?.length !== 0 && (
                      <div>
                        <hr />
                        <h3>
                          Program{' '}
                          <small>({activityDetail?.duration} Hour)</small>
                        </h3>
                        <ul class='cbp_tmtimeline'>
                          {whatExpect?.map((item, index) => (
                            <li key={index}>
                              <div class='cbp_tmicon'>{index + 1}</div>
                              <div class='cbp_tmlabel'>
                                <h4>{item.title}</h4>
                                <p>{item.expect_content}</p>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {faqs !== null && faqs?.length !== 0 && (
                      <div>
                        <hr />
                        <h3> FAQs</h3>
                        <div className='accordion' id='accordionExample'>
                          {faqs.map((item, index) => (
                            <div className='accordion-item' key={index}>
                              <h2
                                className='accordion-header'
                                id={`heading${index}`}
                              >
                                <button
                                  className={`p-2 accordion-button ${
                                    index === 0 ? '' : 'collapsed'
                                  }`}
                                  type='button'
                                  data-bs-toggle='collapse'
                                  data-bs-target={`#collapse${index}`}
                                  aria-expanded={index === 0 ? 'true' : 'false'}
                                  aria-controls={`collapse${index}`}
                                >
                                  {item.title}
                                </button>
                              </h2>
                              <div
                                id={`collapse${index}`}
                                className={`accordion-collapse collapse ${
                                  index === 0 ? 'show' : ''
                                }`}
                                aria-labelledby={`heading${index}`}
                                data-bs-parent='#accordionExample'
                              >
                                <div className='accordion-body'>
                                  <strong>{item.content}</strong>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </section>
                </div>
                <div class='col-lg-4' id='sidebar'>
                  <section id='description'>
                    <h2>Availability</h2>
                    <div class='list-attributes'>
                      <table class='table'>
                        <thead>
                          <tr>
                            <th>Day of Week</th>
                            <th>Open</th>
                            <th>Close</th>
                          </tr>
                        </thead>
                        <tbody>
                          {availibalityDays?.slice(0, 12).map((item, index) => (
                            <tr key={index}>
                              <td>
                                {moment(item.date).format('dddd')} -{' '}
                                {moment(item.date).format('DD-MM-YYYY')}
                              </td>
                              <td>{item.from}</td>
                              <td>{item.to}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {availibalityDays !== null && (
                        <div>
                          {availibalityDays?.length > 12 && (
                            <p
                              onClick={toggleAVailabilityModal}
                              style={{ cursor: 'pointer' }}
                              className='text-center text-primary'
                            >
                              View All
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  </section>
                  <div class='box_detail booking'>
                    <div class='price'>
                      <div class='d-flex justify-content-between mt-2'>
                        <div>
                          <h6 class='card-title'>Start Date:</h6>
                        </div>
                        <div>
                          {' '}
                          {moment(activityDetail?.start_date).format(
                            'DD-MM-YYYY'
                          )}
                        </div>
                      </div>
                      <div class='d-flex justify-content-between mt-2'>
                        <div>
                          <h6 class='card-title'>End Date:</h6>
                        </div>
                        <div>
                          {' '}
                          {moment(activityDetail?.end_date).format(
                            'DD-MM-YYYY'
                          )}
                        </div>
                      </div>
                      <div class='d-flex justify-content-between mt-2'>
                        <div>
                          <h6 class='card-title'>Adult Price:</h6>
                        </div>
                        <h6>
                          {' '}
                          {activityDetail?.currency_symbol}{' '}
                          {activityDetail?.sale_price}
                        </h6>
                      </div>
                      {activityDetail?.child_sale_price !== null && (
                        <div class='d-flex justify-content-between mt-2'>
                          <div>
                            <h6 class='card-title'>Child Price:</h6>
                          </div>
                          <h6>
                            {' '}
                            {activityDetail?.currency_symbol}{' '}
                            {activityDetail?.child_sale_price}
                          </h6>
                        </div>
                      )}
                    </div>
                    {additionalServices !== null &&
                              additionalServices?.length !== 0 && (
                                <div className='mt-2'>
                                  <h6>Additional Services</h6>
                                </div>
                              )}
                            {additionalServices?.map((item, index) => (
                              <div key={index}>
                                <div>
                                  <label class='d-flex align-items-center'>
                                    <input
                                      id='0'
                                      autocomplete='off'
                                      checked={
                                        selectedServices.length > 0 &&
                                        selectedServices.some(
                                          data =>
                                            data.name === item.service_name
                                        )
                                      }
                                      onChange={() =>
                                        handleCheckboxChange(
                                          item.service_name,
                                          item.service_type,
                                          item.service_price
                                        )
                                      }
                                      class='room-check me-2'
                                      type='checkbox'
                                    />
                                    {item.service_name}
                                  </label>
                                </div>

                                <div class='d-flex justify-content-between mt-2'>
                                  <div>
                                    <h6 class='card-title'>Price:</h6>
                                  </div>
                                  <div>
                                    {' '}
                                    {activityDetail?.currency_symbol}{' '}
                                    {item.service_price}
                                  </div>
                                </div>
                                <div class='d-flex justify-content-between mt-2'>
                                  <div>
                                    <h6 class='card-title'>Service Type:</h6>
                                  </div>
                                  <div> {item.service_type}</div>
                                </div>
                                {item.service_type === 'Per Person' && (
                                  <div class='form-group mt-2'>
                                    <h6 class='card-title'>Select Person:</h6>
                                    <select
                                      value={
                                        selectedServices.length > 0
                                          ? selectedServices.find(
                                              data =>
                                                data.name === item.service_name
                                            )?.adult || '0'
                                          : '0'
                                      }
                                      onChange={event =>
                                        handleAdditionalServicePerson(
                                          event,
                                          item.service_name
                                        )
                                      }
                                      disabled={
                                        !(
                                          selectedServices.length > 0 &&
                                          selectedServices.some(
                                            data =>
                                              data.name === item.service_name
                                          )
                                        )
                                      }
                                      className='form-control form-select select-styling'
                                      id='exampleFormControlSelect1'
                                    >
                                      <option selected value='0'>
                                        0
                                      </option>
                                      {Array.from(
                                        { length: selectAdults },
                                        (_, index) => index + 1
                                      )?.map(optionValue => (
                                        <option
                                          key={optionValue}
                                          value={optionValue}
                                        >
                                          {optionValue}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                )}
                              </div>
                            ))}
                    <div className='date_input_width my-1'>
                      <DatePicker
                        selected={selectedDate}
                        onChange={date => setSelectedDate(date)}
                        placeholderText='Select Date'
                        filterDate={filterDate}
                        minDate={new Date()}
                        dateFormat='dd/MM/yyyy' // Customize date format as needed
                        className='form-control w-100 text-start '
                      />
                    </div>
                    <div class='date-wrapper clearfix pb-0 px-0 my-1' v-else>
                      <div class='check-in-wrapper'>
                        <label>Adult</label>
                        <div class='render check-in-render'>
                          <select
                            onChange={handleAdult}
                            value={selectAdults}
                            className='form-control form-select'
                            id='exampleFormControlSelect1'
                          >
                            <option selected value='0'>
                              0
                            </option>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                            <option value='5'>5</option>
                            <option value='6'>6</option>
                            <option value='7'>7</option>
                            <option value='8'>8</option>
                            <option value='9'>9</option>
                            <option value='10'>10</option>
                          </select>
                        </div>
                      </div>
                      <i class='fa fa-angle-down arrow'></i>
                    </div>
                    <div class='date-wrapper clearfix pb-0 my-1 px-0' v-else>
                      <div class='check-in-wrapper'>
                        <label>Child</label>
                        <div class='render check-in-render'>
                          <select
                            value={selectChilds}
                            onChange={handleChild}
                            disabled={activityDetail?.child_sale_price == null}
                            className='form-control form-select select-styling'
                            id='exampleFormControlSelect1'
                          >
                            <option selected value='0'>
                              0
                            </option>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                            <option value='5'>5</option>
                            <option value='6'>6</option>
                            <option value='7'>7</option>
                            <option value='8'>8</option>
                            <option value='9'>9</option>
                            <option value='10'>10</option>
                          </select>
                        </div>
                      </div>
                      <i class='fa fa-angle-down arrow'></i>
                    </div>

                    <a onClick={SaveData} class='btn_1 full-width purchase'>
                      Book Now
                    </a>

                    <div
                      onClick={toggleModal}
                      class='text-center text-primary '
                      style={{ cursor: 'pointer' }}
                    >
                      <small>Cancellation Policy ?</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Modal isOpen={isModal} className='t-0' toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Cancellation Policy</ModalHeader>
          <ModalBody>
            <div className='form-group'>
              <p>{activityDetail?.cancellation_policy}</p>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={isAvailableModal}
          className='custom-modal t-0'
          toggle={toggleAVailabilityModal}
        >
          <ModalHeader toggle={toggleAVailabilityModal}>
            Availability
          </ModalHeader>
          <ModalBody>
            <table class='table'>
              <thead>
                <tr>
                  <th>Day of Week</th>
                  <th>Open</th>
                  <th>Close</th>
                </tr>
              </thead>
              <tbody>
                {availibalityDays?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {moment(item.date).format('dddd')} -{' '}
                      {moment(item.date).format('DD-MM-YYYY')}
                    </td>
                    <td>{item.from}</td>
                    <td>{item.to}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </ModalBody>
        </Modal>
      </Layout>
    </>
  )
}

export default ActivityDetail
