import React, { useState, useEffect } from 'react'
import logo from '../../Images/logo.png'
import Axios from 'axios'
import ReactDOM from 'react-dom'
import { json, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { CurrencyConverter } from '../GlobalData/GlobalData'
import { Hotelapitoken, ApiEndPoint } from '../GlobalData/GlobalData'
import { CurrencyRates, AllCurrencyRates } from '../../Redux/Actions/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import { Drawer } from 'rsuite'
import { useTranslation } from 'react-i18next'
function Navbar () {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  var token = Hotelapitoken()
  var endpoint = ApiEndPoint()
  const [countryList, setCountryList] = useState([])
  const [dropdownCat, setDropdownCat] = useState([])
  const [open, setOpen] = useState(false)
  const [selectedCurrency, setSelectedCurrency] = useState('GBP')
  const [menuVisible, setMenuVisible] = useState(false)
  var flagsList={
    GBP:'https://flagcdn.com/w320/gb.png',
    EUR:'https://flagcdn.com/w320/de.png',
    USD:'https://flagcdn.com/w320/us.png',
    SAR:'https://flagcdn.com/w320/sa.png',
    PKR:'https://flagcdn.com/w320/pk.png',
  };
  const toggleMenu = () => {
    setMenuVisible(!menuVisible)
  }
  const handleOpen = () => {
    setOpen(true)
  }
  // This function put query that helps to
  // change the language

  useEffect(() => {
    GetCatagories()
    fetchData()
    AllCurrency()
    const storedCurrency = localStorage.getItem('selectedCurrency')
    if (storedCurrency) {
      setSelectedCurrency(storedCurrency)
      CurrencyCalucaltion(storedCurrency)
    } else {
      dispatch(CurrencyRates(undefined))
    }
  }, [])
  async function fetchData () {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      // 'Authorization': 'Bearer YourAccessToken', // Replace with your authorization token
      'Content-Type': 'application/json' // Specify content type if needed
    }
    try {
      const response = await Axios.get(
        endpoint + '/api/countries/fetch',
        headers
      )
      setCountryList(response.data.countries)
    } catch (error) {
      // Handle any errors
      console.error('Error:', error)
    }
  }
  const handleCurrencyDivClick = currency => {
    setSelectedCurrency(currency)
    CurrencyCalucaltion(currency)
    localStorage.setItem('selectedCurrency', currency)

    // Perform other calculations or actions here
  }

  const CurrencyCalucaltion = c => {
    var token = CurrencyConverter()
    const config = {
      method: 'get',
      url: 'https://v6.exchangerate-api.com/v6/' + token + '/latest/' + c, // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {}
    }

    Axios.request(config)
      .then(response => {
        response.data.selectedcurrency = c
        dispatch(CurrencyRates(response.data))
      })
      .catch(error => {
        if (localStorage.getItem('selectedCurrency')) {
          localStorage.removeItem('selectedCurrency')
        }
        dispatch(CurrencyRates(undefined))
        // Handle errors here
        console.error(error)
      })
  }

  const AllCurrency = () => {
    var token = CurrencyConverter()
    const config = {
      method: 'get',
      url: 'https://v6.exchangerate-api.com/v6/' + token + '/latest/GBP', // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {}
    }

    Axios.request(config)
      .then(response => {
        dispatch(AllCurrencyRates(response.data))
      })
      .catch(error => {
        dispatch(AllCurrencyRates(undefined))
        // Handle errors here
        console.error(error)
      })
  }

  const GetCatagories = async () => {
    var data = {
      token: token
    }
    try {
      const response = await Axios.post(
        endpoint + '/api/get_all_catigories_list_apis_new',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      sessionStorage.setItem(
        'Catogories',
        JSON.stringify(response.data.categories)
      )
      setDropdownCat(response.data.categories)
    } catch (error) {
      console.error('Error:', error)
    }
  };
  return (
    <>
      <Drawer placement='left' open={open} onClose={() => setOpen(false)}>
        <Drawer.Header>
          <Drawer.Title>Menu</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <div class='mobile-nav'>
            <ul class='mobile-listview'>
              <li>
                <NavLink to='/'>Home</NavLink>
              </li>
              <li>
                <NavLink to='/contact-us'>Contact us</NavLink>
              </li>
              <li>
                <NavLink to='/about-us'>About us</NavLink>
              </li>
              <li className='dropdown'>
                <a
                  className='is_login dropdown-toggle'
                  id='currencyDropdown'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  {selectedCurrency} <i className='fa fa-angle-down'></i>
                </a>
                <ul
                  className='dropdown-menu text-left width-auto'
                  aria-labelledby='currencyDropdown'
                >
                  <li>
                    <a
                      onClick={() => handleCurrencyDivClick('EUR')}
                      className='is_login'
                    >
                      EUR
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => handleCurrencyDivClick('GBP')}
                      className='is_login'
                    >
                      GBP
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => handleCurrencyDivClick('USD')}
                      className='is_login'
                    >
                      USD
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => handleCurrencyDivClick('SAR')}
                      className='is_login'
                    >
                      SAR
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => handleCurrencyDivClick('PKR')}
                      className='is_login'
                    >
                      PKR
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </Drawer.Body>
      </Drawer>
      <header class='header mm-slideout sticky'>
        <div id='logo'>
          <NavLink to='/'>
            <img
              src={logo}
              width='100'
              height='65'
              alt=''
              class='logo_normal'
            />
            <img
              src={logo}
              width='100'
              height='65'
              alt=''
              class='logo_sticky'
            />
          </NavLink>
        </div>
        <a class='btn_mobile'>
          <div class='hamburger hamburger--spin' id='hamburger'>
            <div class='hamburger-box'>
              <div class='hamburger-inner'>
                <FontAwesomeIcon
                  onClick={() => setOpen(true)}
                  className='h5'
                  icon={faBars}
                />
              </div>
            </div>
          </div>
        </a>
        <nav
          id='menu'
          class='main-menu mm-menu mm-offcanvas mm-hasnavbar-bottom-1 mm-pagedim-black mm-opened'
        >
          <ul>
            <li>
              <span>
                <NavLink to='/'>Home</NavLink>
              </span>
            </li>
            <li>
              <span>
                <NavLink to='/about-us'>About Us</NavLink>
              </span>
            </li>
            <li>
              <span>
                <NavLink to='/contact-us'>Contact Us</NavLink>
              </span>
            </li>
            <li className='dropdown'>
              <a
                className='is_login dropdown-toggle'
                id='currencyDropdown'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
               <img src={flagsList[selectedCurrency]} width='23' /> {selectedCurrency} <i className='fa fa-angle-down'></i>
              </a>
              <ul
                className='dropdown-menu text-left width-auto'
                aria-labelledby='currencyDropdown'
              >
                <li>
                  <a
                    onClick={() => handleCurrencyDivClick('EUR')}
                    className='is_login'
                  >
                    <img src='https://flagcdn.com/w320/de.png' width='23' /> EUR
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => handleCurrencyDivClick('GBP')}
                    className='is_login'
                  >
                    <img src='https://flagcdn.com/w320/gb.png' width='23' /> GBP
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => handleCurrencyDivClick('USD')}
                    className='is_login'
                  >
                    <img src='https://flagcdn.com/w320/us.png' width='23' /> USD
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => handleCurrencyDivClick('SAR')}
                    className='is_login'
                  >
                    <img src='https://flagcdn.com/w320/sa.png' width='23' /> SAR
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => handleCurrencyDivClick('PKR')}
                    className='is_login'
                  >
                    <img src='https://flagcdn.com/w320/pk.png' width='23' /> PKR
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>
    </>
  )
}

export default Navbar
