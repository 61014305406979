import React, { useState } from 'react'
import bgimage from '../../Images/bg1.jpg'
import Modal from 'react-bootstrap/Modal'
import MCDC1050HJ from '../../Images/Package images/MCDC1050HJ.jpg'
import MCDC1051HJ from '../../Images/Package images/MCDC1051HJ.jpg'
import MCDC1052HJ from '../../Images/Package images/MCDC1052HJ.jpg'
import MCDC1053HJ from '../../Images/Package images/MCDC1053HJ.jpg'
import MCDC1054HJ from '../../Images/Package images/MCDC1054HJ.jpg'
import MCDC1055HJ from '../../Images/Package images/MCDC1055HJ.jpg'
import MCDC1056HJ from '../../Images/Package images/MCDC1056HJ.jpg'
// import MCDC1057HJ from '../../Images/Package images/MCDC1057HJ.jpg';
import MCDC1058HJ from '../../Images/Package images/MCDC1058HJ.jpg'
import MCDC1059HJ from '../../Images/Package images/MCDC1059HJ.jpg'
import MCDC1060HJ from '../../Images/Package images/MCDC1060HJ.jpg'
import MCDC1061HJ from '../../Images/Package images/MCDC1061HJ.jpg'
import MCDC1062HJ from '../../Images/Package images/MCDC1062HJ.jpg'
import MCDC1063HJ from '../../Images/Package images/MCDC1063HJ.jpg'
import MCDC1065HJ from '../../Images/Package images/MCDC1065HJ.jpg'
import MCDC1070HJ from '../../Images/Package images/MCDC1070HJ.jpg'
import MCDC1075HJ from '../../Images/Package images/MCDC1075HJ.jpg'
import MCDC1080HJ from '../../Images/Package images/MCDC1080HJ.jpg'
import MCDC1090HJ from '../../Images/Package images/MCDC1090HJ.jpg'
import MCDC1091HJ from '../../Images/Package images/MCDC1091HJ.jpg'
import Package21 from '../../Images/Package images/Package21.jpeg'
import Layout from '../../Components/Layout/Layout'
function Home () {
  const [show, setShow] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [imageSrc, setImageSrc] = useState('')

  const openFullscreen = src => {
    setImageSrc(src)
    setIsFullScreen(true)
  }

  const closeFullscreen = () => {
    setIsFullScreen(false)
  }

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className='text-center'>
          <strong>
            Al Hijaz Tours Ltd is the marketing partner of MCDC, Hajj Nusuk 2024
            for Nusuk approved Hajj companies.
          </strong>
          <br />
          <a target='_blank' href='https://hajj.nusuk.sa/registration/signup'>
            <button class='btn btn-success mt-3'>Click to login Nusuk</button>
          </a>
        </Modal.Body>
        <Modal.Footer>
          <button
            variant='secondary'
            className='btn btn-secondary'
            onClick={handleClose}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <Layout>
        <div class='fluid-container'>
          <div class='image-container'>
            <img
              src={bgimage}
              alt='Background Image'
              style={{ height: 'auto' }}
            />
            <div class='overlay-text'>Plan your Hajj and Ziyarah your way</div>
          </div>
        </div>

        <div className='gallery'>
          {isFullScreen && (
            <div className='fullscreen' onClick={closeFullscreen}>
              <img
                src={imageSrc}
                alt='Full Screen'
                className='fullscreen-image'
              />
            </div>
          )}
        </div>

        <div className='m-3 pb-3'>
          <div className='card-wrap mt-2 mb-2'>
            <div className='row p-3 '>
              <div className='col-lg-3 card-price-section '>
                <div className='card-img'>
                  <div className='text-center hajj-price'>
                    <h5>Price : SAR 35697.84</h5>
                    <p className='fw-bold'>Excluding Flights</p>
                  </div>
                  <img src={bgimage} />
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='date-flex'>
                  <h4>Luxury Non-Shifting</h4>
                  <h6
                    onClick={() => openFullscreen(MCDC1050HJ)}
                    class='text-center m-2 p-view-detail'
                  >
                    View Detail
                  </h6>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Package Code</h6>
                  </div>
                  <div>
                    <h6>MCDC1050HJ</h6>
                  </div>
                </div>
                <div className='date-flex'>
                  <div className='mt-2'>
                    <h6>Start Date : 07-JUN-2024</h6>
                  </div>
                  <div className='mt-2'>
                    <h6>End Date : 23-JUN-2024</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Flight</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Gulf Air</h6>
                  </div>
                </div>
                <h6 className='text-center header__center'>Stay</h6>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Madinah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Aqeeq Hotel</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Makkah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Makkah Towers</h6>
                  </div>
                </div>
                <div className='text-center'>
                  <button onClick={handleShow} className='Book-button m-2 '>
                    Book Now
                  </button>
                </div>
              </div>
              <div className='col-lg-3 flex-class'>
                <div className='text-center'>
                  <div className='text-center clients card__border'>
                    <center>
                      <div class='rounded-circle guide_img guide-23'></div>
                    </center>
                  </div>
                  <h5>Abdul Hamid Shakoor</h5>
                </div>
                <div className='hajj-price1 m-2'>
                    <p className='fw-bold'>Package Guide</p>
                  </div>
              </div>
            </div>
          </div>

          <div className='card-wrap mt-2 mb-2'>
            <div className='row p-2 '>
              <div className='col-lg-3 card-price-section '>
                <div className='card-img'>
                  <div className='text-center hajj-price'>
                    <h5>Price : SAR 29893.73</h5>
                    <p className='fw-bold'>Excluding Flights</p>
                  </div>
                  <h5 className='text-center hajj-price'></h5>
                  <img src={bgimage} />
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='date-flex'>
                  <h4 className='mt-2'>Standard Shifting</h4>
                  <h6
                    onClick={() => openFullscreen(MCDC1051HJ)}
                    class='text-center m-2 p-view-detail'
                  >
                    View Detail
                  </h6>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Package Code</h6>
                  </div>
                  <div>
                    <h6>MCDC1051HJ</h6>
                  </div>
                </div>
                <div className='date-flex '>
                  <div className='mt-2'>
                    <h6>Start Date : 02-JUN-2024</h6>
                  </div>
                  <div className='mt-2'>
                    <h6>End Date : 22-JUN-2024</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Flight</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Qatar Airways</h6>
                  </div>
                </div>
                <h6 className='text-center header__center'>Stay</h6>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Madinah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Jayden Hotel</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Makkah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Makkah Towers</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Makkah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Al Masa Al Rawdah</h6>
                  </div>
                </div>
                <div className='text-center'>
                  <button onClick={handleShow} className='Book-button m-2 '>
                    Book Now
                  </button>
                </div>
              </div>
              <div className='col-lg-3  flex-class'>
                <div className='text-center'>
                  <div className='text-center clients card__border1'>
                    <center>
                      <div class='rounded-circle1 guide_img guide-16'></div>
                    </center>
                  </div>
                  <h6>Umar Rashid</h6>
                  <div className='text-center clients card__border1'>
                    <center>
                      <div class='rounded-circle1 guide_img guide-8'></div>
                    </center>
                  </div>
                  <h6>Mufti Haroon Rashid Ebrahim</h6>
                </div>
                <div className='hajj-price1 m-2'>
                    <p className='fw-bold'>Package Guide</p>
                  </div>
              </div>
            </div>
          </div>

          <div className='card-wrap mt-2 mb-2'>
            <div className='row p-2 '>
              <div className='col-lg-3 card-price-section '>
                <div className='card-img'>
                  <div className='text-center hajj-price'>
                    <h5>Price : SAR 29730.12</h5>
                    <p className='fw-bold'>Excluding Flights</p>
                  </div>
                  <img src={bgimage} />
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='date-flex'>
                  <h4 className='mt-2'>Standard Shifting</h4>
                  <h6
                    onClick={() => openFullscreen(MCDC1052HJ)}
                    class='text-center m-2 p-view-detail'
                  >
                    View Detail
                  </h6>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Package Code</h6>
                  </div>
                  <div>
                    <h6>MCDC1052HJ</h6>
                  </div>
                </div>
                <div className='date-flex '>
                  <div className='mt-2'>
                    <h6>Start Date : 02-JUN-2024</h6>
                  </div>
                  <div className='mt-2'>
                    <h6>End Date : 22-JUN-2024</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Flight</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Gulf Air</h6>
                  </div>
                </div>
                <h6 className='text-center header__center'>Stay</h6>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Madinah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Jayden Hotel</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Makkah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Makkah Towers</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Makkah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Al Masa Al Rawdah</h6>
                  </div>
                </div>
                <div className='text-center'>
                  <button onClick={handleShow} className='Book-button m-2 '>
                    Book Now
                  </button>
                </div>
              </div>
              <div className='col-lg-3 flex-class'>
                <div className='text-center'>
                  <div className='text-center clients card__border'>
                    <center>
                      <div class='rounded-circle guide_img guide-15'></div>
                    </center>
                  </div>
                  <h5>Mohammad Hasib Alam</h5>
                </div>
                <div className='hajj-price1 m-2'>
                    <p className='fw-bold'>Package Guide</p>
                  </div>
              </div>
            </div>
          </div>

          <div className='card-wrap mt-2 mb-2'>
            <div className='row p-2 '>
              <div className='col-lg-3 card-price-section '>
                <div className='card-img'>
                  <div className='text-center hajj-price'>
                    <h5>Price : SAR 37803.12</h5>
                    <p className='fw-bold'>Excluding Flights</p>
                  </div>
                  <img src={bgimage} />
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='date-flex'>
                  <h4 className='mt-2'>Standard Non-Shifting</h4>
                  <h6
                    onClick={() => openFullscreen(MCDC1053HJ)}
                    class='text-center m-2 p-view-detail'
                  >
                    View Detail
                  </h6>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Package Code</h6>
                  </div>
                  <div>
                    <h6>MCDC1053HJ</h6>
                  </div>
                </div>
                <div className='date-flex '>
                  <div className='mt-2'>
                    <h6>Start Date : 02-JUN-2024</h6>
                  </div>
                  <div className='mt-2'>
                    <h6>End Date : 21-JUN-2024</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Flight</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Gulf Air</h6>
                  </div>
                </div>
                <h6 className='text-center header__center'>Stay</h6>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Madinah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Aqeeq Hotel</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Makkah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Makkah Towers</h6>
                  </div>
                </div>
                <div className='text-center'>
                  <button onClick={handleShow} className='Book-button m-2 '>
                    Book Now
                  </button>
                </div>
              </div>
              <div className='col-lg-3 flex-class'>
                <div className='text-center'>
                  <div className='text-center clients card__border'>
                    <center>
                      <div class='rounded-circle guide_img guide-1'></div>
                    </center>
                  </div>
                  <h5>Sheikh Adan qanyare</h5>
                </div>
                <div className='hajj-price1 m-2'>
                    <p className='fw-bold'>Package Guide</p>
                  </div>
              </div>
            </div>
          </div>

          <div className='card-wrap mt-2 mb-2'>
            <div className='row p-2 '>
              <div className='col-lg-3 card-price-section '>
                <div className='card-img'>
                  <div className='text-center hajj-price'>
                    <h5>Price : SAR 29612.37</h5>
                    <p className='fw-bold'>Excluding Flights</p>
                  </div>
                  <img src={bgimage} />
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='date-flex'>
                  <h4 className='mt-2'>Standard Shifting</h4>
                  <h6
                    onClick={() => openFullscreen(MCDC1054HJ)}
                    class='text-center m-2 p-view-detail'
                  >
                    View Detail
                  </h6>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Package Code</h6>
                  </div>
                  <div>
                    <h6>MCDC1054HJ</h6>
                  </div>
                </div>
                <div className='date-flex '>
                  <div className='mt-2'>
                    <h6>Start Date : 03-JUN-2024</h6>
                  </div>
                  <div className='mt-2'>
                    <h6>End Date : 22-JUN-2024</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Flight</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Gulf Air</h6>
                  </div>
                </div>
                <h6 className='text-center header__center'>Stay</h6>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Madinah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Jayden Hotel</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Makkah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Makkah Towers</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Makkah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Al Masa Al Rawdah</h6>
                  </div>
                </div>
                <div className='text-center'>
                  <button onClick={handleShow} className='Book-button m-2 '>
                    Book Now
                  </button>
                </div>
              </div>
              <div className='col-lg-3 flex-class'>
                <div className='text-center'>
                  <div className='text-center clients card__border1'>
                    <center>
                      <div class='rounded-circle1 guide_img guide-19'></div>
                    </center>
                  </div>
                  <h6>Mohammad Iryaan</h6>
                  <div className='text-center clients card__border1'>
                    <center>
                      <div class='rounded-circle1 guide_img guide-27'></div>
                    </center>
                  </div>
                  <h6>Anees Chaudry</h6>
                </div>
                <div className='hajj-price1 m-2'>
                    <p className='fw-bold'>Package Guide</p>
                  </div>
              </div>
            </div>
          </div>

          <div className='card-wrap mt-2 mb-2'>
            <div className='row p-2 '>
              <div className='col-lg-3 card-price-section '>
                <div className='card-img'>
                  <div className='text-center hajj-price'>
                    <h5>Price : SAR 35658.70</h5>
                    <p className='fw-bold'>Excluding Flights</p>
                  </div>

                  <img src={bgimage} />
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='date-flex'>
                  <h4 className='mt-2'>Luxury Non-Shifting</h4>
                  <h6
                    onClick={() => openFullscreen(MCDC1055HJ)}
                    class='text-center m-2 p-view-detail'
                  >
                    View Detail
                  </h6>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Package Code</h6>
                  </div>
                  <div>
                    <h6>MCDC1055HJ</h6>
                  </div>
                </div>
                <div className='date-flex '>
                  <div className='mt-2'>
                    <h6>Start Date : 02-JUN-2024</h6>
                  </div>
                  <div className='mt-2'>
                    <h6>End Date : 21-JUN-2024</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Flight</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Gulf Air</h6>
                  </div>
                </div>
                <h6 className='text-center header__center'>Stay</h6>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Madinah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Aqeeq Hotel</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Makkah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Makkah Towers</h6>
                  </div>
                </div>
                <div className='text-center'>
                  <button onClick={handleShow} className='Book-button m-2 '>
                    Book Now
                  </button>
                </div>
              </div>
              <div className='col-lg-3 flex-class'>
                <div className='text-center'>
                  <div className='text-center clients card__border'>
                    <center>
                      <div class='rounded-circle guide_img guide-17'></div>
                    </center>
                  </div>
                  <h5>Hafiz Zahid Iqbal</h5>
                </div>
                <div className='hajj-price1 m-2'>
                    <p className='fw-bold'>Package Guide</p>
                  </div>
              </div>
            </div>
          </div>

          <div className='card-wrap mt-2 mb-2'>
            <div className='row p-2 '>
              <div className='col-lg-3 card-price-section '>
                <div className='card-img'>
                  <div className='text-center hajj-price'>
                    <h5>Price : SAR 30154.09</h5>
                    <p className='fw-bold'>Excluding Flights</p>
                  </div>

                  <img src={bgimage} />
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='date-flex'>
                  <h4 className='mt-2'>Standard Shifting</h4>
                  <h6
                    onClick={() => openFullscreen(MCDC1056HJ)}
                    class='text-center m-2 p-view-detail'
                  >
                    View Detail
                  </h6>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Package Code</h6>
                  </div>
                  <div>
                    <h6>MCDC1056HJ</h6>
                  </div>
                </div>
                <div className='date-flex '>
                  <div className='mt-2'>
                    <h6>Start Date : 05-JUN-2024</h6>
                  </div>
                  <div className='mt-2'>
                    <h6>End Date : 25-JUN-2024</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Flight</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Egypt Air</h6>
                  </div>
                </div>
                <h6 className='text-center header__center'>Stay</h6>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Madinah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Jayden Hotel</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Makkah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Makkah Towers</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Makkah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Al Masa Al Rawdah</h6>
                  </div>
                </div>
                <div className='text-center'>
                  <button onClick={handleShow} className='Book-button m-2 '>
                    Book Now
                  </button>
                </div>
              </div>
              <div className='col-lg-3 flex-class'>
                <div className='text-center'>
                  <div className='text-center clients card__border'>
                    <center>
                      <div class='rounded-circle guide_img guide-24'></div>
                    </center>
                  </div>
                  <h5>Kudrat Hussain</h5>
                </div>
                <div className='hajj-price1 m-2'>
                    <p className='fw-bold'>Package Guide</p>
                  </div>
              </div>
            </div>
          </div>

          {/* <div className='card-wrap mt-2 mb-2'>
          <div className='row p-2 '>
            <div className='col-lg-3 card-price-section '>
              <div className='card-img'>
                <img src={bgimage} />
              </div>
            </div>
            <div className='col-lg-6'>
              <h4 className='mt-2'>Standard Non-Shifting</h4>
              <div className='d-flex justify-content-between mt-2'>
                <div>
                  <h6>Package Code</h6>
                </div>
                <div>
                  <h6>MCDC1057HJ</h6>
                </div>
              </div>
              <div className='date-flex '>
                <div className='mt-2'>
                  <h6>Start Date : 09-JUN-2024</h6>
                </div>
                <div className='mt-2'>
                  <h6>End Date : 23-JUN-2024</h6>
                </div>
              </div>
              <div className='d-flex justify-content-between mt-2'>
                <div>
                  <h6>Flight</h6>
                </div>
                <div className='d-flex'>
                  <h6>Egypt Air</h6>
                </div>
              </div>
              <h6 className='text-center header__center'>Stay</h6>
              <div className='d-flex justify-content-between mt-2'>
                <div>
                  <h6>Madinah</h6>
                </div>
                <div className='d-flex'>
                  <h6>Jayden Hotel</h6>
                </div>
              </div>
              <div className='d-flex justify-content-between mt-2'>
                <div>
                  <h6>Makkah</h6>
                </div>
                <div className='d-flex'>
                  <h6>Makkah Towers</h6>
                </div>
              </div>
            </div>
            <div className='col-lg-3 flex-class'>
              <div className='text-center'>
              <h6 onClick={() => openFullscreen(MCDC1057HJ)} class="text-center m-2 p-view-detail">View Detail</h6>
                <h4>Price : SAR 33830.43</h4>
                <h6>Excluding Flights</h6>
                <button className='Book-button m-2 ' onClick={handleShow}>Book Now</button>
              </div>
            </div>
          </div>
        </div> */}

          <div className='card-wrap mt-2 mb-2'>
            <div className='row p-2 '>
              <div className='col-lg-3 card-price-section '>
                <div className='card-img'>
                  <div className='text-center hajj-price'>
                    <h5>Price : SAR 34958.57</h5>
                    <p className='fw-bold'>Excluding Flights</p>
                  </div>

                  <img src={bgimage} />
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='date-flex'>
                  <h4>Standard Package Non-Shifting</h4>
                  <h6
                    onClick={() => openFullscreen(MCDC1058HJ)}
                    class='text-center m-2 p-view-detail'
                  >
                    View Detail
                  </h6>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Package Code</h6>
                  </div>
                  <div>
                    <h6>MCDC1058HJ</h6>
                  </div>
                </div>
                <div className='date-flex '>
                  <div className='mt-2'>
                    <h6>Start Date : 05-JUN-2024</h6>
                  </div>
                  <div className='mt-2'>
                    <h6>End Date : 28-JUN-2024</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Flight</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Egypt Air</h6>
                  </div>
                </div>
                <h6 className='text-center header__center'>Stay</h6>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Madinah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Jayden Hotel</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Makkah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Royal Majestic</h6>
                  </div>
                </div>
                <div className='text-center'>
                  <button onClick={handleShow} className='Book-button m-2 '>
                    Book Now
                  </button>
                </div>
              </div>
              <div className='col-lg-3 flex-class'>
                <div className='text-center'>
                  <div className='text-center clients card__border'>
                    <center>
                      <div class='rounded-circle guide_img guide-11'></div>
                    </center>
                  </div>
                  <h5>Maulana Sajid Ali Khan</h5>
                </div>
                <div className='hajj-price1 m-2'>
                    <p className='fw-bold'>Package Guide</p>
                  </div>
              </div>
            </div>
          </div>

          <div className='card-wrap mt-2 mb-2'>
            <div className='row p-2 '>
              <div className='col-lg-3 card-price-section '>
                <div className='card-img'>
                  <div className='text-center hajj-price'>
                    <h5>Price : SAR 30490.61</h5>
                    <p className='fw-bold'>Excluding Flights</p>
                  </div>
                  <img src={bgimage} />
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='date-flex'>
                  <h4>Standard Package Non-Shifting</h4>
                  <h6
                    onClick={() => openFullscreen(MCDC1059HJ)}
                    class='text-center m-2 p-view-detail'
                  >
                    View Detail
                  </h6>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Package Code</h6>
                  </div>
                  <div>
                    <h6>MCDC1059HJ</h6>
                  </div>
                </div>
                <div className='date-flex '>
                  <div className='mt-2'>
                    <h6>Start Date : 10-JUN-2024</h6>
                  </div>
                  <div className='mt-2'>
                    <h6>End Date : 24-JUN-2024</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Flight</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Egypt Air</h6>
                  </div>
                </div>
                <h6 className='text-center header__center'>Stay</h6>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Madinah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Jayden Hotel</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Makkah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Royal Majestic</h6>
                  </div>
                </div>
                <div className='text-center'>
                  <button onClick={handleShow} className='Book-button m-2 '>
                    Book Now
                  </button>
                </div>
              </div>
              <div className='col-lg-3 flex-class'>
                <div className='text-center'>
                  <div className='text-center clients card__border'>
                    <center>
                      <div class='rounded-circle guide_img guide-22'></div>
                    </center>
                  </div>
                  <h5>Maulana Mohammed Kaleem</h5>
                </div>
                <div className='hajj-price1 m-2'>
                    <p className='fw-bold'>Package Guide</p>
                  </div>
              </div>
            </div>
          </div>

          <div className='card-wrap mt-2 mb-2'>
            <div className='row p-2 '>
              <div className='col-lg-3 card-price-section '>
                <div className='card-img'>
                  <div className='text-center hajj-price'>
                    <h5>Price : SAR 33830.43</h5>
                    <p className='fw-bold'>Excluding Flights</p>
                  </div>
                  <img src={bgimage} />
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='date-flex'>
                  <h4>Standard Non-Shifting</h4>
                  <h6
                    onClick={() => openFullscreen(MCDC1060HJ)}
                    class='text-center m-2 p-view-detail'
                  >
                    View Detail
                  </h6>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Package Code</h6>
                  </div>
                  <div>
                    <h6>MCDC1060HJ</h6>
                  </div>
                </div>
                <div className='date-flex '>
                  <div className='mt-2'>
                    <h6>Start Date : 09-JUN-2024</h6>
                  </div>
                  <div className='mt-2'>
                    <h6>End Date : 23-JUN-2024</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Flight</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Egypt Air</h6>
                  </div>
                </div>
                <h6 className='text-center header__center'>Stay</h6>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Madinah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Jayden Hotel</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Makkah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Makkah Towers</h6>
                  </div>
                </div>
                <div className='text-center'>
                  <button onClick={handleShow} className='Book-button m-2 '>
                    Book Now
                  </button>
                </div>
              </div>
              <div className='col-lg-3 flex-class'>
                <div className='text-center'>
                  <div className='text-center clients card__border'>
                    <center>
                      <div class='rounded-circle guide_img guide-13'></div>
                    </center>
                  </div>
                  <h5>Sheikh Anis Ahmed</h5>
                </div>
                <div className='hajj-price1 m-2'>
                    <p className='fw-bold'>Package Guide</p>
                  </div>
              </div>
            </div>
          </div>

          <div className='card-wrap mt-2 mb-2'>
            <div className='row p-2 '>
              <div className='col-lg-3 card-price-section '>
                <div className='card-img'>
                  <div className='text-center hajj-price'>
                    <h5>Price : SAR 33830.43</h5>
                    <p className='fw-bold'>Excluding Flights</p>
                  </div>
                  <img src={bgimage} />
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='date-flex'>
                  <h4>Standard Non-Shifting</h4>
                  <h6
                    onClick={() => openFullscreen(MCDC1061HJ)}
                    class='text-center m-2 p-view-detail'
                  >
                    View Detail
                  </h6>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Package Code</h6>
                  </div>
                  <div>
                    <h6>MCDC1061HJ</h6>
                  </div>
                </div>
                <div className='date-flex '>
                  <div className='mt-2'>
                    <h6>Start Date : 09-JUN-2024</h6>
                  </div>
                  <div className='mt-2'>
                    <h6>End Date : 23-JUN-2024</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Flight</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Egypt Air</h6>
                  </div>
                </div>
                <h6 className='text-center header__center'>Stay</h6>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Madinah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Jayden Hotel</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Makkah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Makkah Towers</h6>
                  </div>
                </div>
                <div className='text-center'>
                  <button onClick={handleShow} className='Book-button m-2 '>
                    Book Now
                  </button>
                </div>
              </div>
              <div className='col-lg-3 flex-class'>
                <div className='text-center'>
                  <div className='text-center clients card__border'>
                    <center>
                      <div class='rounded-circle guide_img guide-4'></div>
                    </center>
                  </div>
                  <h5>Mufti Mehboob ur Rehman</h5>
                </div>
                <div className='hajj-price1 m-2'>
                    <p className='fw-bold'>Package Guide</p>
                  </div>
              </div>
            </div>
          </div>

          <div className='card-wrap mt-2 mb-2'>
            <div className='row p-2 '>
              <div className='col-lg-3 card-price-section '>
                <div className='card-img'>
                  <div className='text-center hajj-price'>
                    <h5>Price : SAR 30435.36</h5>
                    <p className='fw-bold'>Excluding Flights</p>
                  </div>
                  <img src={bgimage} />
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='date-flex'>
                  <h4>Standard Non-Shifting</h4>
                  <h6
                    onClick={() => openFullscreen(MCDC1062HJ)}
                    class='text-center m-2 p-view-detail'
                  >
                    View Detail
                  </h6>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Package Code</h6>
                  </div>
                  <div>
                    <h6>MCDC1062HJ</h6>
                  </div>
                </div>
                <div className='date-flex '>
                  <div className='mt-2'>
                    <h6>Start Date : 08-JUN-2024</h6>
                  </div>
                  <div className='mt-2'>
                    <h6>End Date : 24-JUN-2024</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Flight</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Egypt Air</h6>
                  </div>
                </div>
                <h6 className='text-center header__center'>Stay</h6>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Madinah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Jayden Hotel</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Makkah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Royal Majestic</h6>
                  </div>
                </div>
                <div className='text-center'>
                  <button onClick={handleShow} className='Book-button m-2 '>
                    Book Now
                  </button>
                </div>
              </div>
              <div className='col-lg-3 flex-class'>
                <div className='text-center'>
                  <div className='text-center clients card__border'>
                    <center>
                      <div class='rounded-circle guide_img guide-2'></div>
                    </center>
                  </div>
                  <h5>Sheikh Sadaqat Hussain</h5>
                </div>
                <div className='hajj-price1 m-2'>
                    <p className='fw-bold'>Package Guide</p>
                  </div>
              </div>
            </div>
          </div>

          <div className='card-wrap mt-2 mb-2'>
            <div className='row p-2 '>
              <div className='col-lg-3 card-price-section '>
                <div className='card-img'>
                  <div className='text-center hajj-price'>
                    <h5>Price : SAR 47677.96</h5>
                    <p className='fw-bold'>Excluding Flights</p>
                  </div>
                  <img src={bgimage} />
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='date-flex'>
                  <h4>Luxury Package Non-Shifting</h4>
                  <h6
                    onClick={() => openFullscreen(MCDC1063HJ)}
                    class='text-center m-2 p-view-detail'
                  >
                    View Detail
                  </h6>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Package Code</h6>
                  </div>
                  <div>
                    <h6>MCDC1063HJ</h6>
                  </div>
                </div>
                <div className='date-flex '>
                  <div className='mt-2'>
                    <h6>Start Date : 10-JUN-2024</h6>
                  </div>
                  <div className='mt-2'>
                    <h6>End Date : 23-JUN-2024</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Flight</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Egypt Air</h6>
                  </div>
                </div>
                <h6 className='text-center header__center'>Stay</h6>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Madinah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Aqeeq Hotel</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Makkah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Fairmont Clock Tower</h6>
                  </div>
                </div>
                <div className='text-center'>
                  <button onClick={handleShow} className='Book-button m-2 '>
                    Book Now
                  </button>
                </div>
              </div>
              <div className='col-lg-3 flex-class'>
                <div className='text-center'>
                  <div className='text-center clients card__border'>
                    <center>
                      <div class='rounded-circle guide_img guide-18'></div>
                    </center>
                  </div>
                  <h5>Mohammed Zahid Aslam</h5>
                </div>
                <div className='hajj-price1 m-2'>
                    <p className='fw-bold'>Package Guide</p>
                  </div>
              </div>
            </div>
          </div>
          <div className='card-wrap mt-2 mb-2'>
            <div className='row p-2 '>
              <div className='col-lg-3 card-price-section '>
                <div className='card-img'>
                  <div className='text-center hajj-price'>
                    <h5>Price : SAR 37588.14</h5>
                    <p className='fw-bold'>Excluding Flights</p>
                  </div>
                  <img src={bgimage} />
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='date-flex'>
                  <h4>Standard Non-Shifting</h4>
                  <h6
                    onClick={() => openFullscreen(MCDC1065HJ)}
                    class='text-center m-2 p-view-detail'
                  >
                    View Detail
                  </h6>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Package Code</h6>
                  </div>
                  <div>
                    <h6>MCDC1065HJ</h6>
                  </div>
                </div>
                <div className='date-flex '>
                  <div className='mt-2'>
                    <h6>Start Date : 09-JUN-2024</h6>
                  </div>
                  <div className='mt-2'>
                    <h6>End Date : 24-JUN-2024</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Flight</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Gulf Airways</h6>
                  </div>
                </div>
                <h6 className='text-center header__center'>Stay</h6>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Madinah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Aqeeq Hotel</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Makkah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Makkah Towers</h6>
                  </div>
                </div>
                <div className='text-center'>
                  <button onClick={handleShow} className='Book-button m-2 '>
                    Book Now
                  </button>
                </div>
              </div>
              <div className='col-lg-3 flex-class'>
                <div className='text-center'>
                  <div className='text-center clients card__border'>
                    <center>
                      <div class='rounded-circle guide_img guide-12'></div>
                    </center>
                  </div>
                  <h5>Maulana Hafiz Mohammed Hassan</h5>
                </div>
                <div className='hajj-price1 m-2'>
                    <p className='fw-bold'>Package Guide</p>
                  </div>
              </div>
            </div>
          </div>

          <div className='card-wrap mt-2 mb-2'>
            <div className='row p-2 '>
              <div className='col-lg-3 card-price-section '>
                <div className='card-img'>
                  <div className='text-center hajj-price'>
                    <h5>Price : SAR 37741.53</h5>
                    <p className='fw-bold'>Excluding Flights</p>
                  </div>
                  <img src={bgimage} />
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='date-flex'>
                  <h4>Standard Non-Shifting</h4>
                  <h6
                    onClick={() => openFullscreen(MCDC1070HJ)}
                    class='text-center m-2 p-view-detail'
                  >
                    View Detail
                  </h6>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Package Code</h6>
                  </div>
                  <div>
                    <h6>MCDC1070HJ</h6>
                  </div>
                </div>
                <div className='date-flex '>
                  <div className='mt-2'>
                    <h6>Start Date : 08-JUN-2024</h6>
                  </div>
                  <div className='mt-2'>
                    <h6>End Date : 24-JUN-2024</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Flight</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Egypt Air</h6>
                  </div>
                </div>
                <h6 className='text-center header__center'>Stay</h6>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Madinah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Aqeeq Hotel</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Makkah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Makkah Towers</h6>
                  </div>
                </div>
                <div className='text-center'>
                  <button onClick={handleShow} className='Book-button m-2 '>
                    Book Now
                  </button>
                </div>
              </div>
              <div className='col-lg-3 flex-class'>
                <div className='text-center'>
                  <div className='text-center clients card__border'>
                    <center>
                      <div class='rounded-circle guide_img guide-5'></div>
                    </center>
                  </div>
                  <h5>Sarfraz Nazir</h5>
                </div>
                <div className='hajj-price1 m-2'>
                    <p className='fw-bold'>Package Guide</p>
                  </div>
              </div>
            </div>
          </div>

          <div className='card-wrap mt-2 mb-2'>
            <div className='row p-2 '>
              <div className='col-lg-3 card-price-section '>
                <div className='card-img'>
                  <div className='text-center hajj-price'>
                    <h5>Price : SAR 35098.89</h5>
                    <p className='fw-bold'>Excluding Flights</p>
                  </div>
                  <img src={bgimage} />
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='date-flex'>
                  <h4>Luxury Shifting</h4>
                  <h6
                    onClick={() => openFullscreen(MCDC1075HJ)}
                    class='text-center m-2 p-view-detail'
                  >
                    View Detail
                  </h6>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Package Code</h6>
                  </div>
                  <div>
                    <h6>MCDC1075HJ</h6>
                  </div>
                </div>
                <div className='date-flex '>
                  <div className='mt-2'>
                    <h6>Start Date : 03-JUN-2024</h6>
                  </div>
                  <div className='mt-2'>
                    <h6>End Date : 24-JUN-2024</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Flight</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Qatar Airways</h6>
                  </div>
                </div>
                <h6 className='text-center header__center'>Stay</h6>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Madinah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Aqeeq Hotel</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Makkah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Fairmont Clock Tower</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Makkah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Ibis Styles Hotel-Accor Group</h6>
                  </div>
                </div>
                <div className='text-center'>
                  <button onClick={handleShow} className='Book-button m-2 '>
                    Book Now
                  </button>
                </div>
              </div>
              <div className='col-lg-3 flex-class'>
                <div className='text-center'>
                  <div className='text-center clients card__border'>
                    <center>
                      <div class='rounded-circle guide_img guide-7'></div>
                    </center>
                  </div>
                  <h5>Mohammed Toheed Mehrban</h5>
                </div>
                <div className='hajj-price1 m-2'>
                    <p className='fw-bold'>Package Guide</p>
                  </div>
              </div>
            </div>
          </div>

          <div className='card-wrap mt-2 mb-2'>
            <div className='row p-2 '>
              <div className='col-lg-3 card-price-section '>
                <div className='card-img'>
                  <div className='text-center hajj-price'>
                    <h5>Price : SAR 31936.19</h5>
                    <p className='fw-bold'>Excluding Flights</p>
                  </div>
                  <img src={bgimage} />
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='date-flex'>
                  <h4>Standard Shifting</h4>
                  <h6
                    onClick={() => openFullscreen(MCDC1080HJ)}
                    class='text-center m-2 p-view-detail'
                  >
                    View Detail
                  </h6>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Package Code</h6>
                  </div>
                  <div>
                    <h6>MCDC1080HJ</h6>
                  </div>
                </div>
                <div className='date-flex '>
                  <div className='mt-2'>
                    <h6>Start Date : 05-JUN-2024</h6>
                  </div>
                  <div className='mt-2'>
                    <h6>End Date : 26-JUN-2024</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Flight</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Egypt Air</h6>
                  </div>
                </div>
                <h6 className='text-center header__center'>Stay</h6>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Madinah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Jayden Hotel</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Makkah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Fairmont Clock Tower</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Makkah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Ibis Styles Hotel-Accor Group</h6>
                  </div>
                </div>
                <div className='text-center'>
                  <button onClick={handleShow} className='Book-button m-2 '>
                    Book Now
                  </button>
                </div>
              </div>
              <div className='col-lg-3 flex-class'>
                <div className='text-center'>
                  <div className='text-center clients card__border'>
                    <center>
                      <div class='rounded-circle guide_img guide-26'></div>
                    </center>
                  </div>
                  <h5>Mohammad Ajaz Hussain</h5>
                </div>
                <div className='hajj-price1 m-2'>
                    <p className='fw-bold'>Package Guide</p>
                  </div>
              </div>
            </div>
          </div>

          <div className='card-wrap mt-2 mb-2'>
            <div className='row p-2 '>
              <div className='col-lg-3 card-price-section '>
                <div className='card-img'>
                  <div className='text-center hajj-price'>
                    <h5>Price : SAR 26110.31</h5>
                    <p className='fw-bold'>Excluding Flights</p>
                  </div>
                  <img src={bgimage} />
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='date-flex'>
                  <h4>Standard Package Non-Shifting</h4>
                  <h6
                    onClick={() => openFullscreen(MCDC1090HJ)}
                    class='text-center m-2 p-view-detail'
                  >
                    View Detail
                  </h6>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Package Code</h6>
                  </div>
                  <div>
                    <h6>MCDC1090HJ</h6>
                  </div>
                </div>
                <div className='date-flex '>
                  <div className='mt-2'>
                    <h6>Start Date : 02-JUN-2024</h6>
                  </div>
                  <div className='mt-2'>
                    <h6>End Date : 21-JUN-2024</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Flight</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Gulf Air</h6>
                  </div>
                </div>
                <h6 className='text-center header__center'>Stay</h6>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Madinah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Jayden Hotel</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Makkah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Al Amoudi Residence-Aziziah</h6>
                  </div>
                </div>
                <div className='text-center'>
                  <button onClick={handleShow} className='Book-button m-2 '>
                    Book Now
                  </button>
                </div>
              </div>
              <div className='col-lg-3 flex-class'>
                <div className='text-center'>
                  <div className='text-center clients card__border'>
                    <center>
                      <div class='rounded-circle guide_img guide-28'></div>
                    </center>
                  </div>
                  <h5>Abdul Zaheer Younis</h5>
                </div>
                <div className='hajj-price1 m-2'>
                    <p className='fw-bold'>Package Guide</p>
                  </div>
              </div>
            </div>
          </div>

          <div className='card-wrap mt-2 mb-2'>
            <div className='row p-2 '>
              <div className='col-lg-3 card-price-section '>
                <div className='card-img'>
                  <div className='text-center hajj-price'>
                    <h5>Price : SAR 26640.67</h5>
                    <p className='fw-bold'>Excluding Flights</p>
                  </div>
                  <img src={bgimage} />
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='date-flex'>
                  <h4>Standard Non-Shifting</h4>
                  <h6
                    onClick={() => openFullscreen(MCDC1091HJ)}
                    class='text-center m-2 p-view-detail'
                  >
                    View Detail
                  </h6>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Package Code</h6>
                  </div>
                  <div>
                    <h6>MCDC1091HJ</h6>
                  </div>
                </div>
                <div className='date-flex '>
                  <div className='mt-2'>
                    <h6>Start Date : 08-JUN-2024</h6>
                  </div>
                  <div className='mt-2'>
                    <h6>End Date : 26-JUN-2024</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Flight</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Qatar Air</h6>
                  </div>
                </div>
                <h6 className='text-center header__center'>Stay</h6>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Madinah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Dayar Al Taqwa</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Makkah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Rehab Al Marwa</h6>
                  </div>
                </div>
                <div className='text-center'>
                  <button onClick={handleShow} className='Book-button m-2 '>
                    Book Now
                  </button>
                </div>
              </div>
              <div className='col-lg-3 flex-class'>
                <div className='text-center'>
                  <div className='text-center clients card__border'>
                    <center>
                      <div class='rounded-circle guide_img guide-10'></div>
                    </center>
                  </div>
                  <h5>Qari Saqawat Taj</h5>
                </div>
                <div className='hajj-price1 m-2'>
                    <p className='fw-bold'>Package Guide</p>
                  </div>
              </div>
            </div>
          </div>

          <div className='card-wrap mt-2 mb-2'>
            <div className='row p-2 '>
              <div className='col-lg-3 card-price-section '>
                <div className='card-img'>
                  <div className='text-center hajj-price'>
                    <h5>Price : SAR 49975.44</h5>
                    <p className='fw-bold'>Excluding Flights</p>
                  </div>
                  <img src={bgimage} />
                </div>
              </div>
              <div className='col-lg-9'>
                <div className='date-flex'>
                  <h4>Luxury Non-Shifting</h4>
                  <h6
                    onClick={() => openFullscreen(Package21)}
                    class='text-center m-2 p-view-detail'
                  >
                    View Detail
                  </h6>
                </div>
                <div className='date-flex '>
                  <div className='mt-2'>
                    <h6>Start Date : 07-JUN-2024</h6>
                  </div>
                  <div className='mt-2'>
                    <h6>End Date : 21-JUN-2024</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Flight</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Saudia Airways</h6>
                  </div>
                </div>
                <h6 className='text-center header__center'>Stay</h6>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Madinah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Dallah Taibah</h6>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div>
                    <h6>Makkah</h6>
                  </div>
                  <div className='d-flex'>
                    <h6>Anjum Hotel</h6>
                  </div>
                </div>
                <div className='text-center'>
                  <button onClick={handleShow} className='Book-button m-2 '>
                    Book Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Home
