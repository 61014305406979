import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faPlaneDeparture,
  faArrowRight,
  faCheck
} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import Axios from 'axios'
import { ViewTourDetail } from '../../Redux/Actions/actions'
import { useSelector, useDispatch } from 'react-redux'
import Loading from '../Loading/Loader'
import {
  CustomerDomainName,
  Hotelapitoken,
  ApiEndPoint
} from '../GlobalData/GlobalData'
import { Tooltip, Whisper } from 'rsuite'
function PackageDetailCard ({ filterData }) {
  const [loading, setLoading] = useState(false)
  const [showFilter, setShowFilter] = useState(true)
  const [FilterData, setFilterData] = useState([])
  const [FilterOtherData, setFilterOtherData] = useState([])
  const Dispatch = useDispatch()
  const navigation = useNavigate()
  const imageurl = CustomerDomainName()
  const TourData = useSelector(state => state.hotels.toursdetail)
  const ToursDetail = TourData.tours.sort(
    (a, b) => new Date(a.start_date) - new Date(b.start_date)
  )
  var apiendpoint = ApiEndPoint()

  const fetchViewDetail = async (event, id, otherprovider, name) => {
    event.preventDefault()
    var apitoken = Hotelapitoken()
    setLoading(true)
    if (otherprovider === 0) {
      var data = {
        token: apitoken,
        id: id,
        type: 'tour',
        provider_token: ''
      }
    } else {
      var data = {
        token: apitoken,
        id: id,
        type: 'tour',
        provider_token: otherprovider
      }
    }

    try {
      const response = await Axios.post(
        apiendpoint + '/api/get_tour_details',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      Dispatch(ViewTourDetail(response.data))
      const result = name.split(' ').join('-')
      navigation(`/umrah-package/${result}`)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    DataFilter()
  }, [filterData])

  const DataFilter = () => {
    if (filterData !== '') {
      if (filterData.type === 'price') {
        const filteredTours = ToursDetail.filter(tour => {
          const price = tour.quad_grand_total_amount
          return price >= filterData.min && price <= filterData.max
        })
        setFilterData(filteredTours)
        setShowFilter(false)
      } else if (filterData.type === 'Stars') {
        if (
          filterData.rating1 === '0' &&
          filterData.rating2 === '0' &&
          filterData.rating3 === '0' &&
          filterData.rating4 === '0' &&
          filterData.rating5 === '0'
        ) {
          setFilterData(ToursDetail)
          setShowFilter(false)
        } else {
          const filteredTours = ToursDetail.filter(tour => {
            const hotelRating = tour.starts_rating
            if (hotelRating !== '') {
              return Object.keys(filterData).some(
                ratingKey =>
                  Number(hotelRating) === Number(filterData[ratingKey])
              )
            }
          })
          setFilterData(filteredTours)
          setShowFilter(false)
        }
      } else if (filterData.type === 'airport') {
        var length = Object.keys(filterData).length
        if (length === 1) {
          setFilterData(ToursDetail)
          setShowFilter(false)
        } else {
          const filteredTours = ToursDetail.filter(tour => {
            var flightdetail = JSON.parse(tour.flights_details)

            var name =
              flightdetail === null
                ? ''
                : flightdetail[0].departure_airport_code

            return Object.keys(filterData).some(
              ratingKey => name === filterData[ratingKey]
            )
          })
          setFilterData(filteredTours)
          setShowFilter(false)
        }
      }
    }
  }

  return (
    <>
      {loading && <Loading />}
      {showFilter ? (
        <div class='bravo-list-item'>
          <div class='ajax-search-result '>
            <div class='list-item'>
              <div class='isotope-wrapper'>
                {ToursDetail.map((item, index) => {
                  const flightDetails = JSON.parse(item.flights_details)
                  return (
                    <div key={index} class='isotope-item latest'>
                      <div class='box_list' data-cue='slideInUp'>
                        <div class='row g-0'>
                          <div class='col-lg-5'>
                            <figure>
                              <small>{item.time_duration} Nights</small>
                              <a>
                                <img
                                  class='img-fluid'
                                  src={
                                    imageurl +
                                    'public/uploads/package_imgs/' +
                                    item.tour_banner_image
                                  }
                                  alt=''
                                />
                              </a>
                            </figure>
                          </div>
                          <div class='col-lg-7'>
                            <div class='wrapper'>
                              <a class='wish_btn'>
                                <div class='location text-primary d-flex justify-content-between align-items-center'>
                                  {moment(item.start_date).format('ll')}
                                  <FontAwesomeIcon icon={faArrowRight} />{' '}
                                  {moment(item.end_date).format('ll')}
                                </div>
                              </a>
                              {item.starts_rating === '' ? (
                                <div class='cat_star'>No Rating</div>
                              ) : (
                                <div class='cat_star'>
                                  {Array(item.starts_rating)
                                    .fill(0)
                                    .map((_, index) => (
                                      <i
                                        style={{ color: '#fa5636' }}
                                        key={index}
                                        className='fa fa-star'
                                      >
                                        <FontAwesomeIcon icon={faStar} />
                                      </i>
                                    ))}
                                </div>
                              )}

                              <h3>
                                <a
                                  onClick={event =>
                                    fetchViewDetail(
                                      event,
                                      item.id,
                                      0,
                                      item.title
                                    )
                                  }
                                  className='hotel-card-name'
                                >
                                  {item.title}
                                </a>
                              </h3>
                              <p>{item.content}</p>
                              <div class='d-flex  justify-content-between align-items-center'>
                                <p className='fw-bold mb-0'>Departure From</p>
                                <p className='mb-0'>
                                  {flightDetails === null
                                    ? ''
                                    : flightDetails[0]?.departure_airport_code}
                                </p>
                              </div>
                              <span class='price'>
                                From{' '}
                                <strong>
                                  {item.currency_symbol}{' '}
                                  {item.quad_grand_total_amount !== null
                                    ? item.quad_grand_total_amount
                                    : item.triple_grand_total_amount !== null
                                    ? item.triple_grand_total_amount
                                    : item.double_grand_total_amount !== null
                                    ? item.double_grand_total_amount
                                    : 'N/A'}
                                </strong>
                              </span>
                            </div>
                            <ul>
                              <li>Pax: {item.no_of_pax_days}</li>
                              <li>
                                <div
                                  style={{ cursor: 'pointer' }}
                                  class='score '
                                >
                                  <strong
                                    onClick={event =>
                                      fetchViewDetail(
                                        event,
                                        item.id,
                                        0,
                                        item.title
                                      )
                                    }
                                  >
                                    Book Now
                                  </strong>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
                <div className='my-3'>
                  <h5 className='flight-heading text-light'>
                    Packages From Other Providers
                  </h5>
                </div>
                {TourData.other_providers_tours.map((provider, index) => (
                  <div key={index} class='isotope-item latest'>
                    {provider[0]
                      .filter(item => moment(item.start_date).isAfter(moment()))
                      .sort(
                        (a, b) =>
                          new Date(a.start_date) - new Date(b.start_date)
                      ) // Sorting by start_date
                      .map((item, index) => {
                        var flightDetails
                        if (item?.flights_details) {
                          flightDetails = JSON.parse(item?.flights_details)
                        }
                        return (
                          <div class='box_list' data-cue='slideInUp'>
                            <div class='row g-0'>
                              <div class='col-lg-5'>
                                <figure>
                                  <small>{item.time_duration} Nights</small>
                                  <a>
                                    <img
                                      class='img-fluid'
                                      src={
                                        provider[1].dashboard_Address +
                                        '/public/uploads/package_imgs/' +
                                        item.tour_banner_image
                                      }
                                      alt=''
                                    />
                                  </a>
                                </figure>
                              </div>
                              <div class='col-lg-7'>
                                <div class='wrapper'>
                                  <a class='wish_btn'>
                                    <div class='location text-primary d-flex justify-content-between align-items-center'>
                                      {moment(item.start_date).format('ll')}
                                      <FontAwesomeIcon
                                        icon={faArrowRight}
                                      />{' '}
                                      {moment(item.end_date).format('ll')}
                                    </div>
                                  </a>
                                  {item.starts_rating === '' ? (
                                    <div class='cat_star'>No Rating</div>
                                  ) : (
                                    <div class='cat_star'>
                                      {Array(item.starts_rating)
                                        .fill(0)
                                        .map((_, index) => (
                                          <i
                                            style={{ color: '#fa5636' }}
                                            key={index}
                                            className='fa fa-star'
                                          >
                                            <FontAwesomeIcon icon={faStar} />
                                          </i>
                                        ))}
                                    </div>
                                  )}

                                  <h3>
                                    <a
                                      onClick={event =>
                                        fetchViewDetail( event,item.id,0,item.title)
                                      }
                                      className='hotel-card-name'
                                    >
                                      {item.title}
                                    </a>
                                  </h3>
                                  <p>{item.content}</p>
                                  <div class='d-flex  justify-content-between align-items-center'>
                                    <p className='fw-bold mb-0'>
                                      Departure From
                                    </p>
                                    <p className='mb-0'>
                                      {flightDetails === null
                                        ? ''
                                        : flightDetails[0]?.departure_airport_code}
                                    </p>
                                  </div>
                                  <span class='price'>
                                    From{' '}
                                    <strong>
                                      {item.currency_symbol}{' '}
                                      {item.quad_grand_total_amount !== null
                                        ? item.quad_grand_total_amount
                                        : item.triple_grand_total_amount !==
                                          null
                                        ? item.triple_grand_total_amount
                                        : item.double_grand_total_amount !==
                                          null
                                        ? item.double_grand_total_amount
                                        : 'N/A'}
                                    </strong>
                                  </span>
                                </div>
                                <ul>
                                  <li>Pax: {item.no_of_pax_days}</li>
                                  <li>
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      class='score '
                                    >
                                      <strong
                                        onClick={event =>
                                          fetchViewDetail(
                                            event,
                                            item.id,
                                            0,
                                            item.title
                                          )
                                        }
                                      >
                                        Book Now
                                      </strong>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div class='bravo-list-item'>
          <div class='ajax-search-result '>
            <div class='list-item'>
              <div class='row'>
                {FilterData.map((item, index) => {
                  const flightDetails = JSON.parse(item.flights_details)
                  return (
                    <div key={index} class='isotope-item latest'>
                      <div class='box_list' data-cue='slideInUp'>
                        <div class='row g-0'>
                          <div class='col-lg-5'>
                            <figure>
                              <small>{item.time_duration} Nights</small>
                              <a>
                                <img
                                  class='img-fluid'
                                  src={
                                    imageurl +
                                    'public/uploads/package_imgs/' +
                                    item.tour_banner_image
                                  }
                                  alt=''
                                />
                              </a>
                            </figure>
                          </div>
                          <div class='col-lg-7'>
                            <div class='wrapper'>
                              <a class='wish_btn'>
                                <div class='location text-primary d-flex justify-content-between align-items-center'>
                                  {moment(item.start_date).format('ll')}
                                  <FontAwesomeIcon icon={faArrowRight} />{' '}
                                  {moment(item.end_date).format('ll')}
                                </div>
                              </a>
                              {item.starts_rating === '' ? (
                                <div class='cat_star'>No Rating</div>
                              ) : (
                                <div class='cat_star'>
                                  {Array(item.starts_rating)
                                    .fill(0)
                                    .map((_, index) => (
                                      <i
                                        style={{ color: '#fa5636' }}
                                        key={index}
                                        className='fa fa-star'
                                      >
                                        <FontAwesomeIcon icon={faStar} />
                                      </i>
                                    ))}
                                </div>
                              )}

                              <h3>
                                <a
                                  onClick={event =>
                                    fetchViewDetail(
                                      event,
                                      item.id,
                                      0,
                                      item.title
                                    )
                                  }
                                  className='hotel-card-name'
                                >
                                  {item.title}
                                </a>
                              </h3>
                              <p>{item.content}</p>
                              <div class='d-flex  justify-content-between align-items-center'>
                                <p className='fw-bold mb-0'>Departure From</p>
                                <p className='mb-0'>
                                  {flightDetails === null
                                    ? ''
                                    : flightDetails[0]?.departure_airport_code}
                                </p>
                              </div>
                              <span class='price'>
                                From{' '}
                                <strong>
                                  {item.currency_symbol}{' '}
                                  {item.quad_grand_total_amount !== null
                                    ? item.quad_grand_total_amount
                                    : item.triple_grand_total_amount !== null
                                    ? item.triple_grand_total_amount
                                    : item.double_grand_total_amount !== null
                                    ? item.double_grand_total_amount
                                    : 'N/A'}
                                </strong>
                              </span>
                            </div>
                            <ul>
                              <li>Pax: {item.no_of_pax_days}</li>
                              <li>
                                <div
                                  style={{ cursor: 'pointer' }}
                                  class='score '
                                >
                                  <strong
                                    onClick={event =>
                                      fetchViewDetail(
                                        event,
                                        item.id,
                                        0,
                                        item.title
                                      )
                                    }
                                  >
                                    Book Now
                                  </strong>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PackageDetailCard
