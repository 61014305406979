import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import {
  CustomerDomainName,
  ApiEndPoint,
  ActivityToken
} from '../GlobalData/GlobalData'
import { Tooltip, Whisper } from 'rsuite'
function ActivityCard ({ newActivityListing }) {
  console.log(newActivityListing)
  const [isLoading, setIsLoading] = useState(false)
  var Domainpath = CustomerDomainName()
  var navigation = useNavigate()
  const showDetail = id => {
    navigation(`/activity_details/${id}`)
  }
  return (
    <>
            <div class='isotope-wrapper'>
              {newActivityListing.map((item, index) => (
                <div key={index} class='isotope-item latest'>
                <div class='box_list' data-cue='slideInUp'>
                  <div class='row g-0'>
                    <div class='col-lg-5'>
                      <figure>
                        <small> Activity Hours : {item.duration}</small>
                        <a>
                        <img
                        className='img-fluid'
                        src={
                          Domainpath +
                          '/public/images/activites/' +
                          item.featured_image
                        }
                        alt=''
                      />
                        </a>
                      </figure>
                    </div>
                    <div class='col-lg-7'>
                      <div class='wrapper'>
                        <a class='wish_btn'>
                        Dates: {item.activity_date}
                        </a>
                        {item.starts_rating === '' ? (
                          <div class='cat_star'>No Rating</div>
                        ) : (
                          <div class='cat_star'>
                            {Array(item.starts_rating)
                              .fill(0)
                              .map((_, index) => (
                                <i
                                  style={{ color: '#fa5636' }}
                                  key={index}
                                  className='fa fa-star'
                                >
                                  <FontAwesomeIcon icon={faStar} />
                                </i>
                              ))}
                          </div>
                        )}
    
                        <h3>
                          <a   onClick={() => showDetail(item.id)} className='hotel-card-name'> {item.title}</a>
                        </h3>
                        <p>{item.activity_content ? item.activity_content.slice(0, 300) : ''}</p>
                        <div class='text-success'>
                          <FontAwesomeIcon icon={faMapMarkerAlt} />{' '}
                          {item.location}
                        </div>
                        <span class='price'>
                            From{' '}
                            <strong>
                            {item.currency_symbol} {item.sale_price}
                            </strong>
                          </span>
                      </div>
                      <ul>
                        <li>
                          <div style={{cursor:'pointer'}} class='score '>
                            <strong    onClick={() => showDetail(item.id)}>View Detail</strong>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              ))}
            </div>
    </>
  )
}

export default ActivityCard
