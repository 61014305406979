import React, { useState, useEffect } from 'react'
import FlightDetail from './FlightCard'
import Faqs from '../../Components/FAQs/FAQs'
import Layout from '../../Components/Layout/Layout'
import bgimage from '../../Images/Packages/kabapic.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearch,
  faPhone,
  faMapMarker,
  faPersonCirclePlus,
  faPersonCircleMinus,
  faCalendar,
  faMoon,
  faClipboardCheck,
  faBus,
  faBuildingCircleCheck,
  faPlaneDeparture,
  faPlaneArrival,
  faBed,
  faClock,
  faDollar,
  faHeadphones,
  faStar,
  faLock,
  faMailBulk,
  faEnvelope,
  faCalendarCheck,
  faCalendarMinus,
  faMapMarkerAlt,
  faCalendarAlt
} from '@fortawesome/free-solid-svg-icons'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import kaba from '../../Images/Packages/kabapic.jpg'
import busimage from '../../Images/Logo/bus.jpg'
import { CustomerDomainName } from '../../Components/GlobalData/GlobalData'
import moment from 'moment'
import PackageSearch from '../../Components/SearchBar/PackageSearch'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import ImageGallery from 'react-image-gallery'
function ViewDeail () {
  var url = CustomerDomainName()
  const tourDetail = useSelector(state => state.hotels.viewtourdetail)
  const CurrencyRates = useSelector(state => state.hotels.Currency)
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency)
  const tourlocation = JSON.parse(tourDetail.tours.tour_location)
  const transportationdetail = tourDetail.tours.transportation_details ? JSON.parse(
    tourDetail.tours.transportation_details
  ):[]
  const Itinerarydetail = JSON.parse(tourDetail.tours.Itinerary_details)
  var Itinerarydetailmore = []
  if (tourDetail.tours.tour_itinerary_details_1 !== null) {
    Itinerarydetailmore = JSON.parse(tourDetail.tours.tour_itinerary_details_1)
  }
  const accomodationdetail = JSON.parse(tourDetail.tours.accomodation_details)
  const accomodationdetailmore = JSON.parse(
    tourDetail.tours.accomodation_details_more
  )
  const transportationdetailmore = tourDetail.tours.transportation_details_more
    ? JSON.parse(tourDetail.tours.transportation_details_more)
    : []
  const images = JSON.parse(tourDetail.tours.gallery_images)
  const [opencartmodel, setOpencartmodel] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const [activeIndex, setActiveIndex] = useState(null)
  const [cancelModal, setCancelModal] = useState(false)
  const [minValue, setMinValue] = useState({ price: 0, currency: '' })
  const [modalAdultRoom, setModalAdultRoom] = useState({
    double: '',
    triple: '',
    quad: ''
  })
  const [modalAdultAdult, setModalAdultAdult] = useState({
    double: '',
    triple: '',
    quad: ''
  })
  const [modalAdultTotal, setModalAdultTotal] = useState({
    double: 0,
    triple: 0,
    quad: 0
  })

  const [activeButton, setActiveButton] = useState(null)

  // const handleAdultRoomChange = (event, price) => {
  //   var { name, value } = event.target;

  //   if(name==='double_rooms'){
  //     setModalAdultRoom((prevAdultRoom) => ({
  //       ...prevAdultRoom,
  //      double: value
  //     }));
  //     var adult=value*2;
  //     setModalAdultAdult((prevAdultRoom) => ({
  //       ...prevAdultRoom,
  //       double: adult
  //     }));

  //     var p=adult*price;
  //     setModalAdultTotal((prevAdultRoom) => ({
  //       ...prevAdultRoom,
  //       double: p
  //     }));

  //   }
  // };
  // const handleAdultAdultChange = (event, price) => {
  //   var { name, value } = event.target;

  //   if(name==='double_adult'){
  //     setModalAdultAdult((prevAdultRoom) => ({
  //       ...prevAdultRoom,
  //       double: value
  //     }));

  //     var p=value*price;
  //     setModalAdultTotal((prevAdultRoom) => ({
  //       ...prevAdultRoom,
  //       double: p
  //     }));

  //   }
  // };
  const handleButtonClick = buttonName => {
    setActiveButton(buttonName)
  }
  const show = index => {
    const buttons = document.getElementsByClassName('accordion')
    const panel = buttons[index].nextElementSibling
    buttons[index].classList.toggle('active-2')

    if (panel.style.maxHeight) {
      panel.style.maxHeight = null
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px'
    }

    // Remove "active" class from other buttons
    for (let i = 0; i < buttons.length; i++) {
      if (i !== index) {
        buttons[i].classList.remove('active-2')
        buttons[i].nextElementSibling.style.maxHeight = null
      }
    }
  }
  useEffect(() => {
    calcalutemin()
    // Function to handle scroll events
    const handleScroll = () => {
      const sectionIds = [
        'section-1',
        'section-2',
        'section-3',
        'section-4',
        'section-5',
        'section-6',
        'section-7'
      ]

      // Find the section that is currently in view
      for (const id of sectionIds) {
        const section = document.getElementById(id)
        if (section) {
          const rect = section.getBoundingClientRect()
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            setActiveButton(id)
            break
          }
        }
      }
    }

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll)

    // Clean up the listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const toggleModal2 = () => {
    setCancelModal(!cancelModal)
  }
  const calcalutemin = () => {
    var prices = [
      tourDetail.tours.double_grand_total_amount,
      tourDetail.tours.triple_grand_total_amount,
      tourDetail.tours.quad_grand_total_amount
    ]

    // Filter out any null values
    var validPrices = prices.filter(price => price !== null)

    // Get the minimum value from the valid prices
    var min_value = Math.min(...validPrices)
    if (minValue.price !== min_value) {
      setMinValue({
        price: min_value,
        currency: tourDetail.tours.currency_symbol
      })
    }
  }
  console.log(images)
  return (
    <>
      <Layout>
        <main>
          <section
            class='hero_in '
            style={{ backgroundImage: `url(${bgimage})` }}
          >
            <div class='wrapper'>
              <div class='container'>
                <h1 class='fadeInUp'>
                  <span></span>
                  {tourDetail.tours.title}
                </h1>
              </div>
            </div>
          </section>
        </main>
        <div class='bravo_wrap'>
          <div class='bravo_detail_hotel'>
            <div class='bravo_content'>
              <div class='container mt-2'>
                <div class='row'>
                  <div class='col-md-12 col-lg-8'>
                    <section id='description'>
                      <h2>Description</h2>
                      <p>{tourDetail.tours.content} </p>
                      <div class='g-include-exclude'>
                        <div class='row'>
                          <div class='col-lg-6 col-md-6'>
                            <h3> Included</h3>

                            <div class='item'>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: tourDetail.tours.whats_included
                                }}
                              ></span>
                            </div>
                          </div>
                          <div class='col-lg-6 col-md-6'>
                            <h3>Excluded </h3>

                            <div class='item'>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: tourDetail.tours.whats_excluded
                                }}
                              ></span>{' '}
                            </div>
                          </div>
                        </div>

                        <div class='row'>
                          <div class='col-6 col-md-4 col-sm-6 '>
                            <div class='item'>
                              <h6> Departure Date</h6>
                              <p>
                                <i class='fa tc fa-calendar' aria-hidden='true'>
                                  <FontAwesomeIcon icon={faCalendar} />
                                </i>{' '}
                                {moment(tourDetail.tours.start_date).format(
                                  'DD-MM-YYYY'
                                )}
                              </p>
                            </div>
                          </div>
                          <div class='col-6 col-md-4 col-sm-6 '>
                            <div class='item'>
                              <h6> Return Date</h6>

                              <p>
                                <i class='fa tc fa-calendar' aria-hidden='true'>
                                  <FontAwesomeIcon icon={faCalendar} />
                                </i>{' '}
                                {moment(tourDetail.tours.end_date).format(
                                  'DD-MM-YYYY'
                                )}
                              </p>
                            </div>
                          </div>
                          <div class='col-6 col-md-4 col-sm-6 '>
                            <div class='item'>
                              <h6>Time length</h6>
                              <p>
                                <i class='fa-regular tc fa-moon'>
                                  {' '}
                                  <FontAwesomeIcon icon={faMoon} />
                                </i>{' '}
                                {tourDetail.tours.time_duration} Nights
                              </p>
                            </div>
                          </div>
                         
                          <div class='col-6 col-md-4 col-sm-6 '>
                            <div class='item'>
                              <h6>Transport included</h6>
                              <p>
                                <i class='fa fa-bus tc' aria-hidden='true'>
                                  <FontAwesomeIcon icon={faBus} />
                                </i>
                                {
                                  transportationdetail.length !==0 ? transportationdetail[0]?.transportation_vehicle_type :''
                                }
                              </p>
                            </div>
                          </div>
                          <div class='col-6 col-md-4 col-sm-6 '>
                            <div class='item'>
                              <h6>Destinations</h6>
                              <p title='Makkah, Madina'>
                                <i class='fa-solid tc fa-building-circle-check'>
                                  {' '}
                                  <FontAwesomeIcon
                                    icon={faBuildingCircleCheck}
                                  />
                                </i>
                                {tourlocation.map((location, index) => (
                                  <span key={index}>
                                    {location},
                                    {index !== tourlocation.length - 1
                                      ? ' '
                                      : ''}
                                  </span>
                                ))}
                              </p>
                            </div>
                          </div>
                        </div>

                        {Itinerarydetailmore.length !== 0 && (
                          <section id='section-2'>
                            <h4 className='mt-3 view-detail-heading'>
                              Itinerary Schedule
                            </h4>
                            <button
                              className={`accordion  ${
                                activeIndex === 0 ? 'active' : ''
                              }`}
                              onClick={() => show(0)}
                            >
                              {Itinerarydetail[0].Itinerary_title}:{' '}
                              {Itinerarydetail[0].Itinerary_content}
                            </button>
                            <div className='panel'>
                              <p>{Itinerarydetail[0].Itinerary_city}</p>
                            </div>
                            {Itinerarydetailmore.map((item, index) => (
                              <div key={index}>
                                <button
                                  className={`accordion  ${
                                    activeIndex === index + 1 ? 'active' : ''
                                  }`}
                                  onClick={() => show(index + 1)}
                                >
                                  {item.more_Itinerary_title} :{' '}
                                  {item.more_Itinerary_city}
                                </button>
                                <div class='panel'>
                                  <p>{item.more_Itinerary_content}</p>
                                </div>
                              </div>
                            ))}
                          </section>
                        )}
                      </div>
                      <div style={{ width: '100%' }}>
                        <ImageGallery
                          showFullscreenButton={true}
                          items={images.map(image => ({
                            original:
                              url + 'public/uploads/package_imgs/' + image, // large image
                            thumbnail:
                              url + 'public/uploads/package_imgs/' + image
                          }))}
                        />
                      </div>
                      <div class='g-include-exclude mt-4'>
                        <h3> Flight Details</h3>
                        <FlightDetail />
                      </div>
                      <section id='section-4' className='hotel-detail-padding'>
                        {accomodationdetail.map((item, index) => {
                          const hotels = accomodationdetailmore.filter(
                            x =>
                              x.more_hotel_city.toUpperCase() ===
                              item.hotel_city_name.toUpperCase()
                          )

                          return (
                            <div key={index} class='hotel-details-top'>
                              <h4 className='view-detail-heading mt-1 pt-1'>
                                {item.hotel_city_name} Hotel Details
                              </h4>
                              <div class='row mt-4'>
                                <div class='col-sm-12 col-md-3'>
                                  <img
                                  className='package-acc-img'
                                    src={
                                      url +
                                      '/public/uploads/package_imgs/' +
                                      item.accomodation_image[0]
                                    }
                                    alt=''
                                  />
                                </div>
                                <div class='col-sm-12 col-md-5'>
                                  <h5>{item.acc_hotel_name}</h5>
                                  <ul>
                                    <li className='mt-1'>
                                      <i class='fa-solid fa-bed'>
                                        <FontAwesomeIcon icon={faBed} />
                                      </i>{' '}
                                      Room Type: {item.acc_type}
                                      {hotels.map((a, index) => (
                                        <span key={index}>
                                          /{a.more_acc_type}{' '}
                                        </span>
                                      ))}
                                      <span></span>
                                    </li>
                                    <li className='mt-1'>
                                      {' '}
                                      <i class='fa-solid fa-bed'>
                                        <FontAwesomeIcon
                                          icon={faCalendarCheck}
                                        />
                                      </i>{' '}
                                      Check In :{' '}
                                      {moment(item.acc_check_in).format(
                                        'DD-MM-YYYY'
                                      )}
                                    </li>
                                    <li className='mt-1'>
                                      {' '}
                                      <i class='fa-solid fa-bed'>
                                        <FontAwesomeIcon
                                          icon={faCalendarMinus}
                                        />
                                      </i>{' '}
                                      Check Out :{' '}
                                      {moment(item.acc_check_out).format(
                                        'DD-MM-YYYY'
                                      )}
                                    </li>
                                    <li className='mt-1'>
                                      {' '}
                                      <i class='fa-solid fa-bed'>
                                        <FontAwesomeIcon icon={faMoon} />
                                      </i>{' '}
                                      No Of Nights : {item.acc_no_of_nightst}
                                    </li>
                                  </ul>
                                </div>
                                <div class='col-sm-12 col-md-4'>
                                  <h5>Room Amenities </h5>
                                  <p>{item.hotel_whats_included}</p>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </section>
                      <section id='section-5'>
                        <h4 className='view-detail-heading '>
                          Transfer Details
                        </h4>
                        <div className='row mt-5'>
                          <div className='col-sm-6 col-md-3 text-center'>
                            <div class='image-thumb'>
                              <img src={busimage} alt='' height='100' />
                            </div>
                            <div class='tr-vehicle-name'>
                              <span>
                                Vehicle:{' '}
                                {
                                     transportationdetail.length !==0 ? transportationdetail[0]?.transportation_vehicle_type :''
                                }
                              </span>
                            </div>
                          </div>
                          <div className='col-sm-6 col-md-3 text-center item-from'>
                            <div class=''>
                              <h3 className='tc' style={{ fontSize: '1.1rem' }}>
                                Pickup Location
                              </h3>
                              <h6 style={{ fontSize: '1rem' }}>
                                {
                                     transportationdetail.length !==0 ? transportationdetail[0]?.transportation_pick_up_location :''
                                }
                              </h6>
                              <h6 style={{ fontSize: '.8rem' }}>
                                Pickup Date :{' '}
                                { transportationdetail.length !==0 ? moment(
                                  transportationdetail[0]
                                    ?.transportation_pick_up_date
                                ).format('DD-MM-YYYY') :''}{' '}
                              </h6>
                              <h6 style={{ fontSize: '.8rem' }}>
                                Pickup Date :{' '}
                                { transportationdetail.length !==0 ? moment(
                                  transportationdetail[0]
                                    ?.transportation_pick_up_date
                                ).format('LT'):''}{' '}
                              </h6>
                            </div>
                          </div>
                          <div className='col-sm-6 col-md-3 item-from'>
                            <div class='item-time'>
                              <i class='fa fa-clock-o'>
                                <FontAwesomeIcon icon={faClock} />
                              </i>
                              <span>
                                {
                                  transportationdetail.length !==0 ?
                                  transportationdetail[0]
                                    ?.transportation_total_Time :''
                                }
                              </span>
                            </div>
                          </div>
                          <div className='col-sm-6 col-md-3 text-center item-from'>
                            <div class=''>
                              <h3 className='tc' style={{ fontSize: '1.1rem' }}>
                                Drop off Location
                              </h3>
                              <h6 style={{ fontSize: '1rem' }}>
                                {
                                  transportationdetail.length !==0 ?
                                  transportationdetail[0]
                                    ?.transportation_drop_off_location:''
                                }
                              </h6>
                              <h6 style={{ fontSize: '.8rem' }}>
                                Drop off Date :{' '}
                                {transportationdetail.length !==0 ? moment(
                                  transportationdetail[0]
                                    ?.transportation_drop_of_date
                                ).format('DD-MM-YYYY'):''}{' '}
                              </h6>
                              <h6 style={{ fontSize: '.8rem' }}>
                                Drop off Time :{' '}
                                {transportationdetail.length !==0 ? moment(
                                  transportationdetail[0]
                                    ?.transportation_drop_of_date
                                ).format('LT'):''}{' '}
                              </h6>
                            </div>
                          </div>
                        </div>
                        {transportationdetailmore.map((item, index) => (
                          <div
                            key={index}
                            className='row  mt-4'
                            style={{ borderTop: '2px solid #8080804d' }}
                          >
                            <div className='col-sm-6 col-md-3 text-center'>
                              <div class='image-thumb'>
                                <img
                                  src='https://demo2.synchronousdigital.com/public/admin_package/frontend/images/bus.jpg'
                                  alt=''
                                  height='100'
                                />
                              </div>
                              <div class='tr-vehicle-name'>
                                <span>
                                  Vehicle:{' '}
                                  {
                                    transportationdetail.length !==0 ?
                                    transportationdetail[0]
                                      ?.transportation_vehicle_type:''
                                  }
                                </span>
                              </div>
                            </div>
                            <div className='col-sm-6 col-md-3 text-center item-from'>
                              <div class=''>
                                <h3
                                  className='tc'
                                  style={{ fontSize: '1.1rem' }}
                                >
                                  Pickup Location
                                </h3>
                                <h6 style={{ fontSize: '1rem' }}>
                                  {item.more_transportation_pick_up_location}
                                </h6>
                                <h6 style={{ fontSize: '.8rem' }}>
                                  Pickup Date :{' '}
                                  {moment(
                                    item.more_transportation_pick_up_date
                                  ).format('DD-MM-YYYY')}{' '}
                                </h6>
                                <h6 style={{ fontSize: '.8rem' }}>
                                  Pickup Date :{' '}
                                  {moment(
                                    item.more_transportation_pick_up_date
                                  ).format('LT')}{' '}
                                </h6>
                              </div>
                            </div>
                            <div className='col-sm-6 col-md-3 item-from'>
                              <div class='item-time'>
                                <i class='fa fa-clock-o'>
                                  <FontAwesomeIcon icon={faClock} />
                                </i>
                                <span>
                                  {item.more_transportation_total_Time}
                                </span>
                              </div>
                            </div>
                            <div className='col-sm-6 col-md-3 text-center item-from'>
                              <div class=''>
                                <h3
                                  className='tc'
                                  style={{ fontSize: '1.1rem' }}
                                >
                                  Drop off Location
                                </h3>
                                <h6 style={{ fontSize: '1rem' }}>
                                  {item.more_transportation_drop_off_location}
                                </h6>
                                <h6 style={{ fontSize: '.8rem' }}>
                                  Drop off Date :{' '}
                                  {moment(
                                    item.more_transportation_drop_of_date
                                  ).format('DD-MM-YYYY')}{' '}
                                </h6>
                                <h6 style={{ fontSize: '.8rem' }}>
                                  Drop off Time :{' '}
                                  {moment(
                                    item.more_transportation_drop_of_date
                                  ).format('LT')}{' '}
                                </h6>
                              </div>
                            </div>
                          </div>
                        ))}
                      </section>
                      <section id='section-6'>
                        <h4 className='view-detail-heading '>Visa Details</h4>
                        <div className='row mt-5'>
                          <div className='col-sm-6 col-4 col-md-3 text-center'>
                            <div class='image-thumb'>
                              <img
                                src={
                                  url +
                                  '/public/uploads/package_imgs/' +
                                  tourDetail.tours.visa_image
                                }
                                alt=''
                                height='100'
                              />
                            </div>
                          </div>
                          <div className='col-sm-6 col-8 col-md-3 text-center item-from'>
                            <div class=''>
                              <h6
                                style={{
                                  fontSize: '1.1rem',
                                  fontWeight: 'bold'
                                }}
                              >
                                Visa Type
                              </h6>
                              <h6 style={{ fontSize: '1rem', color: 'gray' }}>
                                {tourDetail.tours.visa_type}
                              </h6>
                            </div>
                          </div>
                          <div className='col-sm-12 col-8 col-md-6 text-justify item-from'>
                            <h6
                              style={{ fontSize: '1.1rem', fontWeight: 'bold' }}
                            >
                              Visa Requirements
                            </h6>
                            <h6 style={{ fontSize: '.8rem', color: 'gray' }}>
                              {/* {tourDetail.tours.visa_detials} */}
                            </h6>
                          </div>
                        </div>
                      </section>
                    </section>
                  </div>
                  <div class='col-md-12 col-lg-4'>
                    <div class='box_detail booking theiaStickySidebar'>
                      <div class='bravo_single_book'>
                        <div id='bravo_tour_book_app' v-cloak>
                          <div class='form-head'>
                            <div class='d-flex justify-content-between mt-2'>
                              <div>
                                <h6 class='card-title'>Double Price:</h6>
                              </div>
                              <h6>
                                {' '}
                                {tourDetail.tours.currency_symbol}{' '}
                                {tourDetail.tours.double_grand_total_amount}
                              </h6>
                            </div>
                            <div class='d-flex justify-content-between mt-2'>
                              <div>
                                <h6 class='card-title'>Triple Price:</h6>
                              </div>
                              <h6>
                                {' '}
                                {tourDetail.tours.currency_symbol}{' '}
                                {tourDetail.tours.triple_grand_total_amount}
                              </h6>
                            </div>
                            <div class='d-flex justify-content-between mt-2'>
                              <div>
                                <h6 class='card-title'>Quad Price:</h6>
                              </div>
                              <h6>
                                {' '}
                                {tourDetail.tours.currency_symbol}{' '}
                                {tourDetail.tours.quad_grand_total_amount}
                              </h6>
                            </div>
                            <div className='mt-2'>
                              <a
                                onClick={toggleModal2}
                                className='activity_booking-Box'
                              >
                                <FontAwesomeIcon icon={faCalendarAlt} />{' '}
                                Cancellation Policy ?
                              </a>
                              <NavLink to='/book_package'>
                                <a class='btn_1 full-width purchase mt-2'>
                                  Add To Cart
                                </a>
                                
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <Modal isOpen={cancelModal} toggle={toggleModal2}>
        <ModalHeader toggle={toggleModal2}> Cancellation Policy</ModalHeader>
        <ModalBody>
          <div className='form-group'>
            <p>{tourDetail.tours.cancellation_policy}</p>
          </div>
        </ModalBody>
      </Modal>
      <div id='mybutton'>
        <NavLink to='/book_package'>
          <button class='btn feedback  select-styling search-btn1 mt-2 detail-view-btn'>
            Add to Cart
          </button>
        </NavLink>
      </div>
    </>
  )
}

export default ViewDeail
