export function Hotelapitoken(){
    return 'ysILuKErFyKw5OHLnAfEaP0Iw112z1NULh8b9pTv-34ETV0iG4qWN67wKAZI6sLRsyvdPs9PyIE5FFgsV1eWjginJtk9O7CKQMolN-JuaQbuuTLR9DvaVj5dQyr7PZu-UcLvTrGOWkLKsMZODc6y0GTbMmJtBk8K6dBNKUjWcYDFibIdqUa4T5Bm2uIEiN09cC6t4xF9NPK-Frz1jH5bAvrmxfQbDjqB0zwkqb4F9WSTUQDf0pL6Lp09k18SOdo3cjHRdKBpWWp6tcwYlBioxoqNCPSC9dGxMRTp6evvnAyg9J9o-OAM1UFBqRiOQpYFvftWDFo5f234JmqYSgjiyFHhUoO60BdX6aW6yTaNRyH3vN6wTrlL8JNDhC3w-P5WcFQ7fVmnI4kwyE2fPyrF6z-tBgb42O64DMCjlCsgYmXCogoeEARwigqeo4R74SAaVNGuVZacm-L5Rw2q50INr7Tm9VdXWytWkt02YxzMNB8xvbEyjnNo8LZb3hFk'
};

export function CustomerDomainName(){
    return 'https://admin.dariaztravel.co.uk/'
};
export function FlightSearchToken(){
    return 'ysILuKErFyKw5OHLnAfEaP0Iw112z1NULh8b9pTv-34ETV0iG4qWN67wKAZI6sLRsyvdPs9PyIE5FFgsV1eWjginJtk9O7CKQMolN-JuaQbuuTLR9DvaVj5dQyr7PZu-UcLvTrGOWkLKsMZODc6y0GTbMmJtBk8K6dBNKUjWcYDFibIdqUa4T5Bm2uIEiN09cC6t4xF9NPK-Frz1jH5bAvrmxfQbDjqB0zwkqb4F9WSTUQDf0pL6Lp09k18SOdo3cjHRdKBpWWp6tcwYlBioxoqNCPSC9dGxMRTp6evvnAyg9J9o-OAM1UFBqRiOQpYFvftWDFo5f234JmqYSgjiyFHhUoO60BdX6aW6yTaNRyH3vN6wTrlL8JNDhC3w-P5WcFQ7fVmnI4kwyE2fPyrF6z-tBgb42O64DMCjlCsgYmXCogoeEARwigqeo4R74SAaVNGuVZacm-L5Rw2q50INr7Tm9VdXWytWkt02YxzMNB8xvbEyjnNo8LZb3hFk'
};

export function ApiEndPoint(){
    return 'https://api.synchtravel.com'
    
};
export function TransferApiToken(){
    return 'ysILuKErFyKw5OHLnAfEaP0Iw112z1NULh8b9pTv-34ETV0iG4qWN67wKAZI6sLRsyvdPs9PyIE5FFgsV1eWjginJtk9O7CKQMolN-JuaQbuuTLR9DvaVj5dQyr7PZu-UcLvTrGOWkLKsMZODc6y0GTbMmJtBk8K6dBNKUjWcYDFibIdqUa4T5Bm2uIEiN09cC6t4xF9NPK-Frz1jH5bAvrmxfQbDjqB0zwkqb4F9WSTUQDf0pL6Lp09k18SOdo3cjHRdKBpWWp6tcwYlBioxoqNCPSC9dGxMRTp6evvnAyg9J9o-OAM1UFBqRiOQpYFvftWDFo5f234JmqYSgjiyFHhUoO60BdX6aW6yTaNRyH3vN6wTrlL8JNDhC3w-P5WcFQ7fVmnI4kwyE2fPyrF6z-tBgb42O64DMCjlCsgYmXCogoeEARwigqeo4R74SAaVNGuVZacm-L5Rw2q50INr7Tm9VdXWytWkt02YxzMNB8xvbEyjnNo8LZb3hFk'
};
export function NewTransferSearchToken(){
    return 'ysILuKErFyKw5OHLnAfEaP0Iw112z1NULh8b9pTv-34ETV0iG4qWN67wKAZI6sLRsyvdPs9PyIE5FFgsV1eWjginJtk9O7CKQMolN-JuaQbuuTLR9DvaVj5dQyr7PZu-UcLvTrGOWkLKsMZODc6y0GTbMmJtBk8K6dBNKUjWcYDFibIdqUa4T5Bm2uIEiN09cC6t4xF9NPK-Frz1jH5bAvrmxfQbDjqB0zwkqb4F9WSTUQDf0pL6Lp09k18SOdo3cjHRdKBpWWp6tcwYlBioxoqNCPSC9dGxMRTp6evvnAyg9J9o-OAM1UFBqRiOQpYFvftWDFo5f234JmqYSgjiyFHhUoO60BdX6aW6yTaNRyH3vN6wTrlL8JNDhC3w-P5WcFQ7fVmnI4kwyE2fPyrF6z-tBgb42O64DMCjlCsgYmXCogoeEARwigqeo4R74SAaVNGuVZacm-L5Rw2q50INr7Tm9VdXWytWkt02YxzMNB8xvbEyjnNo8LZb3hFk'
};
export function TransferCheckoutToken(){
    return 'ysILuKErFyKw5OHLnAfEaP0Iw112z1NULh8b9pTv-34ETV0iG4qWN67wKAZI6sLRsyvdPs9PyIE5FFgsV1eWjginJtk9O7CKQMolN-JuaQbuuTLR9DvaVj5dQyr7PZu-UcLvTrGOWkLKsMZODc6y0GTbMmJtBk8K6dBNKUjWcYDFibIdqUa4T5Bm2uIEiN09cC6t4xF9NPK-Frz1jH5bAvrmxfQbDjqB0zwkqb4F9WSTUQDf0pL6Lp09k18SOdo3cjHRdKBpWWp6tcwYlBioxoqNCPSC9dGxMRTp6evvnAyg9J9o-OAM1UFBqRiOQpYFvftWDFo5f234JmqYSgjiyFHhUoO60BdX6aW6yTaNRyH3vN6wTrlL8JNDhC3w-P5WcFQ7fVmnI4kwyE2fPyrF6z-tBgb42O64DMCjlCsgYmXCogoeEARwigqeo4R74SAaVNGuVZacm-L5Rw2q50INr7Tm9VdXWytWkt02YxzMNB8xvbEyjnNo8LZb3hFk'
};

export function CurrencyConverter(){
    return 'b8d4b285d48d1e690388e999'
};
export function StripeClientSecret(){
    // return 'sk_test_51N9pi8GwYlRApJ0wzqUOVIJBpCNJ1Lz7UcUjJoSdMU73WAVzoTXSJ1gfakCYThPWhnzWlKnLLQXugdB0s8dBFDJ500Beg22CmF'
    return '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'
};
export function ActivityToken(){
    return 'ysILuKErFyKw5OHLnAfEaP0Iw112z1NULh8b9pTv-34ETV0iG4qWN67wKAZI6sLRsyvdPs9PyIE5FFgsV1eWjginJtk9O7CKQMolN-JuaQbuuTLR9DvaVj5dQyr7PZu-UcLvTrGOWkLKsMZODc6y0GTbMmJtBk8K6dBNKUjWcYDFibIdqUa4T5Bm2uIEiN09cC6t4xF9NPK-Frz1jH5bAvrmxfQbDjqB0zwkqb4F9WSTUQDf0pL6Lp09k18SOdo3cjHRdKBpWWp6tcwYlBioxoqNCPSC9dGxMRTp6evvnAyg9J9o-OAM1UFBqRiOQpYFvftWDFo5f234JmqYSgjiyFHhUoO60BdX6aW6yTaNRyH3vN6wTrlL8JNDhC3w-P5WcFQ7fVmnI4kwyE2fPyrF6z-tBgb42O64DMCjlCsgYmXCogoeEARwigqeo4R74SAaVNGuVZacm-L5Rw2q50INr7Tm9VdXWytWkt02YxzMNB8xvbEyjnNo8LZb3hFk'
};
export function HajjApiToken(){
    return 'ysILuKErFyKw5OHLnAfEaP0Iw112z1NULh8b9pTv-34ETV0iG4qWN67wKAZI6sLRsyvdPs9PyIE5FFgsV1eWjginJtk9O7CKQMolN-JuaQbuuTLR9DvaVj5dQyr7PZu-UcLvTrGOWkLKsMZODc6y0GTbMmJtBk8K6dBNKUjWcYDFibIdqUa4T5Bm2uIEiN09cC6t4xF9NPK-Frz1jH5bAvrmxfQbDjqB0zwkqb4F9WSTUQDf0pL6Lp09k18SOdo3cjHRdKBpWWp6tcwYlBioxoqNCPSC9dGxMRTp6evvnAyg9J9o-OAM1UFBqRiOQpYFvftWDFo5f234JmqYSgjiyFHhUoO60BdX6aW6yTaNRyH3vN6wTrlL8JNDhC3w-P5WcFQ7fVmnI4kwyE2fPyrF6z-tBgb42O64DMCjlCsgYmXCogoeEARwigqeo4R74SAaVNGuVZacm-L5Rw2q50INr7Tm9VdXWytWkt02YxzMNB8xvbEyjnNo8LZb3hFk'
};


// b8d4b285d48d1e690388e999 with 30k hits
//32ce58d166a15bca2a55cd7a testing
// 8885028c6faaef9cb5bc397b new