import React from 'react'
function Googlemap () {
  return (
    <>
      <div className='container'>
        <div className='col-12'>
          <div className='responsive-map'>
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d836.8376821685525!2d-1.6723967!3d53.7070034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bdfebbd485337%3A0xbaac738436d1efef!2sDAR%20RIAZ%20TRAVEL%20LTD!5e0!3m2!1sen!2suk!4v1695647338296!5m2!1sen!2suk'
              width='100%'
              height='450'
              frameborder='0'
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </>
  )
}

export default Googlemap
