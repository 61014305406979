import React from 'react';
import gif from '../../Images/loader.gif'
const Loading = ({status}) => {
  return (
    <div id="preloader">
      {/* <div data-loader="circle-side"></div> */}
      <img height='320' width='350' src={gif}/>
      </div>
  );
};

export default Loading;
