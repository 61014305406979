import React from 'react'
import Layout from '../../Components/Layout/Layout'
import bgimage from '../../Images/bg1.jpg'
function Guides () {
  return (
    <>
      <Layout>
        <div class='fluid-container'>
          <div class='image-container'>
            <img
              src={bgimage}
              alt='Background Image'
              style={{ height: 'auto' }}
            />
            <div class='overlay-text'>Our Expert Scholors & Guides</div>
          </div>
        </div>
        <div className='mt-5 mb-3'>
          <div className='section-title mt-5'>
              <h2 className=' wow animate__animated animate__fadeInUp'  data-wow-duration="1.5s" data-wow-delay="0.3s">
                 Religious<span> Scholors </span>
              </h2>
          </div>
        </div>
        <div className='p-3'>
          <div className='row '>

            <div className='col-lg-3 col-md-6 col-sm-6 mt-4'>
              <div className='card card-5 p-3 py-4'>
                <div className='text-center clients card__border'>
                  <center>
                    <div class='rounded-circle guide_img guide-1'></div>
                  </center>
                </div>
                <div class='text-center mt-3'>
                  <div class='seven'>
                    <h5 class='animate__animated animate__delay-1s animate__fadeInUp'>
                      Sheikh Adan qanyare
                    </h5>
                    <h6 className='mb-2 mt-2'>
                      <font>
                        <font>Languages </font>
                      </font>
                    </h6>
                    <span
                      title='English'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>English</font>
                      </font>
                    </span>
                    <span
                      title='Urdu'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Dutch</font>
                      </font>
                    </span>
                    <span
                      title='Arabic'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Arabic</font>
                      </font>
                    </span>
                    <span
                      title='Punjabi'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Swahili</font>
                      </font>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6 mt-4'>
              <div className='card card-5 p-3 py-4'>
                <div className='text-center clients card__border'>
                  <center>
                    <div class='rounded-circle guide_img guide-2'></div>
                  </center>
                </div>
                <div class='text-center mt-3'>
                  <div class='seven'>
                    <h5 class='animate__animated animate__delay-1s animate__fadeInUp'>
                      Sheikh Sadaqat Hussain
                    </h5>
                    <h6 className='mb-2 mt-2'>
                      <font>
                        <font>Languages </font>
                      </font>
                    </h6>
                    <span
                      title='English'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>English</font>
                      </font>
                    </span>
                    <span
                      title='Urdu'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Urdu</font>
                      </font>
                    </span>
                    <span
                      title='Arabic'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Arabic</font>
                      </font>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6 mt-4'>
              <div className='card card-5 p-3 py-4'>
                <div className='text-center clients card__border'>
                  <center>
                    <div class='rounded-circle guide_img guide-6'></div>
                  </center>
                </div>
                <div class='text-center mt-3'>
                  <div class='seven'>
                    <h5 class='animate__animated animate__delay-1s animate__fadeInUp'>
                      Imam Bilal Asif
                    </h5>
                    <h6 className='mb-2 mt-2'>
                      <font>
                        <font>Languages </font>
                      </font>
                    </h6>
                    <span
                      title='English'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>English</font>
                      </font>
                    </span>
                    <span
                      title='Urdu'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Urdu</font>
                      </font>
                    </span>
                    <span
                      title=' Arabic'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font> Arabic</font>
                      </font>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6 mt-4'>
              <div className='card card-5 p-3 py-4'>
                <div className='text-center clients card__border'>
                  <center>
                    <div class='rounded-circle guide_img guide-8'></div>
                  </center>
                </div>
                <div class='text-center mt-3'>
                  <div class='seven'>
                    <h5 class='animate__animated animate__delay-1s animate__fadeInUp'>
                      Mufti Haroon Rashid Ebrahim
                    </h5>
                    <h6 className='mb-2 mt-2'>
                      <font>
                        <font>Languages </font>
                      </font>
                    </h6>
                    <span
                      title='English'
                      class='btn btn-secondary btn-sm  btn-lang'
                    >
                      <font>
                        <font>English</font>
                      </font>
                    </span>
                    <span
                      title='Urdu'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Urdu</font>
                      </font>
                    </span>
                    <span
                      title='Hindi '
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font> Hindi </font>
                      </font>
                    </span>
                    <span
                      title='Gujarati '
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font> Gujarati </font>
                      </font>
                    </span>
                    <span
                      title='Arabic  '
                      class='btn btn-secondary btn-sm  btn-lang'
                    >
                      <font>
                        <font> Arabic </font>
                      </font>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6 mt-4'>
              <div className='card card-5 p-3 py-4'>
                <div className='text-center clients card__border'>
                  <center>
                    <div class='rounded-circle guide_img guide-9'></div>
                  </center>
                </div>
                <div class='text-center mt-3'>
                  <div class='seven'>
                    <h5 class='animate__animated animate__delay-1s animate__fadeInUp'>
                      Sheikh Ghulam Hamza
                    </h5>
                    <h6 className='mb-2 mt-2'>
                      <font>
                        <font>Languages </font>
                      </font>
                    </h6>
                    <span
                      title='English'
                      class='btn btn-secondary btn-sm  btn-lang'
                    >
                      <font>
                        <font>English</font>
                      </font>
                    </span>
                    <span
                      title='Urdu'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Urdu</font>
                      </font>
                    </span>
                    <span
                      title='Arabic  '
                      class='btn btn-secondary btn-sm  btn-lang'
                    >
                      <font>
                        <font> Arabic </font>
                      </font>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6 mt-4'>
              <div className='card card-5 p-3 py-4'>
                <div className='text-center clients card__border'>
                  <center>
                    <div class='rounded-circle guide_img guide-10'></div>
                  </center>
                </div>
                <div class='text-center mt-3'>
                  <div class='seven'>
                    <h5 class='animate__animated animate__delay-1s animate__fadeInUp'>
                      Qari Saqawat Taj
                    </h5>
                    <h6 className='mb-2 mt-2'>
                      <font>
                        <font>Languages </font>
                      </font>
                    </h6>
                    <span
                      title='English'
                      class='btn btn-secondary btn-sm  btn-lang'
                    >
                      <font>
                        <font>English</font>
                      </font>
                    </span>
                    <span
                      title='Urdu'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Urdu</font>
                      </font>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6 mt-4'>
              <div className='card card-5 p-3 py-4'>
                <div className='text-center clients card__border'>
                  <center>
                    <div class='rounded-circle guide_img guide-11'></div>
                  </center>
                </div>
                <div class='text-center mt-3'>
                  <div class='seven'>
                    <h5 class='animate__animated animate__delay-1s animate__fadeInUp'>
                      Maulana sajid Ali Khan
                    </h5>
                    <h6 className='mb-2 mt-2'>
                      <font>
                        <font>Languages </font>
                      </font>
                    </h6>
                    <span
                      title='English'
                      class='btn btn-secondary btn-sm  btn-lang'
                    >
                      <font>
                        <font>English</font>
                      </font>
                    </span>
                    <span
                      title='Urdu'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Urdu</font>
                      </font>
                    </span>
                  
                    <span
                      title='Arabic '
                      class='btn btn-secondary btn-sm  btn-lang'
                    >
                      <font>
                        <font> Arabic </font>
                      </font>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6 mt-4'>
              <div className='card card-5 p-3 py-4'>
                <div className='text-center clients card__border'>
                  <center>
                    <div class='rounded-circle guide_img guide-12'></div>
                  </center>
                </div>
                <div class='text-center mt-3'>
                  <div class='seven'>
                    <h5 class='animate__animated animate__delay-1s animate__fadeInUp'>
                      Maulana Hafiz Mohammed Hassan
                    </h5>
                    <h6 className='mb-2 mt-2'>
                      <font>
                        <font>Languages </font>
                      </font>
                    </h6>
                    <span
                      title='English'
                      class='btn btn-secondary btn-sm  btn-lang'
                    >
                      <font>
                        <font>English</font>
                      </font>
                    </span>
                    <span
                      title='Urdu'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Urdu</font>
                      </font>
                    </span>
                    <span
                      title=' hinko '
                      class='btn btn-secondary btn-sm  btn-lang'
                    >
                      <font>
                        <font> Hinko </font>
                      </font>
                    </span>
                    <span
                      title='Arabic '
                      class='btn btn-secondary btn-sm  btn-lang'
                    >
                      <font>
                        <font> Arabic </font>
                      </font>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6 mt-4'>
              <div className='card card-5 p-3 py-4'>
                <div className='text-center clients card__border'>
                  <center>
                    <div class='rounded-circle guide_img guide-13'></div>
                  </center>
                </div>
                <div class='text-center mt-3'>
                  <div class='seven'>
                    <h5 class='animate__animated animate__delay-1s animate__fadeInUp'>
                      Sheikh Anis Ahmed
                    </h5>
                    <h6 className='mb-2 mt-2'>
                      <font>
                        <font>Languages </font>
                      </font>
                    </h6>
                    <span
                      title='English'
                      class='btn btn-secondary btn-sm  btn-lang'
                    >
                      <font>
                        <font>English</font>
                      </font>
                    </span>
                    <span
                      title='Urdu'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Urdu</font>
                      </font>
                    </span>
                    <span
                      title=' Punjabi '
                      class='btn btn-secondary btn-sm  btn-lang'
                    >
                      <font>
                        <font> Punjabi </font>
                      </font>
                    </span>
                    <span
                      title='Arabic '
                      class='btn btn-secondary btn-sm  btn-lang'
                    >
                      <font>
                        <font> Arabic </font>
                      </font>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6 mt-4'>
              <div className='card card-5 p-3 py-4'>
                <div className='text-center clients card__border'>
                  <center>
                    <div class='rounded-circle guide_img guide-14'></div>
                  </center>
                </div>
                <div class='text-center mt-3'>
                  <div class='seven'>
                    <h5 class='animate__animated animate__delay-1s animate__fadeInUp'>
                      Mufti Syed Shamsul Arifeen
                    </h5>
                    <h6 className='mb-2 mt-2'>
                      <font>
                        <font>Languages </font>
                      </font>
                    </h6>
                    <span
                      title='English'
                      class='btn btn-secondary btn-sm  btn-lang'
                    >
                      <font>
                        <font>English</font>
                      </font>
                    </span>
                    <span
                      title='Urdu'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Urdu</font>
                      </font>
                    </span>
                    <span
                      title='Arabic '
                      class='btn btn-secondary btn-sm  btn-lang'
                    >
                      <font>
                        <font> Arabic </font>
                      </font>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6 mt-4'>
              <div className='card card-5 p-3 py-4'>
                <div className='text-center clients card__border'>
                  <center>
                    <div class='rounded-circle guide_img guide-22'></div>
                  </center>
                </div>
                <div class='text-center mt-3'>
                  <div class='seven'>
                    <h5 class='animate__animated animate__delay-1s animate__fadeInUp'>
                      Maulana Mohammed Kaleem
                    </h5>
                    <h6 className='mb-2 mt-2'>
                      <font>
                        <font>Languages </font>
                      </font>
                    </h6>
                    <span
                      title='English'
                      class='btn btn-secondary btn-sm  btn-lang'
                    >
                      <font>
                        <font>English</font>
                      </font>
                    </span>
                    <span
                      title='Urdu'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Urdu</font>
                      </font>
                    </span>
                    <span
                      title='Punjabi '
                      class='btn btn-secondary btn-sm  btn-lang'
                    >
                      <font>
                        <font> Punjabi </font>
                      </font>
                    </span>
                    <span
                      title='Arabic '
                      class='btn btn-secondary btn-sm  btn-lang'
                    >
                      <font>
                        <font> Arabic </font>
                      </font>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6 mt-4'>
              <div className='card card-5 p-3 py-4'>
                <div className='text-center clients card__border'>
                  <center>
                    <div class='rounded-circle guide_img guide-25'></div>
                  </center>
                </div>
                <div class='text-center mt-3'>
                  <div class='seven'>
                    <h5 class='animate__animated animate__delay-1s animate__fadeInUp'>
                      Ustadh Abu Adam Akhlaq Al Azhari
                    </h5>
                    <h6 className='mb-2 mt-2'>
                      <font>
                        <font>Languages </font>
                      </font>
                    </h6>
                    <span
                      title='English'
                      class='btn btn-secondary btn-sm  btn-lang'
                    >
                      <font>
                        <font>English</font>
                      </font>
                    </span>
                    <span
                      title='Urdu'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Urdu</font>
                      </font>
                    </span>
                    <span
                      title='Arabic '
                      class='btn btn-secondary btn-sm  btn-lang'
                    >
                      <font>
                        <font> Arabic </font>
                      </font>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div className='mt-5 mb-3'>
          <div className='section-title mt-5'>
              <h2 className=' wow animate__animated animate__fadeInUp'  data-wow-duration="1.5s" data-wow-delay="0.3s">
              Our<span> Guides </span>
              </h2>
          </div>
        </div>
        <div className='p-3'>
          <div className='row '>

          <div className='col-lg-3 col-md-6 col-sm-6 mt-4'>
              <div className='card card-5 p-3 py-4'>
                <div className='text-center clients card__border'>
                  <center>
                    <div class='rounded-circle guide_img guide-18'></div>
                  </center>
                </div>
                <div class='text-center mt-3'>
                  <div class='seven'>
                    <h5 class='animate__animated animate__delay-1s animate__fadeInUp'>
                      Mohammed Zahid Aslam
                    </h5>
                    <h6 className='mb-2 mt-2'>
                      <font>
                        <font>Languages </font>
                      </font>
                    </h6>
                    <span
                      title='English'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>English</font>
                      </font>
                    </span>
                    <span
                      title='Urdu'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Urdu</font>
                      </font>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6 mt-4'>
              <div className='card card-5 p-3 py-4'>
                <div className='text-center clients card__border'>
                  <center>
                    <div class='rounded-circle guide_img guide-3'></div>
                  </center>
                </div>
                <div class='text-center mt-3'>
                  <div class='seven'>
                    <h5 class='animate__animated animate__delay-1s animate__fadeInUp'>
                      Tariq Hussain
                    </h5>
                    <h6 className='mb-2 mt-2'>
                      <font>
                        <font>Languages </font>
                      </font>
                    </h6>
                    <span
                      title='English'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>English</font>
                      </font>
                    </span>
                    <span
                      title='Urdu'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Urdu</font>
                      </font>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6 mt-4'>
              <div className='card card-5 p-3 py-4'>
                <div className='text-center clients card__border'>
                  <center>
                    <div class='rounded-circle guide_img guide-4'></div>
                  </center>
                </div>
                <div class='text-center mt-3'>
                  <div class='seven'>
                    <h5 class='animate__animated animate__delay-1s animate__fadeInUp'>
                      Mufti Mehboob ur Rehman
                    </h5>
                    <h6 className='mb-2 mt-2'>
                      <font>
                        <font>Languages </font>
                      </font>
                    </h6>
                    <span
                      title='English'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>English</font>
                      </font>
                    </span>
                    <span
                      title='Urdu'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Urdu</font>
                      </font>
                    </span>
                    <span
                      title='Arabic'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Arabic</font>
                      </font>
                    </span>
                    <span
                      title='Pashto'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Pashto</font>
                      </font>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6 mt-4'>
              <div className='card card-5 p-3 py-4'>
                <div className='text-center clients card__border'>
                  <center>
                    <div class='rounded-circle guide_img guide-5'></div>
                  </center>
                </div>
                <div class='text-center mt-3'>
                  <div class='seven'>
                    <h5 class='animate__animated animate__delay-1s animate__fadeInUp'>
                      Sarfraz Nazir
                    </h5>
                    <h6 className='mb-2 mt-2'>
                      <font>
                        <font>Languages </font>
                      </font>
                    </h6>
                    <span
                      title='English'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>English</font>
                      </font>
                    </span>
                    <span
                      title='Urdu'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Urdu</font>
                      </font>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6 mt-4'>
              <div className='card card-5 p-3 py-4'>
                <div className='text-center clients card__border'>
                  <center>
                    <div class='rounded-circle guide_img guide-7'></div>
                  </center>
                </div>
                <div class='text-center mt-3'>
                  <div class='seven'>
                    <h5 class='animate__animated animate__delay-1s animate__fadeInUp'>
                      Mohammed Toheed Mehrban
                    </h5>
                    <h6 className='mb-2 mt-2'>
                      <font>
                        <font>Languages </font>
                      </font>
                    </h6>
                    <span
                      title='English'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>English</font>
                      </font>
                    </span>
                    <span
                      title='Urdu'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Urdu</font>
                      </font>
                    </span>
                    <span
                      title='Punjabi'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font> Punjabi</font>
                      </font>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6 mt-4'>
              <div className='card card-5 p-3 py-4'>
                <div className='text-center clients card__border'>
                  <center>
                    <div class='rounded-circle guide_img guide-23'></div>
                  </center>
                </div>
                <div class='text-center mt-3'>
                  <div class='seven'>
                    <h5 class='animate__animated animate__delay-1s animate__fadeInUp'>
                      Abdul Hamid Shakoor
                    </h5>
                    <h6 className='mb-2 mt-2'>
                      <font>
                        <font>Languages </font>
                      </font>
                    </h6>
                    <span
                      title='English'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>English</font>
                      </font>
                    </span>
                    <span
                      title='Urdu'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Urdu</font>
                      </font>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6 mt-4'>
              <div className='card card-5 p-3 py-4'>
                <div className='text-center clients card__border'>
                  <center>
                    <div class='rounded-circle guide_img guide-16'></div>
                  </center>
                </div>
                <div class='text-center mt-3'>
                  <div class='seven'>
                    <h5 class='animate__animated animate__delay-1s animate__fadeInUp'>
                      Umar Rashid
                    </h5>
                    <h6 className='mb-2 mt-2'>
                      <font>
                        <font>Languages </font>
                      </font>
                    </h6>
                    <span
                      title='English'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>English</font>
                      </font>
                    </span>
                    <span
                      title='Urdu'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Urdu</font>
                      </font>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6 mt-4'>
              <div className='card card-5 p-3 py-4'>
                <div className='text-center clients card__border'>
                  <center>
                    <div class='rounded-circle guide_img guide-24'></div>
                  </center>
                </div>
                <div class='text-center mt-3'>
                  <div class='seven'>
                    <h5 class='animate__animated animate__delay-1s animate__fadeInUp'>
                      Kudrat Hussain
                    </h5>
                    <h6 className='mb-2 mt-2'>
                      <font>
                        <font>Languages </font>
                      </font>
                    </h6>
                    <span
                      title='English'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>English</font>
                      </font>
                    </span>
                    <span
                      title='Urdu'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Urdu</font>
                      </font>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6 mt-4'>
              <div className='card card-5 p-3 py-4'>
                <div className='text-center clients card__border'>
                  <center>
                    <div class='rounded-circle guide_img guide-20'></div>
                  </center>
                </div>
                <div class='text-center mt-3'>
                  <div class='seven'>
                    <h5 class='animate__animated animate__delay-1s animate__fadeInUp'>
                      Abu Zarr Ashrafi
                    </h5>
                    <h6 className='mb-2 mt-2'>
                      <font>
                        <font>Languages </font>
                      </font>
                    </h6>
                    <span
                      title='English'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>English</font>
                      </font>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6 mt-4'>
              <div className='card card-5 p-3 py-4'>
                <div className='text-center clients card__border'>
                  <center>
                    <div class='rounded-circle guide_img guide-19'></div>
                  </center>
                </div>
                <div class='text-center mt-3'>
                  <div class='seven'>
                    <h5 class='animate__animated animate__delay-1s animate__fadeInUp'>
                      Mohammad Iryaan
                    </h5>
                    <h6 className='mb-2 mt-2'>
                      <font>
                        <font>Languages </font>
                      </font>
                    </h6>
                    <span
                      title='English'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>English</font>
                      </font>
                    </span>
                    <span
                      title='Urdu'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Urdu</font>
                      </font>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6 mt-4'>
              <div className='card card-5 p-3 py-4'>
                <div className='text-center clients card__border'>
                  <center>
                    <div class='rounded-circle guide_img guide-17'></div>
                  </center>
                </div>
                <div class='text-center mt-3'>
                  <div class='seven'>
                    <h5 class='animate__animated animate__delay-1s animate__fadeInUp'>
                      Hafiz Zahid Iqbal
                    </h5>
                    <h6 className='mb-2 mt-2'>
                      <font>
                        <font>Languages </font>
                      </font>
                    </h6>
                    <span
                      title='English'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>English</font>
                      </font>
                    </span>
                    <span
                      title='Arabic'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Arabic</font>
                      </font>
                    </span>
                    <span
                      title='Urdu'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Urdu</font>
                      </font>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6 mt-4'>
              <div className='card card-5 p-3 py-4'>
                <div className='text-center clients card__border'>
                  <center>
                    <div class='rounded-circle guide_img guide-27'></div>
                  </center>
                </div>
                <div class='text-center mt-3'>
                  <div class='seven'>
                    <h5 class='animate__animated animate__delay-1s animate__fadeInUp'>
                      Anees Chaudry
                    </h5>
                    <h6 className='mb-2 mt-2'>
                      <font>
                        <font>Languages </font>
                      </font>
                    </h6>
                    <span
                      title='English'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>English</font>
                      </font>
                    </span>
                    <span
                      title='Urdu'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Urdu</font>
                      </font>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6 mt-4'>
              <div className='card card-5 p-3 py-4'>
                <div className='text-center clients card__border'>
                  <center>
                    <div class='rounded-circle guide_img guide-15'></div>
                  </center>
                </div>
                <div class='text-center mt-3'>
                  <div class='seven'>
                    <h5 class='animate__animated animate__delay-1s animate__fadeInUp'>
                      Mohammad Hasib Alam
                    </h5>
                    <h6 className='mb-2 mt-2'>
                      <font>
                        <font>Languages </font>
                      </font>
                    </h6>
                    <span
                      title='English'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>English</font>
                      </font>
                    </span>
                    <span
                      title='Punjabi'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Punjabi</font>
                      </font>
                    </span>
                    <span
                      title='Urdu'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Urdu</font>
                      </font>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6 mt-4'>
              <div className='card card-5 p-3 py-4'>
                <div className='text-center clients card__border'>
                  <center>
                    <div class='rounded-circle guide_img guide-28'></div>
                  </center>
                </div>
                <div class='text-center mt-3'>
                  <div class='seven'>
                    <h5 class='animate__animated animate__delay-1s animate__fadeInUp'>
                      Abdul Zaheer Younis
                    </h5>
                    <h6 className='mb-2 mt-2'>
                      <font>
                        <font>Languages </font>
                      </font>
                    </h6>
                    <span
                      title='English'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>English</font>
                      </font>
                    </span>
                   
                    <span
                      title='Urdu'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Urdu</font>
                      </font>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6 mt-4'>
              <div className='card card-5 p-3 py-4'>
                <div className='text-center clients card__border'>
                  <center>
                    <div class='rounded-circle guide_img guide-26'></div>
                  </center>
                </div>
                <div class='text-center mt-3'>
                  <div class='seven'>
                    <h5 class='animate__animated animate__delay-1s animate__fadeInUp'>
                      Mohammad Ajaz Hussain
                    </h5>
                    <h6 className='mb-2 mt-2'>
                      <font>
                        <font>Languages </font>
                      </font>
                    </h6>
                    <span
                      title='English'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>English</font>
                      </font>
                    </span>
                    <span
                      title='Punjabi'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Punjabi</font>
                      </font>
                    </span>
                    <span
                      title='Urdu'
                      class='btn btn-secondary btn-sm btn-lang'
                    >
                      <font>
                        <font>Urdu</font>
                      </font>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Guides
