import React,{useState,useEffect} from "react";
import { NavLink, useNavigate } from 'react-router-dom'
import moment from 'moment'
import logo from '../../Images/footer_logo.png'
function Footer () {
  const navigate=useNavigate();
  const [userLocation,setUserLocation]=useState([]);
  useEffect(()=>{
    getUserLocation();
  },[]);
  function getUserLocation() {
    // Fetch user's location data
    fetch("https://api.geoapify.com/v1/ipinfo?&apiKey=0708571ae58c4688824a7d01ca397041")
      .then(response => response.json())
      .then(data => {
        setUserLocation(data);
        // Handle location data as needed
      })
      .catch(error => {
        console.error('Error fetching user location:', error);
      });
  }

  const Searchhotels = async (name) => {
    const currentDate = new Date();
    const twoDaysLater = new Date(currentDate);
    twoDaysLater.setDate(currentDate.getDate() + 2);
    const threeDaysLater = new Date(currentDate);
    threeDaysLater.setDate(currentDate.getDate() + 3);
    var destination_name='';
     var country='';
     var lat='';
     var lon='';
     var pin='';
    if(name==='Makkah'){
     destination_name="Makkah";
     country="Saudi Arabia";
     lat=21.4240968;
     lon=39.81733639999999;
     pin="SA";
    }else if(name==='Madinah'){
     destination_name="Madinah";
     country="Saudi Arabia";
     lat=24.4672132;
     lon=39.6024496;
     pin="SA";
    }else if(name==='Riyadh'){
     destination_name="Riyadh";
     country="Saudi Arabia";
     lat=24.7135517;
     lon=46.6752957;
     pin="SA";
    }else if(name==='Jeddah'){
      destination_name="Jeddah";
      country="Saudi Arabia";
      lat=21.5291545;
      lon=39.1610863;
      pin="SA";
     }
    // Define your API URL, authToken, and dataToSend as shown in the previous example
    const FormData = {
      "destination_name":destination_name,
      "country":country,
      "lat":  lat,
      "long": lon,
      "pin": pin,
      "cityd":destination_name,
      "country_code": pin,
      "check_in":moment(twoDaysLater).format('YYYY-MM-DD'),
      "check_out":moment(threeDaysLater).format('YYYY-MM-DD'),
      "slc_nationality":userLocation.country.name,
      "adult": 2,
      "child": 0,
      "room": 1,
      "Adults": [2],
      "children": [0],
      "child_ages1": [],
      "rooms_counter": [1],
      "child_ages2": [],
     
    };
    const queryString = new URLSearchParams(FormData).toString()
    navigate(`/hotels?${queryString}`)
  };
  return (
    <>
    <footer>
		<div class="container margin_60_35">
			<div class="row">
				<div class="col-lg-5 col-md-12 pe-5">
					<p className="text-center" ><img src={logo} width="140" height="90" alt=""/></p>
					<p>Dar Riaz Travel Limited is the leading tour operator in the United Kingdom specializing in Hajj and Umrah services. Take advantage of our exceptional Hajj and Umrah packages, thoughtfully designed to meet the unique needs of pilgrims embarking on their significant sacred journey to Saudi Arabia.</p>
					<div class="follow_us">
						<ul>
							<li>Follow us</li>
							<li><a ><i class="icofont-facebook"></i></a></li>
							<li><a ><i class="icofont-twitter"></i></a></li>
							<li><a ><i class="icofont-instagram"></i></a></li>
							<li><a ><i class="icofont-brand-whatsapp"></i></a></li>
						</ul>
					</div>
				</div>
				<div class="col-lg-3 col-md-6 ms-lg-auto">
					<h5>Useful links</h5>
					<ul class="links">
          <li><NavLink to="/">Home</NavLink></li>
						<li><NavLink to="/about-us">About</NavLink></li>
						<li><NavLink to="/contact-us">Contact</NavLink></li>
					
					</ul>
				</div>
				<div class="col-lg-3 col-md-6">
					<h5>Contact with Us</h5>
					<ul class="contacts">
						<li><a href="tel://07311398717"><i class="icofont-mobile-phone"></i> 07311398717</a></li>
						<li><a href="mailto:dariaztravel36@gmail.com"><i class="icofont-email"></i> dariaztravel36@gmail.com</a></li>
					</ul>
					
				</div>
			</div>
			<hr/>
			<div class="row">
				<div class="col-lg-6">
					<p>© {moment().format('YYYY')} Dar Riaz Travel Limited All Rights Reserved.</p>
				</div>
				<div class="col-lg-6">
					<ul id="additional_links">
						<li><NavLink to="/terms_and_conditions">Terms and conditions</NavLink></li>
						<li><NavLink to="/privacy_policy">Privacy</NavLink></li>
            <li><NavLink to="/complaint_policy">Complaint Policy</NavLink></li>
						<li><span>© Dar Riaz Travel Limited</span></li>
					</ul>
				</div>
			</div>
		</div>
	</footer>
    </>
  )
}

export default Footer
