import React, { useEffect, useState } from 'react'
import Googlemap from '../../Components/Googlemap/Googlemap'
import img2 from '../../Images/New/bg-contact.jpg'
import Layout from '../../Components/Layout/Layout'
import wow from 'wowjs'
import { ToastContainer, toast } from 'react-toastify'
import emailjs from 'emailjs-com'
const Contact = () => {
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    subject: '',
    phnno: '',
    message: ''
  })
  const [isBooking, setIsBooking] = useState(false)
  useEffect(() => {
    new wow.WOW().init()
  }, [])

  const handleChange = event => {
    const { value, name } = event.target
    setFormData(prevdata => ({
      ...prevdata,
      [name]: value
    }))
  }

  const isValidEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const submitForm = async () => {
    if (formData.name === '') {
      toast.info('Please Enter Your Name.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (formData.email === '') {
      toast.info('Please Enter Your Email.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (formData.subject === '') {
      toast.info('Please Enter Subject.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (formData.phnno === '') {
      toast.info('Please Enter Phone Number.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (formData.message === '') {
      toast.info('Please Enter Message.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (!isValidEmail(formData.email)) {
      toast.info('Please Enter a valid email.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    }

    setIsBooking(true)
    await sendUserMessage()
    setIsBooking(false)
    toast.success(
      'Dear Customer,Thank you for contacting AlHijaz Tours.One of our agent will contact you within 12 to 24 hours.'
    )
    // try {
    //     const templateParams = {
    //       email: formData.email,
    //       name: formData.name,
    //       subject: formData.subject,
    //       phone : formData.phnno,
    //       message: formData.message
    //     };

    //     await emailjs.send('service_d6zrc2l', 'template_la4ks0l', templateParams, 'c163bgNie5rW0iU1f');
    //     setIsBooking(false);
    //     toast.success('Email Send Successfully.', {
    //         position: toast.POSITION.TOP_RIGHT
    //       });
    //   } catch (error) {
    //     setIsBooking(false);
    //     console.error('Error sending email', error);
    //     toast.error('Error sending email.', {
    //         position: toast.POSITION.TOP_RIGHT
    //       });
    //   }
  }

  const sendUserMessage = async () => {
    const messages = [
      {
        recipient: '+07311398717',
        message: `Dear Dar Riaz Travel Limited,
              
          A customer has contact via contact form.
              
          Name:${formData.name}
          Email:${formData.email}
          Number:${formData.phnno}
          Subject:${formData.subject}
          Message:${formData.message}
              
          Thank You`
      },
      {
        recipient: formData.phnno,
        message: `Dear Customer,\nThank you for contacting Dar Riaz Travel Limited.\nOne of our agent will contact you within 12 to 24 hours.
            
        Thank You 
        Team Dar Riaz Travel Limited`
      }
    ]
    const apiKey = 'RUU6Wkzm2JRt'
    for (let i = 0; i < messages.length; i++) {
      const { recipient, message } = messages[i]
      const encodedMessage = encodeURIComponent(message)
      const url = `http://api.textmebot.com/send.php?recipient=${recipient}&apikey=${apiKey}&text=${encodedMessage}`

      try {
        const response = await fetch(url)

        if (response.ok) {
          const contentType = response.headers.get('content-type')

          if (contentType && contentType.includes('application/json')) {
            const data = await response.json()
            console.log(`Message sent to ${recipient}:`, data)
          } else {
            const text = await response.text()
            console.log(`Message sent to ${recipient}:`, text)
          }

          // Wait 7 seconds before sending the next message
          if (i < messages.length - 1) {
            await new Promise(resolve => setTimeout(resolve, 7000))
          }
        } else {
          console.error(
            `Error sending message to ${recipient}:`,
            response.statusText
          )
        }
      } catch (error) {
        if (i < messages.length - 1) {
          await new Promise(resolve => setTimeout(resolve, 7000))
        }
        console.error(`Error sending message to ${recipient}:`, error)
      }
    }
  }

  return (
    <>
    <ToastContainer/>
      <Layout>
      <main>
		<section class="hero_in contacts">
			<div class="wrapper">
				<div class="container">
					<h1 class="fadeInUp"><span></span>Contact Us</h1>
				</div>
			</div>
		</section>
    <div class="contact_info">
			<div class="container">
				<ul class="clearfix">
					<li>
          <i class="icofont-google-map"></i>
						<h4>Address</h4>
						<span>36 old field lane Heckmonwike Wf16 0jd Heckmondwike</span>
					</li>
					<li>
          <i class="icofont-email"></i>
						<h4>Email address</h4>
						<span>dariaztravel36@gmail.com</span>

					</li>
					<li>
					<i class="icofont-phone"></i>
						<h4>Contacts info</h4>
						<span>07311398717</span>
					</li>
				</ul>
			</div>
		</div>
    <div class="bg_color_1">
			<div class="container margin_80_55">
				<div class="row justify-content-between">
					<div class="col-lg-5">
          <Googlemap />
					</div>
					<div class="col-lg-6">
						<h4>Send a message</h4>
						<div id="message-contact"></div>
						
							<div class="row">
								<div class="col-md-6">
									<div class="form-group">
										<label>Name</label>
										<input class="form-control"  onChange={handleChange} type="text"   name='name'/>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<label>Subject</label>
										<input class="form-control"  onChange={handleChange} type="text"  name="subject"/>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<div class="form-group">
										<label>Email</label>
										<input class="form-control" onChange={handleChange} type="email" id="email_contact" name="email"/>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<label>Telephone</label>
										<input class="form-control"  onChange={handleChange} type="text"  name="phnno"/>
									</div>
								</div>
							</div>
							<div class="form-group">
								<label>Message</label>
								<textarea class="form-control"  onChange={handleChange}  name="message" style={{height:'150px'}}></textarea>
							</div>
						
							<p class="add_top_30"><input  onClick={submitForm} type="submit" value="Submit" class="btn_1 rounded" id="submit-contact"/></p>
					
					</div>
				</div>
			</div>
		</div>
    </main>
       
      
      </Layout>
    </>
  )
}
const Spinner = () => {
  return (
    <div className='spinner-border' role='status'>
      <span className='visually-hidden'>Loading...</span>
    </div>
  )
}
export default Contact
