import React, { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faAngleDown,
  faDollar,
  faPlaneDeparture
} from '@fortawesome/free-solid-svg-icons'
import Layout from '../../Components/Layout/Layout'
import { RangeSlider } from 'rsuite'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import PackageDetailCard from '../../Components/PackageDetailCard/PackageDetailCard'
import bgimage from '../../Images/Packages/kabapic.jpg'
import { useSelector } from 'react-redux'
import PackageSearch from '../../Components/SearchBar/PackageSearch'
import Helmet from 'react-helmet'
function SearchTour () {
  const ToursDetail = useSelector(state => state.hotels.toursdetail)
  console.log(ToursDetail)
  const amounts = ToursDetail.tours.map(tour => tour.quad_grand_total_amount)

  var minValue1 = 0
  var maxValue1 = 0
  if (amounts.length !== 0) {
    minValue1 = Math.min(...amounts)
    maxValue1 = Math.max(...amounts)
  }
  const [showfilter, setShowfilter] = useState({
    departure: true,
    rating: true
  })
  const [minValue, setMinValue] = useState(minValue1)
  const [maxValue, setMaxValue] = useState(maxValue1)
  const [filterData, setFilterData] = useState('')
  const [rangeValue, setRangeValue] = useState([minValue1, maxValue1])
  const [ShowPriceFilter, setShowPriceFilter] = useState(false)
  const [ShowAirportFilter, setShowAirportFilter] = useState(false)
  const [ShowRatingFilter, setShowRatingFilter] = useState(false)
  const [filterAirports, setFilterAirports] = useState([])
  const [airportFilter, setAirportFilter] = useState({ type: 'airport' })
  const [starRating, setStarRating] = useState({
    rating5: '0',
    rating4: '0',
    rating3: '0',
    rating2: '0',
    rating1: '0',
    type: 'Stars'
  })
  const sliderRef = useRef(null)
  useEffect(() => {
    getdepartureairports()
  }, [])

  const handlefilter = () => {
    setShowPriceFilter(false)
    var data = { min: minValue, max: maxValue, type: 'price' }
    setFilterData(data)
  }

  const handleCheckboxChange = event => {
    const { name, value } = event.target

    setStarRating(prevDetails => {
      const updatedStarRating = { ...prevDetails }
      if (updatedStarRating[name] !== '0') {
        updatedStarRating[name] = '0'
      } else {
        updatedStarRating[name] = value
      }

      return updatedStarRating
    })
  }
  const StarFilter = () => {
    setShowRatingFilter(false)
    setFilterData(starRating)
  }
  const getdepartureairports = () => {
    var airports = []
    ToursDetail.tours.forEach(element => {
      var flightdetail = JSON.parse(element.flights_details)
      if (flightdetail !== null) {
        var departureAirportCode = flightdetail[0].departure_airport_code

        // Check if the departure airport code is not already in the array
        if (!airports.includes(departureAirportCode)) {
          airports.push(departureAirportCode)
        }
      }
    })

    setFilterAirports(airports)
  }

  const handleairportsvheckbox = event => {
    const { name, value } = event.target
    setAirportFilter(prevData => {
      const updatedData = { ...prevData }

      if (updatedData.hasOwnProperty(name)) {
        // If the key already exists, remove it
        delete updatedData[name]
      } else {
        // If the key doesn't exist, add it
        updatedData[name] = value
      }

      return updatedData
    })
  }

  const Showfilter = num => {
    if (num === 1) {
      setShowfilter(prevData => ({
        ...prevData,
        departure: !prevData.departure
      }))
    }
    if (num === 2) {
      setShowfilter(prevData => ({ ...prevData, rating: !prevData.rating }))
    }
  }

  const filterAirportscall = () => {
    setShowAirportFilter(false)
    setFilterData(airportFilter)
  }

  const TogglePriceFilter = () => {
    setShowPriceFilter(!ShowPriceFilter)
  }
  const ToggleAirportFilter = () => {
    setShowAirportFilter(!ShowAirportFilter)
  }
  const ToggleRatingFilter = () => {
    setShowRatingFilter(!ShowRatingFilter)
  }

  const handleChange = newRangeValue => {
    setRangeValue(newRangeValue)
    setMinValue(newRangeValue[0])
    setMaxValue(newRangeValue[1])
  }

  return (
    <>
      <Modal isOpen={ShowPriceFilter} toggle={TogglePriceFilter}>
        <ModalHeader toggle={TogglePriceFilter}>Price Filter</ModalHeader>
        <ModalBody>
          <div className='widget widget_price_filter'>
            <div className='mb-0'>
              {/* <label className='form-label'>Price Level</label> */}
              {/* <div ref={sliderRef} /> */}
              <RangeSlider
                value={rangeValue}
                onChange={handleChange}
                min={minValue1}
                tooltip={false}
                max={maxValue1}
                step={1}
              />
              <div className='pt-5'>
                <div className='fw-bold mb-2'>
                  Min: <span id='kt_slider_basic_min'>{rangeValue[0]}</span>
                </div>
                <div className='fw-bold mb-2'>
                  Max: <span id='kt_slider_basic_max'>{rangeValue[1]}</span>
                </div>
              </div>
              <button
                onClick={handlefilter}
                className='btn select-styling search-btn1 mb-1'
              >
                Filter
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={ShowAirportFilter} toggle={ToggleAirportFilter}>
        <ModalHeader toggle={ToggleAirportFilter}>
          Departure Airport Filter
        </ModalHeader>
        <ModalBody>
          <div className='widget widget_has_radio_checkbox'>
            <div className='filter-show-hide'>
              {/* <h3>Filter by Departure Airport</h3> */}
              {/* <FontAwesomeIcon icon={faAngleDown}/> */}
            </div>
            <div>
              <ul>
                {filterAirports.map((item, index) => (
                  <li key={index}>
                    <label>
                      <input
                        type='checkbox'
                        className='custom-textbox'
                        onChange={handleairportsvheckbox}
                        name={`item${index + 1}`}
                        value={item}
                      />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> {item}</span>
                    </label>
                  </li>
                ))}
              </ul>
              <button
                onClick={filterAirportscall}
                className='btn select-styling search-btn1 mb-1'
              >
                Filter
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={ShowRatingFilter} toggle={ToggleRatingFilter}>
        <ModalHeader toggle={ToggleRatingFilter}>
          Star Rating Filter
        </ModalHeader>
        <ModalBody>
          <div className='widget widget_has_radio_checkbox'>
            <div className='filter-show-hide'>
              {/* <h3>Star Rating</h3>                 */}
              {/* <FontAwesomeIcon icon={faAngleDown}/> */}
            </div>
            <div>
              <ul>
                <li>
                  <label>
                    <input
                      type='checkbox'
                      className='custom-textbox'
                      onChange={handleCheckboxChange}
                      name='rating5'
                      value='5'
                    />
                    <i className='awe-icon awe-icon-check'></i>
                    <span className='rating'>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                    </span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type='checkbox'
                      className='custom-textbox'
                      onChange={handleCheckboxChange}
                      name='rating4'
                      value='4'
                    />
                    <i className='awe-icon awe-icon-check'></i>
                    <span className='rating'>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                    </span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type='checkbox'
                      className='custom-textbox'
                      onChange={handleCheckboxChange}
                      name='rating3'
                      value='3'
                    />
                    <i className='awe-icon awe-icon-check'></i>
                    <span className='rating'>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                    </span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type='checkbox'
                      className='custom-textbox'
                      onChange={handleCheckboxChange}
                      name='rating2'
                      value='2'
                    />
                    <i className='awe-icon awe-icon-check'></i>
                    <span className='rating'>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                    </span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type='checkbox'
                      className='custom-textbox'
                      onChange={handleCheckboxChange}
                      name='rating1'
                      value='1'
                    />
                    <i className='awe-icon awe-icon-check'></i>
                    <span className='rating'>
                      <i className='fa fa-star'>
                        {' '}
                        <FontAwesomeIcon icon={faStar} />
                      </i>
                    </span>
                  </label>
                </li>
              </ul>
              <button
                onClick={StarFilter}
                className='btn select-styling search-btn1'
              >
                Filter
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Helmet>
        <meta
          property='og:title'
          content='Book Umrah Package | Select From Available All-Inclusive Umrah Deals & Luxury Umrah Offers to Cheapest Umrah Packages'
        />
        <title>
          Book Umrah Package | Select From Available All-Inclusive Umrah Deals &
          Luxury Umrah Offers to Cheapest Umrah Packages
        </title>
        <meta
          name='keywords'
          content='Umrah Packages, Low Cost Umrah Packages, Umrah Packages from Birmingham, 5 star Umrah packages, Makkah Hotels, Medina Hotels, Umrah visa, tourist visa, Umrah with flights, book hotels,Umrah 2024, Umrah 2025'
        />
        <meta
          property='og:description'
          content='Dar Riaz Travel Limited offers huge range of cautiously devised Umrah packages for families, groups, couples, ladies & individual pilgrims. Select from all-inclusive Umrah deals with 5 star amenities, affordable Umrah packages with 4 star facilities or cheapest Umrah offers designed specifically with flights from UK airports & close to Haram hotels. Avail assistance from expert travel advisors to customize these Umrah packages that suits your requirements & get fast visa processing.'
        />
        <meta
          name='description'
          content='Dar Riaz Travel Limited offers huge range of cautiously devised Umrah packages for families, groups, couples, ladies & individual pilgrims. Select from all-inclusive Umrah deals with 5 star amenities, affordable Umrah packages with 4 star facilities or cheapest Umrah offers designed specifically with flights from UK airports & close to Haram hotels. Avail assistance from expert travel advisors to customize these Umrah packages that suits your requirements & get fast visa processing.'
        />
      </Helmet>
      <Layout>
        <main>
          <section
            class='hero_in '
            style={{ backgroundImage: `url(${bgimage})` }}
          >
            <div class='wrapper'>
              <div class='container'>
                <h1 class='fadeInUp'>
                  <span></span>Search for Package
                </h1>
              </div>
            </div>
          </section>
          <div class='container margin_60_35'>
            <div class='row'>
              <aside class='col-lg-3' id='sidebar'>
                <div id='filters_col'>
                  <a
                    data-bs-toggle='collapse'
                    href='#collapseFilters'
                    aria-expanded='false'
                    aria-controls='collapseFilters'
                    id='filters_col_bt'
                  >
                    Filters{' '}
                  </a>
                  <div class='collapse show' id='collapseFilters'>
                    <div class='filter_type'>
                      <h6>Price</h6>
                      <RangeSlider
                        value={rangeValue}
                        onChange={handleChange}
                        min={minValue1}
                        tooltip={false}
                        max={maxValue1}
                        step={1}
                      />
                      <div className='pt-2'>
                        <div className='fw-bold mb-2'>
                          Min:{' '}
                          <span id='kt_slider_basic_min'>{rangeValue[0]} </span>
                        </div>
                        <div className='fw-bold '>
                          Max:{' '}
                          <span id='kt_slider_basic_max'>{rangeValue[1]}</span>
                        </div>
                      </div>
                      <button
                        onClick={handlefilter}
                        type='submit'
                        class='btn btn-primary w-100 mt-2'
                      >
                        APPLY
                      </button>
                    </div>
                    <div class='filter_type'>
                      <h6>Departure Airport</h6>
                      <ul>
                        {filterAirports.map((item, index) => (
                          <li key={index}>
                            <label class='container_check'>
                              <input
                                type='checkbox'
                                onChange={handleairportsvheckbox}
                                name={`item${index + 1}`}
                                value={item}
                              />{' '}
                              {item}
                              <span class='checkmark'></span>
                            </label>
                          </li>
                        ))}
                      </ul>
                      <button
                        onClick={filterAirportscall}
                        type='submit'
                        class='btn btn-primary w-100 mt-2'
                      >
                        APPLY
                      </button>
                    </div>
                    <div class='filter_type'>
                      <h6>Star Rating</h6>
                      <ul>
                        <li>
                          <label class='container_check'>
                            <span class='cat_star'>
                              {' '}
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />
                            </span>
                            <input
                              type='checkbox'
                              name='rating5'
                              onChange={handleCheckboxChange}
                              value='5'
                            />
                            <span class='checkmark'></span>
                          </label>
                        </li>
                        <li>
                          <label class='container_check'>
                            <span class='cat_star'>
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />
                            </span>
                            <input
                              type='checkbox'
                              name='rating4'
                              onChange={handleCheckboxChange}
                              value='4'
                            />
                            <span class='checkmark'></span>
                          </label>
                        </li>
                        <li>
                          <label class='container_check'>
                            <span class='cat_star'>
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />
                            </span>
                            <input
                              type='checkbox'
                              name='rating3'
                              onChange={handleCheckboxChange}
                              value='3'
                            />
                            <span class='checkmark'></span>
                          </label>
                        </li>
                        <li>
                          <label class='container_check'>
                            <span class='cat_star'>
                              {' '}
                              <FontAwesomeIcon icon={faStar} />{' '}
                              <FontAwesomeIcon icon={faStar} />
                            </span>
                            <input
                              type='checkbox'
                              name='rating2'
                              onChange={handleCheckboxChange}
                              value='2'
                            />
                            <span class='checkmark'></span>
                          </label>
                        </li>
                        <li>
                          <label class='container_check'>
                            <span class='cat_star'>
                              {' '}
                              <FontAwesomeIcon icon={faStar} />
                            </span>
                            <input
                              type='checkbox'
                              name='rating1'
                              onChange={handleCheckboxChange}
                              value='1'
                            />
                            <span class='checkmark'></span>
                          </label>
                        </li>
                      </ul>
                      <button
                        onClick={StarFilter}
                        type='submit'
                        class='btn btn-primary w-100 mt-2'
                      >
                        APPLY
                      </button>
                    </div>
                  </div>
                </div>
              </aside>
              <div class='col-lg-9' id='list_sidebar'>
              <PackageDetailCard filterData={filterData} />
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </>
  )
}

export default SearchTour
