import React, { useEffect } from 'react'
import img2 from '../../Images/NewTemplate/bg_call_section.jpg'
import Layout from '../../Components/Layout/Layout'
import wow from 'wowjs'

function About () {
  useEffect(() => {
    new wow.WOW().init()
  }, [])

  return (
    <>
      <Layout>
        <main>
          <section class='hero_in general'>
            <div class='wrapper'>
              <div class='container'>
                <h1 class='fadeInUp'>
                  <span></span>About Us
                </h1>
              </div>
            </div>
          </section>

          <div class='container margin_80_55'>
            <div class='main_title_2'>
              <span>
                <em></em>
              </span>
              <h2>Why Choose Us</h2>
              <p>Your Journey, Our Commitment – Experience Travel Like Never Before!</p>
            </div>
            <div class='row'>
              <div class='col-lg-4 col-md-6'>
                <a class='box_feat'>
                  <i class='icofont-medal'></i>
                  <h3>+ 1000 Customers</h3>
                  <p>
                  Join over 1,000 satisfied customers who trust us to turn their travel dreams into reality. Discover why we're the go-to choice for unforgettable journeys!
                  </p>
                </a>
              </div>
              <div class='col-lg-4 col-md-6'>
                <a class='box_feat' >
                  <i class='icofont-support'></i>
                  <h3>24/7 Support</h3>
                  <p>
                    With 24/7 support, we're always here to assist you, ensuring a smooth and worry-free travel experience anytime, anywhere!
                  </p>
                </a>
              </div>
              <div class='col-lg-4 col-md-6'>
                <a class='box_feat' >
                  <i class='icofont-bank-alt'></i>
                  <h3>WorldWide Locations</h3>
                  <p>
                  Explore the world with ease through worldwide locations, offering you access to incredible destinations across the globe!
                  </p>
                </a>
              </div>
              <div class='col-lg-4 col-md-6'>
                <a class='box_feat' >
                  <i class='icofont-ui-head-phone'></i>
                  <h3>Help Direct Line</h3>
                  <p>
                  Get immediate assistance with our Help Direct Line, connecting you to expert support for all your travel needs, anytime you require it!
                  </p>
                </a>
              </div>
              <div class='col-lg-4 col-md-6'>
                <a class='box_feat' >
                  <i class='icofont-credit-card'></i>
                  <h3>Secure Payments</h3>
                  <p>
                  Enjoy peace of mind with our secure payment options, ensuring your transactions are safe and protected every step of the way!
                  </p>
                </a>
              </div>
              <div class='col-lg-4 col-md-6'>
                <a class='box_feat' >
                  <i class='icofont-chat'></i>
                  <h3>Support via Chat</h3>
                  <p>
                  Experience instant assistance through our chat support, where our friendly team is ready to help you with any queries!
                  </p>
                </a>
              </div>
            </div>
          </div>

          <div class='bg_color_1'>
            <div class='container margin_80_55'>
              <div class='main_title_2'>
                <span>
                  <em></em>
                </span>
                <h2>Our Origins and Story</h2>
                <p>Where Passion for Travel Meets Unforgettable Adventures!</p>
              </div>
              <div class='row justify-content-between'>
                <div
                  class='col-lg-6 wow position-relative'
                  data-wow-offset='150'
                >
                  <figure class='block-reveal'>
                    <div class='block-horizzontal'></div>
                    <img src={img2} class='img-fluid' alt='' />
                  </figure>
                </div>
                <div class='col-lg-5'>
                  <p>
                  At Dar Riaz Travel Limited, our journey began with a simple yet powerful vision: to connect people with the wonders of travel. Founded in the heart of the UK, we have grown from a small local agency into a trusted partner for travelers seeking unique and unforgettable experiences. Our founders, driven by their passion for exploration, realized the need for personalized travel solutions that cater to every type of adventurer. 
                  </p>
                  <p>
                  Over the years, Dar Riaz Travel Limited has established a reputation for reliability and excellence in the travel industry. Our commitment to quality service and customer satisfaction has attracted a loyal clientele, and we take pride in being a part of countless memorable journeys. With a team of experienced travel experts, we are here to guide you through every step of your travel planning process, ensuring that your trips are not just vacations but lifelong memories.
                  </p>
                  {/* <p>
                    <em>CEO Marc Schumaker</em>
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </>
  )
}

export default About
