import React, { useEffect, useState } from 'react'
import img1 from '../../Images/Carousal/1.jpg'
// import '../../Components/Carousal/style.css'
import img2 from '../../Images/Carousal/2.jpg'
import img3 from '../../Images/Carousal/3.jpg'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Carousel from 'react-bootstrap/Carousel'
import SearchBar from '../../Components/SearchBar/Searchbar'
import Packages from '../../Components/Packages/Packages'
import agentpic from '../../Images/Home/agentpic.png'
import adimage from '../../Images/Home/qurbani_ad.jpeg'
import Hotels from '../../Components/Hotel/Hotels'
import MorePackages from '../../Components/MorePackages/MorePackages'
import Layout from '../../Components/Layout/Layout'
import Loader from '../../Components/Loading/Loader'
import Helmet from 'react-helmet'
import wow from 'wowjs'
import { useNavigate } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel'

import home1 from '../../Images/New/image_home_mix_1.jpg'
import home2 from '../../Images/New/image_home_mix_2.jpg'
import home3 from '../../Images/New/image_home_mix_3.jpg'
import IndexComponents from './IndexComponents'
import location1 from '../../Images/New/location-1.jpg'
import location2 from '../../Images/New/location-2.jpg'
import location3 from '../../Images/New/location-3.jpg'
import location4 from '../../Images/New/location-4.jpg'
import location5 from '../../Images/New/location-5.jpg'
import avatar1 from '../../Images/New/avatar.jpg'
import avatar2 from '../../Images/New/avatar-2.jpg'
import avatar3 from '../../Images/New/avatar-3.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

import img4 from '../../Images/Carousal/5.jpg'

function Home () {
  const [isLoading, setIsLoading] = useState(true)
  const [bottomAdd, setShowbottomAdd] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const [valueFromChild, setValueFromChild] = useState('');
  const navigate = useNavigate()
  useEffect(() => {
    new wow.WOW().init()

    const fetchData = async () => {
      await new Promise(resolve => setTimeout(resolve, 2000))
      setIsLoading(false)
    }

    fetchData()
  }, [])

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  const hideadd = () => {
    setShowbottomAdd(false)
  }
  const gotoHajjPage = () => {
    navigate('/hajj')
  }
  const handleValueChange = (newValue) => {
    setValueFromChild(newValue);
  };
  return (
    <>
      {isLoading && <Loader />}
      <Layout>
        <section class='hero_single  jarallax radio_cat' data-jarallax>
          <img class={`jarallax-img ${valueFromChild==='3' || valueFromChild===3 ? 'transfer-search-height':''}`} src={img4} alt='' />
          <div
            class='wrapper opacity-mask'
            data-opacity-mask='rgba(0, 0, 0, 0.6)'
          >
            <div class='container'>
              <div class='row justify-content-center'>
                <div class='col-xl-12 '>
					<div class='text-center'>
                  <h3>Book unique experiences</h3>
                  <p>
                    Expolore top rated hotels, flights,umrah packages and transfers around the
                    world
                  </p>
				  </div>
                  <SearchBar onValueChange={handleValueChange}/>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div class="container container-custom margin_30_95">
        <IndexComponents />
			<div class="banner mb-0" data-cue="zoomIn">
				<div class="wrapper d-flex align-items-center opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.3)">
					<div>
						<small>Activity</small>
						<h3>Your Perfect<br/>Advenure Experience</h3>
						<p>Activities and accommodations</p>
					</div>
				</div>
			</div>


      <div class="bg_color_1">
			<div class="container margin_80_55">
				<div class="main_title_2">
					<span><em></em></span>
					<h3>Popular Destinations</h3>
				</div>
				<div class="row" data-cues="slideInUp">
					<div class="col-lg-6">
						<a class="box_news" >
							<figure><img src={location1} alt=""/>
							</figure>
							<h4>Makkah</h4>
							<p>The holiest city in Islam, Makkah is home to the Kaaba and hosts millions of pilgrims annually during Hajj and Umrah.</p>
						</a>
					</div>
					<div class="col-lg-6">
						<a class="box_news" >
							<figure><img src={location2} alt=""/>
							</figure>
							<h4>Medina</h4>
							<p>Known as the city of the Prophet Muhammad, Medina houses the Prophet's Mosque and is a key religious center for Muslims.</p>
						</a>
					</div>
					<div class="col-lg-6">
						<a class="box_news" >
							<figure><img src={location3} alt=""/>
							</figure>
							<h4>Jeddah</h4>
							<p>A coastal city along the Red Sea, Jeddah is a vibrant hub for commerce, culture, and the gateway to Makkah for many pilgrims.</p>
						</a>
					</div>
					<div class="col-lg-6">
						<a class="box_news" >
							<figure><img src={location4} alt=""/>
							</figure>
							
							<h4>Riyadh</h4>
							<p>
							The capital of Saudi Arabia, Riyadh is a modern metropolis with historical landmarks, cultural attractions, and booming development.</p>
						</a>
					</div>
				</div>
			</div>
		</div>
		</div>
    <div class="call_section">
			<div class="container clearfix">
				<div class="col-lg-5 col-md-6 float-end wow position-relative" data-wow-offset="250">
					<div class="block-reveal">
						<div class="block-vertical"></div>
						<div class="box_1">
							<h3>Enjoy a GREAT travel with us</h3>
							<p>
							Embark on an unforgettable journey with us, where every adventure is crafted to inspire and delight. Let us take care of the details while you enjoy the thrill of new destinations!</p>
						</div>
					</div>
				</div>
			</div>
		</div>
      </Layout>
    </>
  )
}

export default Home
