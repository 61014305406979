import React,{useState} from 'react'
import bgimage from '../../Images/bg1.jpg'
import Layout from '../../Components/Layout/Layout';
function SupportChanel () {
  return (
    <>
<Layout>
      <div class='fluid-container'>
        <div class='image-container'>
          <img src={bgimage} alt='Background Image' style={{height:'auto'}}/>
        </div>
      </div>

      <div className='p-5 mt-5' style={{background:'#b3a47340'}}>
        <div>
            <h2>Join Our Telegram Channels</h2>
            <p className='mt-4'>We are delighted to announce the launch of our Telegram channels dedicated to Hajj 1445. These channels serve as essential platforms for disseminating important information and timely updates related to Hajj. Our broadcast channel will be the primary source for sharing important details, while the support channel is designed for interactive two-way communication. Here, you can not only stay informed but also engage with the community by posting questions and participating in discussions.</p>
            <p  className='mt-4'>We highly encourage you to become part of both the broadcast and support channels (where they exist) to ensure you have comprehensive access to all relevant information and can actively engage with the community. Your participation enriches the collective experience and contributes to a more connected and informed Hajj journey. Join us in these channels to enhance your Hajj 1445 experience!</p>
            <p  className='mt-4'> Please join the relevant channel by clicking on the appropriate link: </p>
        </div>
            <h4 className='text-center mt-4 animate__animated animate__delay-1s animate__fadeInUp'>UNITED KINGDOM</h4>

            <div className='text-center mt-4'>
            <a href='https://t.me/+tF5MQ2KCCCU1Y2Zk' target='blank'> <button className='btn btn-warning animate__animated animate__delay-1s animate__fadeInUp'>Join Telegram Broadcast Channel</button></a>
            <a href='https://chat.whatsapp.com/IfJtMNZQXerDgcltof7HGy' target='blank'><button className='btn btn-warning animate__animated animate__delay-1s animate__fadeInUp ms-2'>Join Whatsapp Support Channel</button></a>
            </div>
      </div>
    </Layout>
    </>
  )
}

export default SupportChanel
